
  <template>
    <div class="container mx-auto p-4 shadow-lg rounded-lg">
      <h2 class="text-xl font-semibold mb-4">ดูรายละเอียดคู่ค้า</h2>
      <div v-if="supplier" class="border p-4 rounded">
        <div class="mb-2"><strong>ชื่อร้าน/บริษัท:</strong> {{ supplier.company_name }}</div>
        <div class="mb-2"><strong>เลขประจำตัวผู้เสียภาษี:</strong> {{ supplier.tax_id }}</div>
        <div class="mb-2"><strong>ที่อยู่:</strong> {{ supplier.address }}</div>
        <div class="mb-2"><strong>ประเภทหน้างาน:</strong> {{ supplier.supplier_type }}</div>
        <div class="mb-2"><strong>ข้อมูลการชำระเงิน:</strong> {{ supplier.payment_info }}</div>
        <div class="mb-2"><strong>ข้อมูลสนับสนุนหลังการขาย:</strong> {{ supplier.support_info }}</div>
        <div class="mb-2"><strong>เบอร์โทร:</strong> {{ supplier.phone }}</div>
        <div class="mb-2"><strong>ชื่อผู้ติดต่อ:</strong> {{ supplier.contact_name }}</div>
        <div class="mb-2"><strong>เมลร้าน:</strong> {{ supplier.contact_email }}</div>
        <div class="mb-2"><strong>ตำแหน่ง:</strong> {{ supplier.position }}</div>
        <div class="mb-2"><strong>ข้อมูลสัญญาหรือข้อตกลง:</strong> {{ supplier.contract_info }}</div>
        <div class="mb-2"><strong>เว็บไซต์ร้าน:</strong> <a :href="supplier.website" target="_blank">{{ supplier.website }}</a></div>
        <div class="mb-2"><strong>ข้อมูลการจัดส่ง:</strong> {{ supplier.shipping_info }}</div>
        <div class="mb-2"><strong>เครดิต:</strong> {{ supplier.credit }}</div>
        <div class="mb-2"><strong>รายละเอียดสินค้าหรือบริการ:</strong> {{ supplier.detail_product }}</div>
      </div>
      <router-link to="/suppliers" class="mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">Back to List</router-link>
    </div><br><br><br>
    </template>
    
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        supplier: null
      };
    },
    async created() {
      try {
        const response = await axios.get(`https://brk-group.org/suppliers/${this.$route.params.supplier_id}`);
        this.supplier = response.data;
      } catch (error) {
        console.error(error);
        // แสดงข้อผิดพลาดหรือแจ้งเตือน
      }
    }
  };
  </script>
  