<template><br>
  <div v-if="repairDetails" class="container mx-auto px-4">
    <h1 class="text-xl font-semibold text-gray-800 mb-4">รายละเอียดเลขใบแจ้งซ่อม {{ repairDetails.RepairNumber }}</h1>
    
    <!-- รายละเอียดเลขใบแจ้งซ่อม -->
    <div class="bg-white shadow-md rounded-lg p-6 mb-6">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p class="mb-2"><strong class="font-bold">ชื่อผู้แจ้งซ่อม:</strong> {{ repairDetails.ReportedBy }}</p>
        </div>
        <div>
          <p class="mb-2"><strong class="font-bold">วันที่แจ้งซ่อม:</strong> {{ formatDate(repairDetails.DateSubmitted) }}</p>
        </div>
        <div>
          <p class="mb-2"><strong class="font-bold">สถานะ:</strong> {{ repairDetails.Status }}</p>
        </div>
        <div>
          <p class="mb-2"><strong class="font-bold">เบอร์รถ:</strong> {{ repairDetails.VehicleNumber }}</p>
        </div>
        <div>
          <p class="mb-2"><strong class="font-bold">ทะเบียนรถ:</strong> {{ repairDetails.VehicleLicense }}</p>
        </div>
        <div>
          <p class="mb-2"><strong class="font-bold">เลขไมค์รถ:</strong> {{ repairDetails.Mileage }}</p>
        </div>
        <div>
          <p class="mb-2"><strong class="font-bold">ประเภทซ่อม:</strong> {{ repairDetails.RepairType }}</p>
        </div>
        <div>
          <p class="mb-4"><strong class="font-bold">สาเหตุการซ่อม:</strong> {{ repairDetails.RepairDescription }}</p>
        </div>
        <div>
          <p class="mb-4"><strong class="font-bold">ซ่อมภายในหรือภายนอก:</strong> {{ repairDetails.InternalExternal }}</p>
        </div>
      </div>
      
      <div class="flex space-x-2 mt-4">
        <button @click="confirmRepair" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
          ยืนยันใบแจ้งซ่อม
        </button>
        <button @click="cancelRepair" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          ยกเลิกใบแจ้งซ่อม
        </button>
        <router-link to="/repair-form" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          กลับหน้าหลัก
        </router-link>
      </div>
    </div>

    <!-- รายการอะไหล่ -->
    <div class="bg-white shadow-md rounded-lg p-6 mb-6" v-if="repairDetails.Status === 'กำลังดำเนินการ'">
      <h2 class="text-lg font-semibold text-gray-700 my-4">รายการอะไหล่</h2>
      <button @click="showProductModal = true" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        เพิ่มรายการ
      </button>

      <!-- Modal สำหรับเลือกสินค้า -->
      <div v-if="showProductModal" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full h-4/5">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    เลือกสินค้า
                  </h3>
                  <div class="mt-2">
                    <input v-model="productSearchQuery" @input="fetchProducts" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาจากชื่อสินค้า">
                    <table class="min-w-full divide-y divide-gray-200 mt-4">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อสินค้า</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รหัสสินค้า</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทสินค้า</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ยี่ห้อ</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทพาหนะ</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทอะไหล่</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">จำนวนสินค้า (ที่มีอยู่ใน stock)</th>
                          <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลือก</th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="product in paginatedProducts" :key="product.product_id">
                          <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium text-gray-900">{{ product.product_name }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_code }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_type }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.brand }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.vehicle_type }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.part_type }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.quantity_in_stock }}</td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium">
                            <button @click="selectPart(product)" class="text-indigo-600 hover:text-indigo-900">เลือก</button>
                          </td>
                        </tr>
                        <tr v-if="paginatedProducts.length === 0">
                          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="8">ไม่พบข้อมูล</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="mt-4 flex justify-between">
                      <button @click="prevProductPage" :disabled="currentProductPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                      <span>หน้าที่ {{ currentProductPage }} จาก {{ totalProductPages }}</span>
                      <button @click="nextProductPage" :disabled="currentProductPage === totalProductPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button @click="showProductModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>

      <table class="min-w-full table-auto mt-4">
        <thead>
          <tr>
            <th class="px-4 py-2">ลำดับ</th>
            <th class="px-4 py-2">รหัสสินค้า</th>
            <th class="px-4 py-2">รายการสินค้า</th>
            <th class="px-4 py-2">ประเภทสินค้า</th>
            <th class="px-4 py-2">จำนวน</th>
            <th class="px-4 py-2">หน่วยนับ</th>
            <th class="px-4 py-2">สถานะ</th>
            <th class="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in repairParts" :key="item.id">
            <td class="border px-4 py-2">{{ index + 1 }}</td>
            <td class="border px-4 py-2">{{ item.productCode }}</td>
            <td class="border px-4 py-2">{{ item.partName }}</td>
            <td class="border px-4 py-2">{{ item.productType }}</td>
            <td class="border px-4 py-2">{{ item.quantity }}</td>
            <td class="border px-4 py-2">{{ item.unitName }}</td>
            <td class="border px-4 py-2">
              <span :class="item.status === 'สินค้าคงคลัง' ? 'text-green-500' : 'text-red-500'">
                {{ item.status }}
              </span>
            </td>
            <td class="border px-4 py-2">
              <button @click="removePart(item.id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">Remove</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-6 flex justify-between">
      <div class="flex space-x-4">
        <div class="flex flex-col">
          <label class="text-gray-700">หัวหน้าแผนกซ่อมบำรุง</label>
          <input type="text" class="form-input mt-1 block w-full" v-model="repairDetails.maintenanceHead" readonly>
        </div>
        <div class="flex flex-col">
          <label class="text-gray-700">ผู้จัดการแผนกซ่อมบำรุง</label>
          <input type="text" class="form-input mt-1 block w-full" v-model="repairDetails.maintenanceManager" readonly>
        </div>
      </div>
      <div class="flex space-x-4">
        <button @click="cancelRepair" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          ยกเลิกใบแจ้งซ่อม
        </button>
        <button @click="createPurchaseOrder" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
          สร้างใบขอซื้อ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      showProductModal: false,
      repairDetails: null,
      selectedPart: null,
      partsOptions: [], // ตัวเลือกสินค้าที่จะแสดงใน dropdown
      repairParts: [], // เก็บรายการอะไหล่
      products: [],
      productSearchQuery: '',
      currentProductPage: 1,
      productsPerPage: 10,
    };
  },
  computed: {
    paginatedProducts() {
      const start = (this.currentProductPage - 1) * this.productsPerPage;
      const end = start + this.productsPerPage;
      return this.products.slice(start, end);
    },
    totalProductPages() {
      return Math.ceil(this.products.length / this.productsPerPage);
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('th-TH');
    },
   async fetchRepairDetails() {
    try {
      const response = await axios.get(`https://brk-group.org/api/repair-details/${this.$route.params.id}`);
      this.repairDetails = response.data;
    } catch (error) {
      console.error('Error fetching repair details:', error);
    }
    },
    async confirmRepair() {
    try {
      const response = await axios.put(`https://brk-group.org/api/repairs/${this.repairDetails.RepairNumber}/confirm`);
      if (response.data.success) {
        this.repairDetails.Status = 'กำลังดำเนินการ'; // อัพเดทสถานะในหน้าปัจจุบัน
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error confirming the repair:', error);
      alert('ไม่สามารถยืนยันใบแจ้งซ่อมได้');
    }
  },
  async cancelRepair() {
    try {
      const response = await axios.put(`https://brk-group.org/api/repairs/${this.repairDetails.RepairNumber}/cancel`);
      if (response.data.success) {
        this.repairDetails.Status = 'ยกเลิก'; // อัพเดทสถานะในหน้าปัจจุบัน
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error canceling the repair:', error);
      alert('ไม่สามารถยกเลิกใบแจ้งซ่อมได้');
    }
  },
  async fetchParts() {
      // Fetch parts data from the server and populate partsOptions
      try {
        const response = await axios.get(`https://brk-group.org/api/parts`);
        this.partsOptions = response.data.map(part => ({
          value: part.id, text: part.name
        }));
      } catch (error) {
        console.error('Error fetching parts:', error);
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get('https://brk-group.org/api/productselectrepair', {
          params: { search: this.productSearchQuery }
        });
        this.products = response.data;
        this.currentProductPage = 1; // Reset to the first page when new search is performed
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },

    selectPart(part) {
      this.selectedPart = part;
      const existingPart = this.repairParts.find(item => item.productCode === part.product_code);
      if (existingPart) {
        existingPart.quantity += 1;
      } else {
        this.repairParts.push({
          id: part.product_id,
          productCode: part.product_code,
          partName: part.product_name,
          productType: part.product_type,
          quantity: 1,
          unitName: part.unit_name,
          status: part.quantity_in_stock > 0 ? 'สินค้าคงคลัง' : 'ขอซื้อลิ้นค้า',
        });
      }
      this.showProductModal = false;
    },
    addPart() {
      if (!this.selectedPart) {
        alert('กรุณาเลือกอะไหล่ก่อนเพิ่ม');
        return;
      }

      const partToAdd = this.selectedPart;
      const existingPart = this.repairParts.find(part => part.partName === partToAdd.product_name);
      if (existingPart) {
        existingPart.quantity += 1; // เพิ่มจำนวนหากอะไหล่นี้ถูกเพิ่มไปแล้ว
      } else {
        this.repairParts.push({
          id: partToAdd.product_id,
          partName: partToAdd.product_name,
          quantity: 1,
          unitPrice: partToAdd.price_per_unit // ใช้ราคาจากข้อมูลที่ดึงมา
        });
      }

      this.showProductModal = false; // ปิด modal หลังจากเพิ่มรายการ
    },
    removePart(id) {
      this.repairParts = this.repairParts.filter(part => part.id !== id);
    },
    prevProductPage() {
      if (this.currentProductPage > 1) {
        this.currentProductPage--;
      }
    },
    nextProductPage() {
      if (this.currentProductPage < this.totalProductPages) {
        this.currentProductPage++;
      }
    }
  
  },
  
  mounted() {
    this.fetchRepairDetails();
    this.fetchParts();
    this.fetchProducts();
  }
};
</script>
