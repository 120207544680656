<template>
  <div v-if="showModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 overflow-y-auto h-full w-full">
    <div class="flex justify-center items-center h-full">
      <div class="bg-white p-5 rounded-lg w-full mx-2 md:max-w-2xl md:mx-auto">
        <h3 class="font-semibold text-lg text-gray-800 mb-4">{{ isEdit ? 'แก้ไขสินค้า' : 'เพิ่มสินค้า' }}</h3>
        
        <form @submit.prevent="onSubmit" class="flex flex-wrap -mx-2">

          <div  class="w-full md:w-1/ px-2 mb-4">
            <label for="productCode" class="block text-gray-700 text-sm font-bold mb-2">รหัสสินค้า:</label>
            <input v-model="localForm.product_code" id="productCode" type="text" placeholder="รหัสสินค้า" class="mb-4 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" readonly>
          </div>
          <div class="w-full md:w-1/3 px-2 mb-4">
            <label for="productName" class="block text-gray-700 text-sm font-bold mb-2">ชื่อสินค้า:</label>
            <input v-model="localForm.product_name" id="productName" type="text" placeholder="ชื่อสินค้า" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required>
          </div>
          <div class="w-full md:w-1/3 px-2 mb-4">
            <label for="vehicleType" class="block text-gray-700 text-sm font-bold mb-2">ประเภทรถ:</label>
            <select v-model="localForm.vehicle_type_id" id="vehicleType" class="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
  <option v-for="type in vehicleTypes" :key="type.vehicle_type_id" :value="type.vehicle_type_id">{{ type.description }}
  </option>
</select>

          </div>

          <div class="w-full md:w-1/3 px-2 mb-4">
            <label for="brand" class="block text-gray-700 text-sm font-bold mb-2">ยี่ห้อสินค้า:</label>
            <select v-model="localForm.brand_id" id="brand" class="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
              <option v-for="brand in brands" :key="brand.brand_id" :value="brand.brand_id">{{ brand.brand_name }}</option>
            </select>
          </div>
          <div class="w-full md:w-1/3 px-2 mb-4">
            <label for="productType" class="block text-gray-700 text-sm font-bold mb-2">ประเภทสินค้า:</label>
            <select v-model="localForm.product_type_id" id="productType" class="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
  <option v-for="type in productTypes" :key="type.product_type_id" :value="type.product_type_id">{{ type.description }}</option>
</select>
          </div>

          <div class="w-full md:w-1/3 px-2 mb-4">
            <label for="partType" class="block text-gray-700 text-sm font-bold mb-2">ประเภทอะไหล่:</label>
            <select v-model="localForm.part_type_id" id="partType" class="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
              <option v-for="part in partTypes" :key="part.part_type_id" :value="part.part_type_id">{{ part.type_name }}</option>
            </select>
          </div>
          <div class="w-full md:w-1/3 px-2 mb-4">
            <label for="unitName" class="block text-gray-700 text-sm font-bold mb-2">หน่วยนับ:</label>
            <input v-model="localForm.unit_name" id="unitName" type="text" placeholder="หน่วยนับ" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="w-full px-2 mb-4">
            <label for="description" class="block text-gray-700 text-sm font-bold mb-2">รายละเอียด:</label>
            <textarea v-model="localForm.description" id="description" placeholder="รายละเอียด" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"></textarea>
          </div>
          <div class="w-full px-2 mb-4">
            <label for="image" class="block text-gray-700 text-sm font-bold mb-2">รูปภาพ:</label>
            <input type="file" @change="handleFileUpload" id="image" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="w-full px-2 flex items-center justify-between">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" type="submit">บันทึก</button>
            <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out" @click="close">ยกเลิก</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  props: {
    showModal: Boolean,
    form: Object,
    productTypes: Array,
    vehicleTypes: Array,
    brands: Array,
    partTypes: Array,
    isEdit: Boolean
  },
  emits: ['productUploaded', 'close'],
  data() {
    return {
      localForm: this.defaultForm(),
      selectedFile: null,

    };
  },
  mounted() {
    if (this.isEdit) {
      this.localForm = { ...this.form };
      this.updateProductCode();
    }
  },
  methods: {
    defaultForm() {
      return {
        product_id: null,
        product_name: '',
        product_type_id: null,
        vehicle_type_id: null,
        brand_id: null,
        part_type_id: null,
        unit_name: '',
        description: '',
        product_code: '',
        image: null,
      };
    },

    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async updateProductCode() {
  try {
    const vehicle = this.vehicleTypes.find(v => v.vehicle_type_id === this.localForm.vehicle_type_id) || {};
    const brand = this.brands.find(b => b.brand_id === this.localForm.brand_id) || {};
    const productType = this.productTypes.find(pt => pt.product_type_id === this.localForm.product_type_id) || {};
    const partType = this.partTypes.find(p => p.part_type_id === this.localForm.part_type_id) || {};

    const vehicleCode = vehicle.vehicle_code_name || '';
    const brandCode = brand.brand_code_name || '';
    const productTypeCode = productType.product_code_name || '';
    const partTypeCode = partType.part_type_id || '';

    let nextNumber;
    if (this.isEdit) {
      const match = this.form.product_code.match(/(\d{5})/);
      nextNumber = match ? match[0] : '00001';
    } else {
      try {
        const response = await axios.get('https://brk-group.org/api/products/next-number');
        nextNumber = response.data.nextNumber;
      } catch (error) {
        console.error('Failed to fetch next number from API:', error);
        nextNumber = '00001';  // Consider whether this is the best fallback.
      }
    }

    this.localForm.product_code = `${vehicleCode}-${brandCode}${productTypeCode}-${nextNumber}-${partTypeCode}`;
  } catch (error) {
    console.error('Failed to update product code:', error);
  }
}
,

async onSubmit() {
  const formData = new FormData();
  // เพิ่มการตรวจสอบว่าค่าที่ส่งเป็น undefined หรือไม่
  Object.entries(this.localForm).forEach(([key, value]) => {
    if (value !== undefined) {
      formData.append(key, this.localForm[key]);
    }
  });
  // Append the selected file to FormData
  if (this.selectedFile) {
    formData.append('image', this.selectedFile);
  }

  try {
    let response;
    if (this.isEdit) {
      // ใช้ HTTP PUT เมื่อแก้ไข
      response = await axios.put(`https://brk-group.org/api/products/${this.localForm.product_id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } else {
      // ใช้ HTTP POST เมื่อเพิ่มใหม่
      response = await axios.post('https://brk-group.org/api/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    this.$emit('product-uploaded');
    Swal.fire('สำเร็จ!', response.data, 'success');
    this.close();
  } catch (error) {
    console.error("Request failed:", error);
    Swal.fire('ผิดพลาด', 'ไม่สามารถดำเนินการกับสินค้าได้', 'error');
  }
},
    close() {
      this.$emit('close');
    },
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.localForm = { ...newVal };
          if (this.isEdit) {
            this.updateProductCode();
          }
        }
      }
    },
    'localForm.vehicle_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) this.updateProductCode();
    },
    'localForm.brand_id': function (newVal, oldVal) {
      if (newVal !== oldVal) this.updateProductCode();
    },
    'localForm.product_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) this.updateProductCode();
    },
    'localForm.part_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) this.updateProductCode();
    },
  }

  
};
</script>

<style scoped>
/* Styling for product image */
.product-image {
  width: 150px; /* or any desired size */
  height: auto; /* height will adjust to maintain aspect ratio */
  object-fit: cover; /* image will cover the area without distortion */
  border-radius: 15px; /* optional: for rounded corners */
}
</style>
