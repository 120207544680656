<template>
  <div v-if="isVisible" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" @click.self="closeModal">
    <div class="flex items-center justify-center min-h-screen">
      <div class="bg-white rounded-lg shadow-xl m-4 sm:max-w-xl sm:w-full" @click.stop>
        <div class="border-b px-4 py-2 flex justify-between items-center">
          <h3 class="text-lg font-semibold">
            {{ editableVehicleType.vehicle_type_id ? 'แก้ไขประเภทรถ' : 'เพิ่มประเภทรถใหม่' }}
          </h3>
          <button @click="closeModal" class="text-black">
            <!-- Heroicons x-mark icon -->
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <form class="px-8 py-4">
          <div class="mb-4">
            <label for="vehicle_code_name" class="block text-sm font-medium text-gray-700">รหัสประเภท</label>
            <input type="text" id="vehicle_code_name" v-model="editableVehicleType.vehicle_code_name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" placeholder="ระบุรหัสประเภท">
          </div>
          <div class="mb-4">
            <label for="type_name" class="block text-sm font-medium text-gray-700">ชื่อประเภท</label>
            <input type="text" id="type_name" v-model="editableVehicleType.type_name" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" placeholder="ระบุชื่อประเภท">
          </div>
          <div class="mb-4">
            <label for="description" class="block text-sm font-medium text-gray-700">รายละเอียด</label>
            <textarea id="description" v-model="editableVehicleType.description" rows="3" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50" placeholder="ระบุรายละเอียด"></textarea>
          </div>
        </form>
        <div class="flex justify-end border-t px-4 py-2">
          <button @click="saveVehicleType" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">บันทึก</button>
          <button @click="closeModal" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">ยกเลิก</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    vehicleType: Object,
  },
  data() {
    return {
      // Initialize editableVehicleType with a deep copy of vehicleType or default values
      editableVehicleType: this.vehicleType ? JSON.parse(JSON.stringify(this.vehicleType)) : { vehicle_type_id: '', vehicle_code_name: '', type_name: '', description: '' },
    };
  },
  watch: {
    vehicleType: {
      immediate: true,
      deep: true,
      handler(newVal) {
        // Update editableVehicleType whenever the prop changes
        this.editableVehicleType = newVal ? JSON.parse(JSON.stringify(newVal)) : { vehicle_type_id: '', vehicle_code_name: '', type_name: '', description: '' };
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    saveVehicleType() {
      // Emit the save event with a deep copy of editableVehicleType to prevent direct mutations
      this.$emit('save', JSON.parse(JSON.stringify(this.editableVehicleType)));
      this.closeModal();
    },
  },
};
</script>
