<template>
  <div>
    <div class="navbar bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500 text-white shadow-md">
      <nav class="container mx-auto px-6 py-3 flex justify-between items-center">
        <ul class="flex space-x-4 items-center">
          <li :class="{ active: activeSection === 'intakeList' }" @click="setActiveSection('intakeList')">
            รายการรับเข้าใบสั่งซื้อ
          </li>
          <li :class="{ active: activeSection === 'transferList' }" @click="setActiveSection('transferList')">
            รายการรับเข้าใบโอน
          </li>
          <li :class="{ active: activeSection === 'returnList' }" @click="setActiveSection('returnList')">
            รายการรับเข้าใบคืน
          </li>
        </ul>
       
      </nav>
    </div>
    
    <div class="warehouse-intake">
      <div class="content p-6 bg-gray-100">
        <div v-if="activeSection === 'intakeList'">
          <div class="mb-4">
            <div class="flex justify-between items-center">
              <h1 class="text-xl font-semibold">รับเข้าสินค้า</h1>
            </div>
          </div>
          <!-- Search and filter section -->
          <div class="mb-4 flex space-x-4">
            <input v-model="searchQuery" type="text" placeholder="ค้นหาชื่อผู้นำเข้าหรือคลังที่รับ" class="border p-2 rounded">
            <select v-model="selectedStatus" class="border p-2 rounded">
              <option value="">ทั้งหมด</option>
              <option value="รออนุมัติ">รออนุมัติ</option>
              <option value="เสร็จสิ้น">เสร็จสิ้น</option>
              <option value="รอเข้าคลัง">รอเข้าคลัง</option>
            </select>
            <input v-model="startDate" type="date" class="border p-2 rounded">
            <input v-model="endDate" type="date" class="border p-2 rounded">
          </div>
          <!-- Table section -->
          <table class="min-w-full bg-white">
            <thead>
              <tr>
                <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ลำดับ</th>
                <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">เลขที่เอกสาร</th>
                <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">วันที่นำเข้า</th>
                <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">จำนวนสินค้า</th>
                <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">คลังที่รับ</th>
                <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">สถานะ</th>
                <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ผู้นำเข้า</th>
                <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in filteredImports" :key="index" :class="{'bg-red-100': item.status === 'ยกเลิก', 'bg-orange-100': item.status === 'รออนุมัติ', 'bg-green-100': item.status === 'รับเข้าคลัง'}">
                <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ index + 1 }}</td>
                <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.doc_number }}</td>
                <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ formatDate(item.import_date) }}</td>
                <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.quantity }}</td>
                <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.warehouse_name }}</td>
                <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.status }}</td>
                <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.importer_fname }} {{ item.importer_lname }}</td>
                <td class="border-b p-4 pl-8 text-grey-darker text-center">
                  <button @click="openDetailsModal(item.import_id)" class="text-blue-500 hover:underline">รายละเอียด</button>
                </td>
              </tr>
            </tbody>
          </table>


   <!-- Details Modal -->
   <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white p-6 rounded-lg shadow-lg w-3/4 h-4/5 overflow-y-auto">
          <h2 class="text-xl font-semibold mb-4">รายละเอียดการนำเข้า</h2>
          <div v-if="modalData" class="space-y-4 p-4 border rounded-lg shadow">
            <div class="flex items-center border-b py-2">
              <strong class="w-1/3 text-right font-semibold pr-2">เลขที่เอกสาร:</strong>
              <span class="w-2/3">{{ modalData.doc_number }}</span>
            </div>
            <div class="flex items-center border-b py-2">
              <strong class="w-1/3 text-right font-semibold pr-2">วันที่นำเข้า:</strong>
              <span class="w-2/3">{{ formatDate(modalData.import_date) }}</span>
            </div>
            <div class="flex items-center border-b py-2">
              <strong class="w-1/3 text-right font-semibold pr-2">ผู้นำเข้า:</strong>
              <span class="w-2/3">{{ modalData.importerName }}</span>
            </div>
            <div class="flex items-center border-b py-2">
              <strong class="w-1/3 text-right font-semibold pr-2">ตำแหน่งผู้นำเข้า:</strong>
              <span class="w-2/3">{{ modalData.importerPosition }}</span>
            </div>
            <div class="flex items-center border-b py-2">
              <strong class="w-1/3 text-right font-semibold pr-2">หน่วยงาน:</strong>
              <span class="w-2/3">{{ modalData.agency }}</span>
            </div>
            <div class="flex items-center border-b py-2">
              <strong class="w-1/3 text-right font-semibold pr-2">คลังที่รับ:</strong>
              <span class="w-2/3">{{ modalData.warehouse_name }}</span>
            </div>
            <div v-if="modalData.status === 'รออนุมัติ'" class="flex items-center border-b py-2 bg-blue-100">
              <strong class="w-1/3 text-right font-semibold pr-2">สถานะ:</strong>
              <span class="w-2/3">{{ modalData.status }}</span>
            </div>
            <div v-if="modalData.status === 'ไม่อนุมัติ'" class="flex items-center border-b py-2">
              <strong class="w-1/3 text-right font-semibold pr-2">ผู้ยกเลิก:</strong>
              <span class="w-2/3">{{ modalData.cancelName || 'ไม่ระบุ' }}</span>
            </div>
            <div v-if="modalData.status === 'ไม่อนุมัติ'" class="flex items-center border-b py-2">
              <strong class="w-1/3 text-right font-semibold pr-2">ตำแหน่งผู้ยกเลิก:</strong>
              <span class="w-2/3">{{ modalData.cancelPosition || 'ไม่ระบุ' }}</span>
            </div>

            <h3 class="mt-4 mb-2 text-lg font-semibold">รายการสินค้า</h3>
            <table class="min-w-full bg-white">
              <thead>
                <tr>
                  <th class="border-b p-2 text-center"></th>
                  <th class="border-b p-2 text-center">ชื่อสินค้า</th>
                  <th class="border-b p-2 text-center">ประเภทสินค้า</th>
                  <th class="border-b p-2 text-center">ประเภทอะไหล่</th>
                  <th class="border-b p-2 text-center">ยี่ห้อรถ</th>
                  <th class="border-b p-2 text-center">จำนวน</th>
                  <th class="border-b p-2 text-center">ราคาต่อหน่วย</th>
                  <th class="border-b p-2 text-center">ราคารวม</th>
                  <th class="border-b p-2 text-center">รูปประกอบ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="product in modalData.products" :key="product.product_id">
                  <td class="border-b p-2">
                    <img :src="product.product_image_url ? getImageUrl2(product.product_image_url) : ''" alt="Product Image" class="w-10 h-10">
                  </td>
                  <td class="border-b p-2">{{ product.product_name }}</td>
                  <td class="border-b p-2">{{ product.product_type }}</td>
                  <td class="border-b p-2">{{ product.part_type }}</td>
                  <td class="border-b p-2">{{ product.brand_name }}</td>
                  <td class="border-b p-2">{{ product.quantity }}</td>
                  <td class="border-b p-2">{{ product.unitPrice }}</td>
                  <td class="border-b p-2">{{ product.totalPrice }}</td>
                  <td class="border-b p-2">
                    <img :src="product.import_image_url ? getImageUrl(product.import_image_url) : ''" alt="Import Image" class="w-10 h-10">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-4 text-right">
            <button @click="closeModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ปิด</button>
            <button v-if="modalData.status === 'รออนุมัติ'" @click="openDisapproveModal(modalData.import_id)" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">ไม่อนุมัติ</button>
            <button v-if="modalData.status === 'รออนุมัติ'" @click="approveImport(modalData.import_id)" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">อนุมัติ</button>
          </div>
        </div>
      </div>

      <!-- Disapprove Modal -->
      <div v-if="showDisapproveModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white p-6 rounded-lg shadow-lg w-1/2">
          <h2 class="text-xl font-semibold mb-4">เหตุผลในการไม่อนุมัติ</h2>
          <textarea v-model="disapproveReason" class="w-full p-2 border rounded mb-4" rows="4"></textarea>
          <div class="text-right">
            <button @click="closeDisapproveModal" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">ยกเลิก</button>
            <button @click="disapproveImport" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ไม่อนุมัติ</button>
          </div>
        </div>
      </div>


        </div>
        


        <div v-if="activeSection === 'transferList'">
    <div class="mb-4">
      <div class="flex justify-between items-center">
        <h1 class="text-xl font-semibold">รายการรับเข้าใบโอน</h1>
      </div>
    </div>

    <!-- Search and filter section -->
    <div class="mb-4 flex space-x-4">
      <input v-model="transferSearchQuery" type="text" placeholder="ค้นหาเลขที่ใบสั่งซื้อหรือหน่วยงาน" class="border p-2 rounded">
      <select v-model="transferSelectedStatus" class="border p-2 rounded">
        <option value="">ทั้งหมด</option>
        <!-- <option value="รออนุมัติ">รออนุมัติ</option> -->
        <option value="รอเข้าคลัง">รอเข้าคลัง</option>
        <option value="รับเข้าคลังแล้ว">รับเข้าคลังแล้ว</option>
        <!-- <option value="ไม่อนุมัติ">ไม่อนุมัติ</option> -->
      </select>
      <input v-model="transferStartDate" type="date" class="border p-2 rounded">
      <input v-model="transferEndDate" type="date" class="border p-2 rounded">
    </div>

    <!-- Table section -->
    <table class="min-w-full bg-white">
      <thead>
        <tr>
          <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ลำดับ</th>
          <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">เลขที่ใบสั่งซื้อ</th>
          <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">วันที่เปิดใบสั่งซื้อ</th>
          <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">หน่วยงาน</th>
          <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">สถานะ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in filteredTransferOrders" :key="index" @click="goToDetail(item.po_id, item.status)">
          <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ index + 1 }}</td>
          <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.po_number }}</td>
          <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ formatDate(item.po_date) }}</td>
          <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.department }}</td>
          <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.status }}</td>
        </tr>
      </tbody>
    </table>
      </div>
      </div>
    </div>
   
  </div>
</template>
<script>
import axios from 'axios';
// import { LogoutIcon } from '@heroicons/vue/solid';

export default {
  data() {
    return {
      imports: [],
      filteredImports: [],
      searchQuery: '',
      selectedStatus: '',
      startDate: '',
      endDate: '',
      showModal: false,
      modalData: null,
      showDisapproveModal: false,
      disapproveReason: '',
      activeSection: 'intakeList',
      isAuthenticated: true,
      transferOrders: [],
      filteredTransferOrders: [],
      transferSearchQuery: '',
      transferSelectedStatus: '',
      transferStartDate: '',
      transferEndDate: '',
      transfers: []
    };
  },
  mounted() {
    this.fetchWarehouseIntakes();
    this.fetchTransferOrders();
  },
  methods: {
    goToDetail(poId, status) {
      const query = status === 'รอเข้าคลัง' ? { fromWarehouse: true } : {};
      this.$router.push({ name: 'PODetail', params: { poId }, query });
    },
    setActiveSection(section) {
      this.activeSection = section;
      // You can add more logic here to fetch different data for other sections if needed
    },
    async fetchWarehouseIntakes() {
      try {
        const response = await axios.get('https://brk-group.org/api/warehouse-intakes');
        this.imports = response.data;
        this.filteredImports = this.imports;
      } catch (error) {
        console.error('Error fetching warehouse intakes:', error);
        alert('e.');
      }
    },
    async fetchTransferOrders() {
      try {
        const response = await axios.get('https://brk-group.org/api/transfer-orders');
        this.transferOrders = response.data;
        this.filteredTransferOrders = this.transferOrders;
      } catch (error) {
        console.error('Error fetching transfer orders:', error);
        alert('Failed to fetch transfer orders due to a server error. Please try again later.');
      }
    },
    formatDate(dateStr) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) {
        return 'Invalid Date';
      }
      return date.toLocaleDateString('th-TH', options);
    },
    async openDetailsModal(importId) {
      try {
        const response = await axios.get(`https://brk-group.org/api/warehouse-intakes/${importId}`);
        this.modalData = {
          ...response.data,
          importerName: `${response.data.importer_fname} ${response.data.importer_lname}`,
          importerPosition: response.data.importer_position,
          cancelName: `${response.data.cancel_fname} ${response.data.cancel_lname}`,
          cancelPosition: response.data.cancel_position,
          products: response.data.products.map(product => ({
            ...product,
            unitPrice: product.unit_price,
            totalPrice: product.quantity * product.unit_price,
          })),
        };
        this.showModal = true;
      } catch (error) {
        console.error('Error fetching import details:', error);
        alert('Failed to fetch import details due to a server error. Please try again later.');
      }
    },
    closeModal() {
      this.showModal = false;
      this.modalData = null;
    },
    openDisapproveModal(importId) {
      this.showDisapproveModal = true;
      this.modalData.import_id = importId;
    },
    closeDisapproveModal() {
      this.showDisapproveModal = false;
      this.disapproveReason = '';
    },
    async disapproveImport() {
      try {
        const token = localStorage.getItem('userToken');
        await axios.put(`https://brk-group.org/api/imports/${this.modalData.import_id}/disapprove`, {
          reason: this.disapproveReason,
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.closeDisapproveModal();
        this.closeModal();
        this.fetchWarehouseIntakes();
      } catch (error) {
        console.error('Error disapproving import:', error);
        alert('Failed to disapprove import due to a server error. Please try again later.');
      }
    },
    async approveImport(importId) {
      try {
        const token = localStorage.getItem('userToken');
        await axios.put(`https://brk-group.org/api/imports/${importId}/approve`, {}, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.closeModal();
        this.fetchWarehouseIntakes();
      } catch (error) {
        console.error('Error approving import:', error);
        alert('Failed to approve import due to a server error. Please try again later.');
      }
    },
    getImageUrl(filename) {
      return filename.startsWith('https://') ? filename : `https://brkmeeting-room.online/Products/${encodeURIComponent(filename)}`;
    },
    getImageUrl2(filename) {
      return filename.startsWith('https://') ? filename : `https://brkmeeting-room.online/${encodeURIComponent(filename)}`;
    },
   applyFilters() {
      this.filteredImports = this.imports.filter(importItem => {
        const matchesSearchQuery = this.searchQuery === '' ||
          importItem.importer_fname.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          importItem.warehouse_name.toLowerCase().includes(this.searchQuery.toLowerCase());
        const matchesStatus = this.selectedStatus === '' || importItem.status === this.selectedStatus;
        const matchesStartDate = this.startDate === '' || new Date(importItem.import_date) >= new Date(this.startDate);
        const matchesEndDate = this.endDate === '' || new Date(importItem.import_date) <= new Date(this.endDate);

        return matchesSearchQuery && matchesStatus && matchesStartDate && matchesEndDate;
      });

      this.filteredTransferOrders = this.transferOrders.filter(transferOrder => {
        const matchesSearchQuery = this.transferSearchQuery === '' ||
          transferOrder.po_number.toLowerCase().includes(this.transferSearchQuery.toLowerCase()) ||
          transferOrder.department.toLowerCase().includes(this.transferSearchQuery.toLowerCase());
        const matchesStatus = this.transferSelectedStatus === '' || transferOrder.status === this.transferSelectedStatus;
        const matchesStartDate = this.transferStartDate === '' || new Date(transferOrder.po_date) >= new Date(this.transferStartDate);
        const matchesEndDate = this.transferEndDate === '' || new Date(transferOrder.po_date) <= new Date(this.transferEndDate);

        return matchesSearchQuery && matchesStatus && matchesStartDate && matchesEndDate;
      });
    }
  },
  watch: {
    searchQuery() {
      this.applyFilters();
    },
    selectedStatus() {
      this.applyFilters();
    },
    startDate() {
      this.applyFilters();
    },
    endDate() {
      this.applyFilters();
    },
    transferSearchQuery() {
      this.applyFilters();
    },
    transferSelectedStatus() {
      this.applyFilters();
    },
    transferStartDate() {
      this.applyFilters();
    },
    transferEndDate() {
      this.applyFilters();
    }
  }
};
</script>

<style scoped>
.warehouse-intake {
  display: flex;
}

.navbar {
  background-color: #2d3748; /* สีพื้นหลังเข้ม */
  color: #fff; /* สีข้อความขาว */
  padding: 1px;
  width: 100%;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
}

.navbar li {
  padding: 10px;
  cursor: pointer;
  display: inline;
}

.navbar li.active {
  background-color: #4a5568; /* สีเมื่อ active */
}

.navbar li:hover {
  background-color: #4a5568; /* สีเมื่อ hover */
}

.content {
  flex-grow: 1;
  padding: 20px;
  background-color: #edf2f7; /* สีพื้นหลังของเนื้อหา */
  transition: all 0.3s ease; /* เพิ่มการเคลื่อนไหว */
}

.filters {
  margin-bottom: 20px;
}

.filters input,
.filters select {
  margin-right: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #cbd5e0; /* ขอบสีเทา */
  transition: border-color 0.3s ease; /* เพิ่มการเคลื่อนไหว */
}

.filters input:focus,
.filters select:focus {
  border-color: #3182ce; /* ขอบสีฟ้าเมื่อ focus */
}

.approval-table {
  width: 100%;
  border-collapse: collapse;
}

.approval-table th, .approval-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.approval-table th {
  background-color: #f2f2f2;
}

/* เพิ่มการเคลื่อนไหวเมื่อเปลี่ยน active section */
.navbar li.active {
  animation: bounceIn 0.5s; /* เพิ่ม animation */
}

/* กำหนด animation bounceIn */
@keyframes bounceIn {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  70% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>