<template>
  <div class="menu-container">
    <!-- <h1>All Menu</h1> -->
    <div class="menu-buttons">
      <router-link v-if="showHomeRepairMenu" to="/HomeRepair" class="menu-button">
        <HomeIcon class="icon" />
        <span>Home Repair</span>
      </router-link>
      <router-link  v-if="showAdminMenu" to="/admin-settings" class="menu-button">
        <CogIcon class="icon" />
        <span>Admin Settings</span>
      </router-link>
      <router-link v-if="showStockMenu"  to="/stock-menu" class="menu-button">
        <ArchiveIcon class="icon" />
        <span>Stock Menu</span>
      </router-link>
      <router-link v-if="showInsuranceMenu" to="/insurancehome" class="menu-button">
        <ShieldCheckIcon class="icon" />
        <span>Insurance Home</span>
      </router-link>
      <router-link to="/HomePur" class="menu-button">
        <ShoppingCartIcon class="icon" />
        <span>จัดซื้อ</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { HomeIcon, CogIcon, ArchiveIcon, ShieldCheckIcon, ShoppingCartIcon } from '@heroicons/vue/outline'
import axios from 'axios';

export default {
  name: 'UnifiedMenu',
  components: {
    HomeIcon,
    CogIcon,
    ArchiveIcon,
    ShieldCheckIcon,
    ShoppingCartIcon
  },
  data() {
    return {
      user: {},
      agency: null
    };
  },
  computed: {
    showHomeRepairMenu() {
      const allowedPositions = ['ธุรการซ่อมบำรุง', 'หัวหน้าซ่อมบำรุงส่วนกลาง', 'ผู้จัดการฝ่ายซ่อมบำรุง', 'ทั้งหมด'];
      return allowedPositions.includes(this.user.position_lo);
    },
    showStockMenu() {
      const allowedPositions = ['ทั้งหมด'];
      return allowedPositions.includes(this.user.position_lo);
    },
    showAdminMenu() {
      const allowedPositions = ['ทั้งหมด'];
      return allowedPositions.includes(this.user.position_lo);
    },
    showInsuranceMenu() {
      const allowedPositions = ['ทั้งหมด'];
      return allowedPositions.includes(this.user.position_lo);
    },
  },
  methods: {
    async fetchUser() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }

      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        this.agency = response.data.agency;
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' });
        }
      }
    }
  },
  async created() {
    await this.fetchUser();
  }
}
</script>

<style scoped>
.menu-container {
  padding: 20px;
}

h1 {
  text-align: center;
}

.menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.menu-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  width: 100%;
  max-width: 300px;
  text-align: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.menu-button:hover {
  background-color: #0056b3;
}

.icon {
  width: 24px;
  height: 24px;
}

@media (min-width: 600px) {
  .menu-buttons {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .menu-button {
    flex: 1 1 calc(50% - 40px);
    margin: 10px;
  }
}
</style>
