<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-semibold mb-6">จัดการประเภทอะไหล่</h1>
    <div class="mb-4">
      <button @click="showModal(true, {})" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        เพิ่มประเภทอะไหล่
      </button>
    </div>
    <div class="mb-4">
      <input v-model="searchQuery" @input="fetchPartTypes" placeholder="ค้นหาจากชื่อประเภทอะไหล่..." class="p-2 border rounded">
    </div>
    <table class="w-full table-auto">
      <thead>
        <tr class="bg-gray-200">
          <th class="px-4 py-2">ชื่อประเภท</th>
          <th class="px-4 py-2">รายละเอียด</th>
          <th class="px-4 py-2">การจัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="partType in partTypes" :key="partType.part_type_id">
          <td class="border px-4 py-2">{{ partType.type_name }}</td>
          <td class="border px-4 py-2">{{ partType.description }}</td>
          <td class="border px-4 py-2 text-center">
            <button @click="showModal(true, partType)" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded">
              แก้ไข
            </button>
            <button @click="confirmDelete(partType.part_type_id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
              ลบ
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <PartTypeModal :isVisible="isModalVisible" :partType="currentPartType" @close="handleModalClose" @save="savePartType" />
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import PartTypeModal from './PartTypeModal.vue';

export default {
  components: {
    PartTypeModal
  },
  data() {
    return {
      searchQuery: '',
      partTypes: [],
      isModalVisible: false,
      currentPartType: {}
    };
  },
  methods: {
    fetchPartTypes() {
      axios.get(`https://brk-group.org/api/part-types?search=${this.searchQuery}`)
        .then(response => {
          this.partTypes = response.data;
        })
        .catch(error => {
          console.error("Error fetching part types:", error);
        });
    },
    handleModalClose() {
      this.isModalVisible = false;
    },
    showModal(show, partType = { type_name: '', description: '' }) {
      this.isModalVisible = show;
      this.currentPartType = { ...partType };
    },
    savePartType(partType) {
      const isUpdate = !!partType.part_type_id;
      const url = isUpdate ? `https://brk-group.org/api/part-types/${partType.part_type_id}` : 'https://brk-group.org/api/part-types';
      axios({
        method: isUpdate ? 'put' : 'post',
        url: url,
        data: partType,
      })
      .then(() => {
        this.fetchPartTypes();
        this.handleModalClose();
        Swal.fire('สำเร็จ!',
            'ข้อมูลยี่ห้อสินค้าได้รับการอัปเดต',
            'success');
      })
      .catch(error => {
        console.error("Error saving part type:", error);
        Swal.fire('Error!', 'There was an issue saving the part type.', 'error');
      });
    },
    confirmDelete(partTypeId) {
      Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณจะไม่สามารถย้อนกลับได้หากลบ!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน!',
        cancelButtonText: 'ยกเลิก!',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`https://brk-group.org/api/part-types/${partTypeId}`)
          .then(() => {
            this.fetchPartTypes();
            Swal.fire('ลบเรียบร้อย!',
                'ยี่ห้อสินค้าของคุณได้ถูกลบ.',
                'success');
          })
          .catch(error => {
            console.error("Error deleting part type:", error);
            Swal.fire('Error!', 'There was an issue deleting the part type.', 'error');
          });
        }
      });
    },
  },
  mounted() {
    this.fetchPartTypes();
  }
};
</script>

