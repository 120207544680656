<template>
    <div class="container mx-auto px-4 py-6">
      <h1 class="text-2xl font-bold mb-4">Employee List</h1>
      
      <!-- Dropdown for selecting department -->
      <div class="mb-4">
        <label for="department" class="block text-sm font-medium text-gray-700">Select Department</label>
        <select id="department" v-model="selectedDepartment" @change="fetchEmployees" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm">
          <option value="">All Departments</option>
          <option v-for="department in departments" :key="department.name" :value="department.name">
            {{ department.name }}
          </option>
        </select>
      </div>
  
      <!-- Search Input -->
      <input
        type="text"
        v-model="searchQuery"
        @input="searchEmployees"
        placeholder="Search by Name or Employee ID or Department"
        class="mb-4 p-2 border border-gray-300 rounded"
      />
  
      <!-- Employee Table -->
      <table class="min-w-full bg-white divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="py-2 px-4 text-left text-sm font-medium text-gray-500">Employee ID</th>
            <th class="py-2 px-4 text-left text-sm font-medium text-gray-500">Full Name</th>
            <th class="py-2 px-4 text-left text-sm font-medium text-gray-500">Position</th>
            <th class="py-2 px-4 text-left text-sm font-medium text-gray-500">Department</th>
            <th class="py-2 px-4 text-left text-sm font-medium text-gray-500">Actions</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="employee in filteredEmployees" :key="employee.idEm">
            <td class="py-2 px-4">{{ employee.idEm }}</td>
            <td class="py-2 px-4">{{ employee.fullname }}</td>
            <td class="py-2 px-4">{{ employee.position }}</td>
            <td class="py-2 px-4">{{ employee.agency }}</td>
            <td class="py-2 px-4">
              <router-link :to="{ name: 'EmployeeDetails', params: { id: employee.idEm } }" class="text-blue-500 hover:underline">View Details</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        employees: [],
        departments: [],
        searchQuery: '',
        selectedDepartment: ''
      };
    },
    computed: {
      filteredEmployees() {
        return this.employees.filter(employee =>
          employee.fullname.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          employee.idEm.toString().includes(this.searchQuery) ||
          employee.agency.toLowerCase().includes(this.searchQuery.toLowerCase())
        ).filter(employee => 
          this.selectedDepartment === '' || employee.agency === this.selectedDepartment
        );
      }
    },
    methods: {
      async fetchEmployees() {
        try {
          const response = await axios.get('http://localhost:3000/api/hr/employees', {
            params: {
              agency: this.selectedDepartment
            }
          });
          console.log('API Response:', response.data); // Log API response
          if (Array.isArray(response.data)) {
            this.employees = response.data;
          } else if (response.data && typeof response.data === 'object') {
            this.employees = [response.data]; // Wrap the single object in an array
          } else {
            console.error('Unexpected response format:', response.data);
            this.employees = [];
          }
        } catch (error) {
          console.error('Error fetching employees:', error);
          this.employees = [];
        }
      },
      async fetchDepartments() {
        try {
          const response = await axios.get('http://localhost:3000/api/hr/departments');
          if (Array.isArray(response.data)) {
            this.departments = response.data;
          } else {
            console.error('Unexpected response format:', response.data);
          }
        } catch (error) {
          console.error('Error fetching departments:', error);
        }
      },
      searchEmployees() {
        // Filtering logic is handled by the computed property
      }
    },
    created() {
      this.fetchDepartments();
      this.fetchEmployees();
    }
  };
  </script>
  
  <style>
  /* Add any necessary styles here */
  </style>
  