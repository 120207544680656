<template>
   <link href="https://fonts.googleapis.com/css2?family=Mitr:wght@200;400&display=swap" rel="stylesheet">
  <div class="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
    <h2 class="text-2xl font-bold text-center text-gray-800 mb-8">รายละเอียดยานพาหนะ</h2>
    <div v-if="vehicle" class="space-y-4">
      <div class="flex flex-wrap -mx-2">
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>เบอร์รถ:</strong> {{ vehicle.VehicleNumber }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>ทะเบียนรถ:</strong> {{ vehicle.LicensePlate }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>เลขคัทซี:</strong> {{ vehicle.VehicleChassisNumber }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>เลขที่กรมธรรม์:</strong> {{ vehicle.PolicyNumber }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>จังหวัด:</strong> {{ vehicle.Province }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>ยี่ห้อ:</strong> {{ vehicle.Brand }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>ปีรุ่น:</strong> {{ vehicle.ModelYear }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>อายุรถ:</strong> {{ vehicle.VehicleAge }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>ประเภท:</strong> {{ vehicle.VehicleType }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>วันที่ทำ:</strong> {{ formatDate(vehicle.IssueDate) }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>วันที่หมดอายุ:</strong> {{ formatDate(vehicle.ExpiryDate) }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>ทุนประกัน:</strong> {{ vehicle.InsuredValue }}</p>
        </div>
        <!-- ข้อมูลประกันและรายการเพิ่มเติม -->
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>เบี้ยสุทธิป.1:</strong> {{ vehicle.NetPremiumPA }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>เบี้ยรวมป.1:</strong> {{ vehicle.TotalPremiumPA }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>เบี้ยสุทธิป.3:</strong> {{ vehicle.NetPremiumP3 }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>เบี้ยรวมป.3:</strong> {{ vehicle.TotalPremiumP3 }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>รยต:</strong> {{ vehicle.RYT }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>รวมเบี้ย:</strong> {{ vehicle.TotalPremium }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>บริษัทประกันภัย:</strong> {{ vehicle.InsuranceCompany }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>หน่วยงาน:</strong> {{ vehicle.Department }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>กลุ่มงาน:</strong> {{ vehicle.WorkGroup }}</p>
        </div>
        <div class="w-full md:w-1/2 px-2 mb-4">
          <p><strong>หมายเหตุ:</strong> {{ vehicle.Notes }}</p>
        </div>
      </div>
      <router-link to="/vehicles" class="inline-block bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded focus:outline-none">กลับไปยังรายการ</router-link>
    </div>
    <div v-else>
      <p class="text-center">กำลังโหลดข้อมูล...</p>
    </div>
  </div><br><br><br>
</template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        vehicle: null,
      };
    },
    
    async created() {
      await this.fetchVehicleDetail();
    },
    methods: {
      async fetchVehicleDetail() {
        try {
          const response = await axios.get(`https://brk-group.org/vehicles/${this.$route.params.VehicleID}`);
          
          // const response = await axios.get(`https://brk-group.org/vehicles/${this.$route.params.VehicleID}`);
          this.vehicle = response.data;
        } catch (error) {
          console.error(error);
          this.$router.push('/'); // Redirect to home or list if there's an error
        }
      },
      formatDate(date) {
        if (!date) return '';
      const d = new Date(date);
      d.setMinutes(d.getMinutes() - d.getTimezoneOffset()); // Adjust for timezone
      return d.toISOString().split('T')[0];
      },
      formatDateeeee(date) {
      return date.split('T')[0];
    }

    },
  };
  </script>
  
  <style scoped>
  .vehicle-detail {
    /* Enhanced styles */
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }

  .vehicle-detail h2 {
    color: #333;
  }

  .vehicle-detail p {
    margin: 0 0 10px;
    color: #666;
  }

  .vehicle-detail p strong {
    font-weight: bold;
  }
  </style>
