<template>
    <div class="container mx-auto p-6">
      <!-- ปุ่มเปิด modal สำหรับใบแจ้งซ่อมใหม่ -->
      <div class="flex justify-end mb-6">
        <button
          @click="showModal = true"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          เปิดใบแจ้งซ่อม
        </button>
      </div>
      
      <div v-if="showModal" class="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center z-50">
  <div class="bg-white rounded-lg p-6 w-full max-w-3xl">
    <div class="flex justify-between items-center mb-4">
      <h2 class="text-xl font-semibold">ใบแจ้งซ่อมใหม่</h2>
      <!-- ปุ่มปิด Modal -->
      <button @click="closeModal" class="text-gray-600 hover:text-gray-900">
        <svg class="h-6 w-6 fill-current" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M10 9.293l3.293-3.293a1 1 0 011.414 1.414L11.414 10.707l3.293 3.293a1 1 0 01-1.414 1.414L10 12.121l-3.293 3.293a1 1 0 01-1.414-1.414l3.293-3.293-3.293-3.293a1 1 0 111.414-1.414L10 9.293z" clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>
    <form @submit.prevent="submitRepairForm" class="grid grid-cols-1 md:grid-cols-2 gap-4 p-6">
 
      <div class="mb-4 md:mb-0">
          <label for="repairDate" class="block text-sm font-medium text-gray-700">วันที่แจ้งซ่อม</label>
          <input type="datetime" id="repairDate" v-model="repairForm.date" class="input-fieldmt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-500 focus:border-indigo-500 text-sm" required>
        </div>
        <div class="mb-4 md:mb-0">
          <label for="repairNumber" class="block text-sm font-medium text-gray-700">เลขที่ใบแจ้งซ่อม</label>
          <input type="text" id="repairNumber" v-model="repairForm.number" class="input-fieldmt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-500 focus:border-indigo-500 text-sm" readonly>
        </div>
        <div class="mb-4 md:mb-0">
          <label for="reporter" class="block text-sm font-medium text-gray-700">ชื่อผู้แจ้ง</label>
          <input type="text" id="reporter" v-model="repairForm.reporterName" readonly class="input-fieldmt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-500 focus:border-indigo-500 text-sm">
        </div>
 
      <div class="flex gap-4 mb-4">
        <div>
          <input type="radio" id="internalRepair" value="internal" v-model="repairForm.internalExternal">
          <label for="internalRepair" class="ml-2 text-sm font-medium text-gray-700">ซ่อมภายในหน่วยงาน</label>
        </div>
        <div>
          <input type="radio" id="externalRepair" value="external" v-model="repairForm.internalExternal">
          <label for="externalRepair" class="ml-2 text-sm font-medium text-gray-700">ซ่อมภายนอกหน่วยงาน</label>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2">
        <div class="relative">
          <input 
            type="text" 
            v-model="searchTerm" 
            @input="filterVehicles"
            placeholder="พิมพ์เพื่อค้นหายานพาหนะ..."
            class="input-field"
          >
          <div v-if="showDropdown" class="dropdown">
            <div v-for="vehicle in filteredVehicles" :key="vehicle.vehicleID" @click="selectVehicle(vehicle)" class="dropdown-item">{{ vehicle.vehicleNumber }} ({{ vehicle.licensePlate }})</div>
          </div>
        </div><br>
        <div>
          <label for="licensePlate" class="block text-sm font-medium text-gray-700">ทะเบียนรถ</label>
          <input type="text" id="licensePlate" v-model="repairForm.licensePlate" readonly class="input-fieldmt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-500 focus:border-indigo-500 text-sm">
        </div>
        <div>
          <label for="mileage" class="block text-sm font-medium text-gray-700">เลขไมค์รถ</label>
          <input type="number" id="mileage" v-model="repairForm.mileage" class="input-fieldmt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-500 focus:border-indigo-500 text-sm" required>
        </div>
        <div>
          <label for="repairType" class="block text-sm font-medium text-gray-700">ประเภทซ่อม</label>
          <select id="repairType" v-model="repairForm.repairType" class="input-fieldmt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-500 focus:border-indigo-500 text-sm">
            <option value="PM">PM</option>
            <option value="CM">CM</option>
            <option value="A">A</option>
            <option value="TireChange">เปลี่ยนยาง</option>
          </select>
        </div>
        <div>
          <label for="repairReason" class="block text-sm font-medium text-gray-700">สาเหตุการซ่อม</label>
          <input type="text" id="repairReason" v-model="repairForm.repairReason" class="input-fieldmt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-indigo-500 focus:border-indigo-500 text-sm" required>
        </div>
      </div>
      <div class="md:col-span-2 flex justify-end">
        <button type="button" @click="resetForm" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
    ล้างข้อมูล
  </button>
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
          ส่งข้อมูล
          </button>
      </div>
    </form>
  </div>
</div>



  
      <!-- ตารางสำหรับแสดงประวัติการซ่อมรถ -->
      <div class="flex flex-col">
        <div class="mb-4">
          <input type="text" placeholder="ค้นหาประวัติการซ่อมรถ" class="w-full p-2 border rounded">
        </div>
        <div class="align-middle min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table class="min-w-full">
            <thead class="bg-gray-100">
              <tr>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ลำดับ
                </th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  วันที่ส่งใบแจ้งซ่อม
                </th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  เลขที่ใบสั่งซ่อม
                </th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  สถานะ
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(history, index) in vehicleRepairHistory" :key="index">
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {{ index + 1 }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ history.dateSent }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ history.repairOrderNumber }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ history.status }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

<!-- ตารางรายการแจ้งซ่อม -->

<div class="mt-8">
  <h2 class="text-2xl font-semibold mb-4">รายการแจ้งซ่อม</h2>
  <div class="flex justify-center space-x-4 mb-4">
  <button @click="showInternal = true" :class="{'bg-gray-300': showInternal}" class="py-2 px-4 rounded">ภายในหน่วยงาน</button>
  <button @click="showInternal = false" :class="{'bg-gray-300': !showInternal}" class="py-2 px-4 rounded">ภายนอกหน่วยงาน</button>
</div>
<div class="mb-4">
  <select v-model="selectedSearchType" class="p-2 border rounded">
    <option value="ReportedBy">ชื่อผู้แจ้งซ่อม</option>
    <option value="Agency">หน่วยงาน</option>
    <option value="RepairNumber">เลขที่ใบแจ้งซ่อม</option>
    <option value="VehicleLicense">ทะเบียนรถ</option>
    <option value="Status">สถานะ</option>
  </select>
  <input v-model="searchQuery" placeholder="ค้นหา..." class="p-2 border rounded">
</div>
  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
    <table class="min-w-full">
      <thead class="bg-gray-100">
        <tr>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อผู้แจ้งซ่อม</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">หน่วยงาน</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">เลขที่ใบแจ้งซ่อม</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่ส่งใบแจ้งซ่อม</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ทะเบียนรถ</th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สถานะ</th>
          <!-- <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"></th> -->
          <!-- <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทการซ่อม</th> New column for internal/external -->
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
       <tr v-for="repair in filteredRepairs" :key="repair.id" @click="onRowClick(repair)">
          <td class="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-900">{{ repair.ReportedBy }}</td>
          <td class="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">{{ repair.Agency }}</td>
          <td class="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">{{ repair.RepairNumber }}</td>
          <td class="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">{{ formatDate(repair.DateSubmitted) }}</td>
          <td class="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">{{ repair.VehicleLicense }}</td>
          <td class="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
            <span :class="{'text-green-500': repair.Status === 'Completed', 'text-red-500': repair.Status === 'Pending'}">
              {{ repair.Status }}
            </span>
          </td>


        </tr>
        
      </tbody>
    </table>
  </div>
</div>
<div v-if="detailedInfo" class="modal">
  <div class="modal-content">
    <div class="modal-content-left">
      <span class="close-modal" @click="closeModal">{{ String.fromCharCode(215) }}</span>
      <h2 class="text-lg font-semibold mb-2">รายละเอียดเลขใบแจ้งซ่อม {{ detailedInfo.RepairNumber }}</h2>
  
  <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div>
      <p><strong class="font-semibold">ชื่อผู้แจ้งซ่อม:</strong> {{ detailedInfo.ReportedBy }}</p>
    </div>
    <div>
      <p><strong class="font-semibold">วันที่แจ้งซ่อม:</strong> {{ formatDate(detailedInfo.DateSubmitted) }}</p>
    </div>
    <div>
      <p><strong class="font-semibold">สถานะ:</strong> {{ detailedInfo.Status }}</p>
    </div>
    <div>
      <p><strong class="font-semibold">เบอร์รถ:</strong> {{ detailedInfo.VehicleNumber }}</p>
    </div>
    <div>
      <p><strong class="font-semibold">ทะเบียนรถ:</strong> {{ detailedInfo.VehicleLicense }}</p>
    </div>
    <div>
      <p><strong class="font-semibold">เลขไมค์รถ:</strong> {{ detailedInfo.Mileage }}</p>
    </div>
    <div>
      <p><strong class="font-semibold">ประเภทซ่อม:</strong> {{ detailedInfo.RepairType }}</p>
    </div>
    <div>
      <p><strong class="font-semibold">สาเหตุการซ่อม:</strong> {{ detailedInfo.RepairDescription }}</p>
    </div>
    <div>
      <p><strong class="font-semibold">ซ่อมภายในหรือภายนอก:</strong> {{ detailedInfo.InternalExternal }}</p>
    </div>
  </div>

  <div class="flex justify-between mt-4">
    <router-link 
      v-if="detailedInfo && detailedInfo.RepairNumber" 
      :to="{ name: 'RepairDetails', params: { id: detailedInfo.RepairNumber }}"
      class="px-4 py-2 bg-gray-200 text-gray-700 font-semibold rounded-lg shadow-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75">
      ดำเนินการต่อ
    </router-link>
    <button @click="deleteAction" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
      ลบ
    </button>
  </div>
    </div>
   <div v-if="detailedInfo" class="modal-content-right">
  <h3 class="text-lg font-semibold mb-2">ประวัติการแจ้งซ่อมรถของ {{ detailedInfo.ReportedBy }}</h3>
  <table class="table-auto w-full">
    <thead>
      <tr>
        <th class="px-4 py-2">ลำดับ</th>
        <th class="px-4 py-2">เลขที่ใบแจ้งซ่อม</th>
        <th class="px-4 py-2">วันที่ส่งใบแจ้งซ่อม</th>
        <th class="px-4 py-2">สถานะ</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(historyItem, index) in repairHistory" :key="historyItem.id">
        <td class="border px-4 py-2">{{ index + 1 }}</td>
        <td class="border px-4 py-2">{{ historyItem.RepairNumber }}</td>
        <td class="border px-4 py-2">{{ formatDate(historyItem.DateSubmitted) }}</td>
        <td class="border px-4 py-2">{{ historyItem.Status }}</td>
      </tr>
    </tbody>
  </table>
</div>
  </div>
</div>


<repair-modal
  v-if="showModal2 && selectedRepair"
  :repair="selectedRepair"
  :history="repairHistory"
  @close="closeModalRepair"
  :detailedInfo="detailedInfo">
</repair-modal>

  </template>
<!-- ตรวจสอบว่ามี v-if และ props ถูกต้อง -->




  <script>
import axios from 'axios';


export default {

  emits: ['authSuccess'],
  data() {
    return {
      showModal: false,
      repairId: this.id,
      showModal2: false,
      userToken: '',
      repairForm: {
        date: new Date().toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+:\d+)/, '$3-$2-$1 $4'),
        number: '',
        reporter: '',
        reporterName: '',
        internal: false,
        external: false,
        vehicleID: '',
        licensePlate: '',
        mileage: '',
        repairType: '',
        repairReason: '',
        vehicleNumber: '' // เบอร์รถ
      },
      showInternal: true,
      selectedSearchType: 'name', // ค่าเริ่มต้น
    searchQuery: '',
      repairs: [],
      repairOrders: [],
      vehicleRepairHistory: [],
      vehicles: [], // รายการยานพาหนะจาก API
      selectedVehicle: null, // รถที่เลือกจาก dropdown
      selectedRepair: null,
      repairHistory: [],

      detailedInfo: null,
      searchTerm: '', // เพิ่ม searchTerm ในข้อมูล
      showDropdown: false, // ควบคุมการแสดงหรือซ่อน dropdown
      filteredVehicles: []
    };
  },
  computed: {
    filteredRepairs() {
    let filteredByType = this.repairs.filter(repair => {
      return this.showInternal ? repair.InternalExternal === 'ภายใน' : repair.InternalExternal === 'ภายนอก';
    });

    if (!this.searchQuery.trim()) {
      return filteredByType;
    }

    return filteredByType.filter((repair) => {
      // ตรวจสอบว่า property นั้นมีอยู่จริงและไม่เป็น null หรือ undefined ก่อนเรียกใช้ toString()
      const value = repair[this.selectedSearchType];
      if (value == null) return false; // หรือจัดการในวิธีที่เหมาะสมถ้าค่าไม่มีอยู่
      return value.toString().toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }
  },
  watch: {
    selectedRepair: {
      handler: 'updateModalContent',
      deep: true,
      immediate: true
    },
    repairHistory: {
      handler: 'updateModalContent',
      deep: true,
      immediate: true
    }
  },
  
  methods: {
    formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
    return new Date(dateString).toLocaleDateString('th-TH', options) + ' น.';
  },
  onSelectDetail(info) {
  this.detailedInfo = info;
  if (!this.detailedInfo.id) {
    console.error('Detailed info is missing the ID.');
  }
},
  onRowClick(repair) {
  console.log('Row clicked, repair data:', repair);
  this.selectedRepair = repair;
  this.detailedInfo = repair; // Set detailedInfo when a row in the table is clicked
  this.showModal2 = true;
  this.fetchRepairHistory(repair.LoginId) // ตอนนี้คุณใช้ LoginId เป็นตัวระบุเพื่อดึงประวัติการซ่อม
    .then(history => {
      // console.log('Fetched history:', history);
      this.vehicleRepairHistory = history;
    }).catch(err => {
      console.error('Error fetching repair history:', err);
      // Handle the error
    });
},

  
async deleteAction() {
    console.log('Attempting to delete');
  if (!this.detailedInfo || this.detailedInfo.Status !== 'แจ้งซ่อม') {
    alert('ไม่สามารถลบรายการแจ้งซ่อมนี้ได้ เนื่องจากสถานะไม่ใช่ "แจ้งซ่อม"');
    return;
  }

  try {
    await axios.delete(`https://brk-group.org/api/repairs/${this.detailedInfo.RepairNumber}`);
    // console.log('Deletion success:', response.data);
    alert('รายการแจ้งซ่อมถูกลบเรียบร้อยแล้ว');
    this.repairOrders = this.repairOrders.filter(item => item.RepairNumber !== this.detailedInfo.RepairNumber);
    await this.fetchRepairs();
    this.detailedInfo = null;
  } catch (error) {
    console.error('Error:', error);
    alert('ไม่สามารถลบรายการแจ้งซ่อมได้');
  }
  },


async fetchRepairHistory(loginId) {
  console.log('Fetching repair history for login ID:', loginId);
  try {
    const response = await axios.get(`https://brk-group.org/api/repair-history/${loginId}`);
    console.log('Repair history response data:', response.data);
    this.repairHistory = response.data; // This should update repairHistory used in the v-for loop
  } catch (error) {
    console.error('Error fetching repair history:', error);
    this.repairHistory = []; // Set an empty array on error
  }
},


    openRepairForm() {
        this.showModal = true;
        this.fetchReporterDetails();
    },
    filterVehicles() {
      if (!this.searchTerm.trim()) {
        this.showDropdown = false;
        return;
      }
      this.filteredVehicles = this.vehicles.filter(vehicle =>
        vehicle.vehicleNumber.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        vehicle.licensePlate.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
      this.showDropdown = this.filteredVehicles.length > 0;
    },
    selectVehicle(vehicle) {
    this.searchTerm = vehicle.vehicleNumber;  // อัปเดต searchTerm เป็นชื่อของยานพาหนะ
    this.repairForm.vehicleID = vehicle.vehicleID; // ตรวจสอบให้แน่ใจว่าได้กำหนดค่า ID ของยานพาหนะ
  this.repairForm.vehicleNumber = vehicle.vehicleNumber; // เลขเบอร์รถ
  this.repairForm.licensePlate = vehicle.licensePlate; // ทะเบียนรถ
  this.showDropdown = false; // ปิด dropdown
},


    async fetchVehicles() {
      try {
        const response = await axios.get('https://brk-group.org/api/vehicles');
        this.vehicles = response.data.map(vehicle => ({
          vehicleID: vehicle.VehicleID,
          vehicleNumber: vehicle.VehicleNumber,
          licensePlate: vehicle.LicensePlate
        }));
        // console.log("Vehicles loaded:", this.vehicles); // Log loaded vehicles
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    },
    fetchVehicleDetails(selectedVehicle) {
      console.log("Selected vehicle:", selectedVehicle); // Log the selected vehicle
      this.repairForm.vehicleID = selectedVehicle.vehicleID;
      this.repairForm.vehicleNumber = selectedVehicle.vehicleNumber;
      this.repairForm.licensePlate = selectedVehicle.licensePlate;
    },

    async fetchRepairs() {
    try {
      const response = await axios.get('https://brk-group.org/api/repairs');
      this.repairs = response.data; // จัดเก็บข้อมูลการแจ้งซ่อมใน state
    } catch (error) {
      console.error('Failed to fetch repairs:', error);
    }
  },
  
  async fetchReporterDetails() {
  const token = localStorage.getItem('userToken');
  if (!token) {
    console.error('No token found. Redirecting to login...');
    alert('You are not logged in or your session has expired. Please log in again.');
    this.$router.push({ name: 'Login' }); // ตรวจสอบให้แน่ใจว่าชื่อ route ตรงกับชื่อที่กำหนดใน router ของคุณ
    return; // หยุดการดำเนินการเพิ่มเติมถ้าไม่มี token
  }
  try {
    const response = await axios.get('https://brk-group.org/api/user-details', {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    if (response.data && response.data.userId) {
      this.repairForm.reporter = response.data.userId; // จัดเก็บ ID
      this.repairForm.reporterName = response.data.fullName; // จัดเก็บชื่อเต็มเพื่อแสดง
    } else {
      // ดำเนินการเมื่อไม่มีข้อมูลผู้ใช้ที่ถูกต้อง
      console.error('Invalid user details received:', response.data);
      alert('กรุณาล็อคอินใหม่');
      this.$router.push({ name: 'Login' }); // กลับไปที่หน้า login
    }
  } catch (error) {
    console.error('ERR:', error);
    alert('Failed to fetch reporter details due to a server error. Please try again later.');
    this.$router.push({ name: 'Login' }); // กลับไปที่หน้า login ในกรณีที่มี error
  }
}
,
resetForm() {
    this.repairForm = {
      date: new Date().toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/(\d+)\/(\d+)\/(\d+), (\d+:\d+:\d+)/, '$3-$2-$1 $4'), // ตั้งค่าเวลาใหม่
      number: '',
      reporter: '',
      reporterName: '',
      internal: false,
      external: false,
      vehicleID: '',
      licensePlate: '',
      mileage: '',
      repairType: '',
      repairReason: '',
      vehicleNumber: '',  // เลขเบอร์รถ

    };
    
    this.fetchReporterDetails();
  },
    submitRepairForm() {
        // if (!this.validateForm()) {
        //     alert('กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วนและถูกต้อง.');
        //     return;
        // }
        const dataToSend = {
          date: this.repairForm.date,
    number: this.repairForm.number,
    reporter: this.repairForm.reporter,
    internal: this.repairForm.internalExternal === 'internal',
    external: this.repairForm.internalExternal === 'external',
    vehicleID: this.repairForm.vehicleID,
    licensePlate: this.repairForm.licensePlate,
    mileage: this.repairForm.mileage,
    repairType: this.repairForm.repairType,
    repairReason: this.repairForm.repairReason
        };

        axios.post('https://brk-group.org/submit-repair', dataToSend, {
            headers: {
                Authorization: `Bearer ${this.userToken}`
            }
        })
        .then(response => {
            console.log('Server Response:', response.data);
            alert('การแจ้งซ่อมได้ถูกส่งเรียบร้อยแล้ว!');
            this.resetForm();
            this.closeModal();
            this.fetchRepairs();
        })
        .catch(error => {
            console.error('Error:', error);
            alert('ไม่สามารถส่งการแจ้งซ่อมได้');
        });
    },
    fetchRepairOrders() {
    axios.get('https://brk-group.org/api/repair-orders')
      .then(response => {
        this.repairOrders = response.data;
      })
      .catch(error => {
        console.error('Error fetching repair orders:', error);
      });
  },
  validateForm() {
    return this.repairForm.date && 
           this.repairForm.vehicleID && 
           this.repairForm.reporter && 
           this.repairForm.repairType && 
           this.repairForm.mileage && 
           this.repairForm.repairReason; // ตรวจสอบว่าฟิลด์ที่ต้องการทั้งหมดไม่ว่าง
},

    closeModal() {
      this.showModal = false;
      this.showModal2 = false;
      this.selectedRepair = null;
      this.detailedInfo = null; // เพิ่มบรรทัดนี้
    }
},

created() {
  this.fetchRepairOrders();
  this.fetchVehicles();
  this.fetchRepairs();
  // console.log(this.detailedInfo);
},
mounted() {
    this.fetchReporterDetails();
    this.fetchVehicles();
    this.fetchRepairs();
  },
};

</script>

<style scoped>
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */
  }

  /* Modal content container */
  .modal-content {
    display: flex;
    flex-direction: row;
    width: 80%;
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 4px;
  }

  /* Left column */
  .modal-content-left {
    flex: 1;
    padding-right: 10px;
  }

  /* Right column */
  .modal-content-right {
    flex: 1;
    padding-left: 10px;
  }

  .close-modal {
  position: absolute; /* ใช้คุณสมบัติ position เพื่อจัดตำแหน่งปุ่ม */
  top: 1rem; /* กำหนดระยะจากขอบบนของโมดัล */
  right: 1rem; /* กำหนดระยะจากขอบขวาของโมดัล */
  background-color: #f05454; /* กำหนดสีพื้นหลังของปุ่ม */
  color: white; /* กำหนดสีของเครื่องหมาย X */
  border: none; /* ไม่ใช้ขอบใดๆ สำหรับปุ่ม */
  border-radius: 0.25rem; /* กำหนดรูปมุมให้ปุ่มเป็นวงกลมเล็กน้อย */
  padding: 0.5rem; /* กำหนดพื้นที่รอบปุ่ม */
  cursor: pointer; /* เปลี่ยนเคอร์เซอร์เมื่อโฮเวอร์ */
  z-index: 1050; /* กำหนดให้ปุ่มอยู่เหนือโมดัลอื่นๆ */
}

/* ตั้งค่าสไตล์สำหรับเมื่อโฮเวอร์ปุ่ม */
.close-modal:hover {
  background-color: #ff4747; /* เปลี่ยนสีพื้นหลังของปุ่มเมื่อโฮเวอร์ */
}


.dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    width: 100%; /* This makes the dropdown full width of its parent container */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds shadow for better visibility */
}

.dropdown div {
    padding: 10px 15px; /* Increased padding for better touch targets on mobile */
    cursor: pointer;
    white-space: nowrap; /* Ensures the text doesn't wrap */
    overflow: hidden; /* Prevents text from overflowing */
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long to fit */
}

.dropdown div:hover {
    background-color: #f1f1f1;
}
.modal-content {
  max-height: 80vh; /* ขนาดสูงสุดของเนื้อหาโมดัลไม่เกิน 80% ของความสูงของหน้าจอ */
  overflow-y: auto; /* เพิ่ม scrollbar ในแนวตั้งหากเนื้อหาล้น */
}

/* สำหรับตารางเพิ่มให้กับ CSS ที่คุณใช้อยู่เพื่อจัดการกับขนาดของตาราง */
.table-auto {
  width: 100%; /* กำหนดให้ตารางมีความกว้างเต็มความกว้างที่มันสามารถใช้ได้ภายในโมดัล */
  max-height: 65vh; /* กำหนดความสูงสูงสุดของตาราง */
  overflow-y: auto; /* เพิ่ม scrollbar ในแนวตั้งหากเนื้อหาล้น */
}
/* Responsive adjustments */
@media (max-width: 768px) {
    .dropdown {
        width: 100%; /* Full width on smaller screens */
        max-height: 150px; /* Shorter dropdown on smaller screens */
    }

    .dropdown div {
        padding: 8px 12px; /* Smaller padding on smaller screens */
    }
}

@media (max-width: 480px) {
    .dropdown {
        position: relative; /* Changes position for very small screens */
        border: none; /* Removes border on smaller screens for a cleaner look */
    }
}


</style>