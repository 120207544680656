<template>

    <div class="container mx-auto mt-8 p-4">
      <h1 class="text-2xl font-bold mb-6">รายการนำเข้าสินค้า</h1>
      <form @submit.prevent="">
        <div class="grid grid-cols-2 gap-4 ">
          <!-- Import Information Section -->
          <div class="col-span-2 grid grid-cols-2 gap-4 shadow-lg rounded-lg">
            <div>
              <label class="block text-sm font-medium text-gray-700">เลขที่ใบนำเข้า:</label>
              <input type="text" v-model="importForm.docNumber" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-center">
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">วันที่รับเข้า:</label>
              <input type="date" v-model="importForm.importDate" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-center">
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">ชื่อผู้นำเข้า:</label>
              <input type="text" v-model="importForm.importerName" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-center">
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">ตำแหน่ง:</label>
              <input type="text" v-model="importForm.importerPosition" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-center">
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">หน่วยงาน:</label>
  
                <input type="text" v-model="importForm.agency" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-center">
       
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">คลังที่รับ:</label>
              <select v-model="importForm.warehouseId" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-center">
                <option v-for="warehouse in warehouses" :key="warehouse.warehouse_id" :value="warehouse.warehouse_id">
                  {{ warehouse.warehouse_name }} ({{ warehouse.group_name }})
                </option>
              </select>
            </div>
            <!-- <div>
              <label class="block text-sm font-medium text-gray-700">ชื่อผู้รับ:</label>
              <input type="text" v-model="importForm.inspectorName" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-center">
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700">ตำแหน่ง:</label>
              <input type="text" v-model="importForm.inspectorPosition" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm text-center">
            </div> -->
           
          </div>
  
          <div class="col-span-2 mt-4">
            <h3 class="text-lg font-bold text-black-900">รายการสินค้า</h3>
            <br>
            <button @click="showAddProductModal = true" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-2">
              เพิ่มรายการสินค้า
            </button>
            <div class="mt-2">
              <table class="min-w-full divide-y divide-gray-200 shadow-lg rounded-lg">
                <thead class="bg-gray-50 shadow-sm">
                  <tr>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">รหัสสินค้า</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">ชื่อสินค้า</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">ประเภทรถ</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">ประเภทสินค้า</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">หน่วยเรียก</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">ยี่ห้อรถ</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">ประเภทอะไหล่</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">จำนวนสินค้าที่รับเข้า</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">ราคาต่อหน่วย</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">รวมเป็นเงิน</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">ภาพประกอบ</th>
                    <th class="px-6 py-3 text-center text-base font-semibold text-gray-700 uppercase tracking-wider">การดำเนินการ</th>
                  </tr>
                </thead>
              <tbody class="bg-white divide-y divide-gray-200 shadow">
                <tr v-for="(product, index) in addedProducts" :key="product.product_id">
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.product_code }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.product_name }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.vehicle_type }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.product_type }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.unit_name }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.brand_name }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.part_type }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.quantity }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.unitPrice }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">{{ product.totalPrice }}</td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
                      <img v-if="product.imageUrl" :src="product.imageUrl" class="w-10 h-10 rounded-full cursor-pointer" alt="Product Image" @click="openImageModal(product.imageUrl)">
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
                    <button @click="editProduct(product, index)" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mx-1">แก้ไข</button>
                    <button @click="deleteProduct(product.product_id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mx-1">ลบ</button>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </form>
      <br><br><br><br>
      
      <div class="col-span-2 flex justify-end space-x-4">
              <!-- <button type="button" @click="cancelImport" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิกใบนำเข้า
              </button> -->
              <button type="submit" @click.prevent="submitImport" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
              บันทึกใบนำเข้า
              </button>
            </div>
    </div>


 <!-- Modal Layout -->
 <div v-if="showAddProductModal" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
    <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="width: 95%; max-height: 80vh;">
      <h2 class="text-xl font-bold mb-4">เพิ่มรายการสินค้าใหม่</h2>
      <button @click="showAddProductModal = false" class="absolute top-3 right-3 text-gray-600 hover:text-gray-900">
        <!-- SVG for closing modal -->
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <input type="text" v-model="search" placeholder="ค้นหาจากชื่อสินค้าหรือรหัสสินค้า" class="mb-4 p-2 w-full border rounded">
      <div class="flex justify-center my-4">
        <button @click="prevPage" :disabled="currentPage <= 1" class="mx-2 px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400 disabled:opacity-50">Previous</button>
        <button @click="nextPage" :disabled="currentPage >= pageCount" class="mx-2 px-4 py-2 bg-gray-300 text-black rounded hover:bg-gray-400 disabled:opacity-50">Next</button>
      </div>
      <div class="overflow-x-auto">
        <table class="min-w-full table-auto">
          <thead class="bg-gray-200">
            <tr>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">รหัสสินค้า</th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">รูปภาพ</th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">ชื่อสินค้า</th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">ประเภทรถ</th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">ประเภทสินค้า</th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">หน่วยเรียก</th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">ยี่ห้อรถ</th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">ประเภทอะไหล่</th>
              <th class="px-6 py-3 border-b-2 border-gray-300 text-center leading-4 text-blue-500 tracking-wider">การดำเนินการ</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="paginatedProducts.length">
              <tr v-for="product in paginatedProducts" :key="product.product_id" @click="selectProduct(product)" class="cursor-pointer hover:bg-gray-100">
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ product.product_code }}</td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  <img :src="product.image_url" alt="Product Image" class="h-10 w-10 rounded-full">
                </td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ product.product_name }}</td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ product.vehicle_type }}</td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ product.product_type }}</td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ product.unit_name }}</td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ product.brand_name }}</td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">{{ product.part_type }}</td>
                <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                  <button @click.stop="openPartTypeModal(product)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    +
                  </button>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="9" class="text-center text-gray-500">ไม่มีข้อมูลสินค้า</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

<!-- modal + -->
<div v-if="showPartTypeModal" class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
    <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="width: 70%; max-height: 80vh;">
      <h2 class="text-xl font-bold mb-4">รายละเอียดสินค้า</h2>

      <!-- Grid Layout -->
      <div class="grid grid-cols-4 gap-3">
       

        <!-- รหัสสินค้า -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">รหัสสินค้า</label>
          <input type="text" readonly :value="selectedProduct.product_code" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
        </div>

         <!-- ชื่อสินค้า -->
         <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ชื่อสินค้า</label>
          <input type="text" readonly :value="selectedProduct.product_name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
        </div>
        
        <!-- ประเภทรถ -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทรถ</label>
          <input type="text" readonly :value="selectedProduct.vehicle_type" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
        </div>

        <!-- ประเภทสินค้า -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทสินค้า</label>
          <input type="text" readonly :value="selectedProduct.product_type" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
        </div>

        <!-- หน่วยเรียก -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">หน่วยเรียก</label>
          <input type="text" readonly :value="selectedProduct.unit_name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
        </div>

        <!-- ยี่ห้อรถ -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ยี่ห้อรถ</label>
          <input type="text" readonly :value="selectedProduct.brand_name" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
        </div>

        <!-- ประเภทอะไหล่ -->
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทอะไหล่</label>
          <input type="text" readonly :value="selectedProduct.part_type" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
        </div>
      </div>
      <!-- จำนวนสินค้าที่รับเข้า -->
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">จำนวนสินค้าที่รับเข้า</label>
        <input type="number" v-model="selectedProduct.quantity" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
      </div>

      <!-- ราคาต่อหน่วย -->
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">ราคาต่อหน่วย</label>
        <input type="number" v-model="selectedProduct.unitPrice" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
      </div>

      <!-- รวมเป็นเงิน -->
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">รวมเป็นเงิน</label>
        <input type="number" :value="totalPrice" readonly class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight">
      </div>

      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">รูปภาพสินค้า</label>
        <input type="file" @change="handleImageUpload" class="block w-full text-sm text-gray-500
          file:mr-4 file:py-2 file:px-4
          file:rounded-full file:border-0
          file:text-sm file:font-semibold
          file:bg-blue-50 file:text-blue-700
          hover:file:bg-blue-100">

          <div v-if="selectedProduct.imageUrl" class="mt-2">
          <img :src="selectedProduct.imageUrl" alt="Image Preview" class="max-w-xs h-auto rounded">
          </div>
      </div>
      
      <div class="flex items-center justify-between">
        <button @click="confirmAddProduct" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          ตกลง
        </button>
        <button @click="closePartTypeModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          ปิด
        </button>
      </div>
    </div>
  </div>

<!-- Modal แสดงรูปภาพ -->
<div v-if="showImageModal" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center p-4">
  <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto relative" style="width: 70%; max-height: 80vh;">
    <!-- ใช้ SVG กากบาทเป็นปุ่มปิด -->
    <button @click="showImageModal = false" class="absolute top-0 right-0 m-4 p-2 bg-red-500 hover:bg-red-700 text-white font-bold rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
    <img :src="currentImageUrl" alt="Full Size Image" class="rounded">
    <button @click="showImageModal = false" class="mt-3 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
      ปิด
    </button>
  </div>
</div>


  </template>
  
  <script>
import axios from 'axios';

export default {

  data() {
    return {
      warehouses: [],
      agencies: [], // เพิ่มตัวแปรสำหรับเก็บข้อมูลหน่วยงาน
      latestDocNumber: 1, // เลขที่ใบนำเข้าล่าสุดเริ่มต้นที่ 1
      showModal: false,
      showAddProductModal: false,
      showAddProductList: false,
      showPartTypeModal: false,
      showImageModal: false, // สถานะการแสดง modal รูปภาพ
      currentImageUrl: "", // URL ของรูปภาพที่จะแสดง
      selectedProduct: {
        product_id: null,
        product_name: "",
        product_code: "",
        vehicle_type: "",
        product_type: "",
        unit_name: "",
        brand_name: "",
        part_type: "",
        quantity: 0,
        unitPrice: 0,
        description: "",
        imageUrl: null,  // ใช้สำหรับตัวอย่างรูปภาพ
      },
      userDetails: {
        importerName: '',
        importerPosition: '',
        agency: ''
      },
      editingIndex: -1, 
      isEditing: false, // สถานะการแก้ไ
      importForm: {
            importDate: '',
            importerId: null, // id ของผู้นำเข้า
            importerPosition: '', // ตำแหน่งของผู้นำเข้า
            agency: '', // หน่วยงานของผู้นำเข้า
            warehouseId: '', // คลังที่รับ
            inspectorId: null, // id ของผู้ตรวจสอบ
            inspectorPosition: '', // ตำแหน่งของผู้ตรวจสอบ
            status: 'รออนุมัติ',
      },
      search: '',
      currentPage: 1,
      itemsPerPage: 10, // จำนวนรายการต่อหน้า
      products: [], // ข้อมูลสินค้าทั้งหมด
      addedProducts: [], // สำหรับเก็บสินค้าที่เพิ่มผ่าน modal
      loading: false,
      error: '',
      
    };
  },
  mounted() {
      this.importForm.docNumber = this.generateDocNumber();
      this.fetchUserDetails();
      this.fetchProducts();
      this.fetchWarehouses();
      if (this.importId) {
      this.fetchImportDetails(this.importId);
    }
      // this.generateDocNumber();
      // this.fetchAgency();
      
    },
  computed: {
    pageCount() {
      // คำนวณจำนวนหน้าทั้งหมด
      return Math.ceil(this.filteredProducts.length / this.itemsPerPage);
    },
    paginatedProducts() {
      // คำนวณสินค้าที่แสดงในหน้าปัจจุบัน
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredProducts.slice(start, end);
    },
    filteredProducts() {
      // กรองสินค้าตามเงื่อนไขการค้นหา
      return this.products.filter(product =>
        product.product_name.toLowerCase().includes(this.search.toLowerCase()) ||
        product.product_code.toLowerCase().includes(this.search.toLowerCase())
      );
      
    },
    totalPrice() {
      return this.selectedProduct.quantity * this.selectedProduct.unitPrice;
    }
  },
  watch: {
    filteredProducts(newValue) {
      this.pageCount = Math.ceil(newValue.length / this.itemsPerPage);
    }
  },
  methods: {
    
    // async fetchAgency() {
    //   try {
    //     const response = await axios.get('https://brk-group.org/api/agency');
    //     this.agencies = response.data;
    //   } catch (error) {
    //     console.error('Error fetching agency:', error);
    //     alert('Failed to fetch agency due to a server error. Please try again later.');
    //   }
    // },
    async fetchWarehouses() {
      try {
        const response = await axios.get('https://brk-group.org/api/stockwarehouses');
        this.warehouses = response.data;
      } catch (error) {
        console.error('Error fetching warehouses:', error);
        alert('Failed to fetch warehouses due to a server error. Please try again later.');
      }
    },
      // ฟังก์ชันเพื่อดึงข้อมูลผู้ใช้จากเซิร์ฟเวอร์โดยใช้ userToken
    async fetchUserDetails() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        console.error('No token found. Redirecting to login...');
        alert('You are not logged in or your session has expired. Please log in again.');
        this.$router.push({ name: 'Login' }); // ตรวจสอบให้แน่ใจว่าชื่อ route ตรงกับชื่อที่กำหนดใน router ของคุณ
        return; // หยุดการดำเนินการเพิ่มเติมถ้าไม่มี token
      }
      try {
        const response = await axios.get('https://brk-group.org/api/user-stock', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (response.data && response.data.userId) {
          this.userDetails.importerName = response.data.fullName;
          this.userDetails.importerPosition = response.data.position;
          this.userDetails.agency = response.data.agency;
          this.importForm.importerName = response.data.fullName; // จัดเก็บชื่อเต็มเพื่อแสดง
          this.importForm.importerPosition = response.data.position;
          this.importForm.agency = response.data.agency;
        } else {
          // ดำเนินการเมื่อไม่มีข้อมูลผู้ใช้ที่ถูกต้อง
          console.error('Invalid user details received:', response.data);
          alert('กรุณาล็อคอินใหม่');
          this.$router.push({ name: 'Login' }); // กลับไปที่หน้า login
        }
      } catch (error) {
        console.error('ERR:', error);
        alert('Failed to fetch user details due to a server error. Please try again later.');
        this.$router.push({ name: 'Login' }); // กลับไปที่หน้า login ในกรณีที่มี error
      }
    },
  setUserDetails(userData) {
    this.importForm.importerName = `${userData.prefixname} ${userData.fname} ${userData.lname}`;
    this.importForm.importerPosition = userData.position_lo;
    this.importForm.agency = userData.department;
  },
  async generateDocNumber() {
      try {
        const response = await axios.get('https://brk-group.org/api/latest-doc-number', {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('userToken')}` }
        });
        const latestDocNumber = response.data.latestDocNumber;
        const latestNumber = parseInt(latestDocNumber.replace('IMP', ''), 10) + 1;
        this.importForm.docNumber = `IMP${String(latestNumber).padStart(5, '0')}`;
      } catch (error) {
        console.error('Error generating doc number:', error);
        this.importForm.docNumber = 'IMP00001';
      }
    },
  async submitImport() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        console.error('No token found. Redirecting to login...');
        alert('You are not logged in or your session has expired. Please log in again.');
        this.$router.push({ name: 'Login' });
        return;
      }
      try {
        const formData = new FormData();
        formData.append('docNumber', this.importForm.docNumber);
        formData.append('importDate', this.importForm.importDate);
        formData.append('warehouseId', this.importForm.warehouseId);
        formData.append('status', this.importForm.status); // เพิ่มฟิลด์ status
        formData.append('products', JSON.stringify(this.addedProducts));

        for (let product of this.addedProducts) {
          if (product.imageUrl && product.imageUrl.startsWith('blob')) {
            const response = await fetch(product.imageUrl);
            const blob = await response.blob();
            const file = new File([blob], 'image.jpg', { type: blob.type });
            formData.append('images', file);
          }
        }

        await axios.post('https://brk-group.org/api/imports', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });

        alert('นำเข้าสินค้าสำเร็จ');
        this.resetForm();
      } catch (error) {
        console.error('Error submitting import:', error);
      }
    },

    handleImageUpload(event) {
    const file = event.target.files[0]; // ได้รับไฟล์จาก input
    this.selectedProduct.imageFile = file;
    if (file) {
      this.selectedProduct.imageUrl = URL.createObjectURL(file); // สร้าง URL สำหรับตัวอย่าง
    }
  },
  openImageModal(imageUrl) {
    this.currentImageUrl = imageUrl;
    this.showImageModal = true;
  },
    confirmAddProduct() {
    // Ensure totalPrice gets updated when changes are saved
    this.selectedProduct.totalPrice = this.totalPrice; // Update the totalPrice based on computed value

    if (this.editingIndex === -1) {
      this.addedProducts.push({...this.selectedProduct});
    } else {
      this.addedProducts.splice(this.editingIndex, 1, {...this.selectedProduct});
    }
    this.closePartTypeModal();
  },
    openPartTypeModal(product) {
    this.selectedProduct = {
      ...product,
      quantity: 1,  // ตั้งค่าเริ่มต้นเป็น 1 สำหรับฟอร์ม
      unitPrice: product.unit_price || 0,  // ใช้ unit_price จากข้อมูลสินค้าหรือ 0 ถ้าไม่มี
      description: product.description || ''
    };
    this.showPartTypeModal = true;
  },
    closePartTypeModal() {
      this.selectedProduct = null;
    this.editingIndex = -1;
    this.showPartTypeModal = false;  // Close the modal
    },

    nextPage() {
      // ไปยังหน้าถัดไป
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    prevPage() {
      // กลับไปยังหน้าก่อนหน้า
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    fetchProducts() {
      this.loading = true;
      axios.get('https://brk-group.org/products')
        .then(response => {
          this.products = response.data;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching products:', error);
          this.error = 'Failed to load products. Please try again.';
          this.loading = false;
        });
    },
    getImageUrl(filename) {
      // สร้าง URL สำหรับรูปภาพ
      const baseUrl = "https://brkmeeting-room.online/";
      return `${baseUrl}${filename}`;
    },
    cancelImport() {
      console.log('Import Cancelled');
    },
    editProduct(product, index) {
    // เปิด modal พร้อมข้อมูลสินค้าที่จะแก้ไข
    this.selectedProduct = {...product};
    this.editingIndex = index;  // Set the current index for editing
    this.showPartTypeModal = true;

  },
  saveProduct() {
    if (this.isEditing) {
      const index = this.addedProducts.findIndex(p => p.product_id === this.selectedProduct.product_id);
      if (index !== -1) {
        this.addedProducts.splice(index, 1, {...this.selectedProduct});
      }
    } else {
      this.addedProducts.push({...this.selectedProduct});
    }
    this.resetForm();
  },
  resetForm() {
    this.importForm = {
      docNumber: this.generateDocNumber(),
      importDate: '',
        importerName: this.userDetails.importerName, // Retain user details
        importerPosition: this.userDetails.importerPosition, // Retain user details
        agency: this.userDetails.agency, // Retain user details
        warehouseId: '',
        inspectorName: '',
        inspectorPosition: '',
      };
      this.addedProducts = [];
      this.selectedProduct = {
        product_id: null,
        product_name: "",
        product_code: "",
        vehicle_type: "",
        product_type: "",
        unit_name: "",
        brand_name: "",
        part_type: "",
        quantity: 0,
        unitPrice: 0,
        description: "",
        imageUrl: "",
      };
      this.isEditing = false;
    },
  deleteProduct(productId) {
    // ลบสินค้าจากอาเรย์ addedProducts
    this.addedProducts = this.addedProducts.filter(p => p.product_id !== productId);
  }
  }
};
</script>
  
