<template>
  <div class="home-container">
    <home-menu :menus="filteredMenus"></home-menu>
  </div>
</template>

<script>
import HomeMenu from '@/components/Purchase/HomeMenu.vue';
import axios from 'axios';

export default {
  components: {
    HomeMenu
  },
  data() {
  return {
    menus: [
      {
        title: 'รายการคู่ค้า',
        icon: 'document-text',  // เพิ่มไอคอน
        route: '/suppliers',
        class: 'supplier-menu'
      },
      {
        title: 'รายการใบ (PR) และ (PO)',
        icon: 'archive',  // เพิ่มไอคอน
        route: '/purchase-recives',
        class: 'pr-menu'
      },
      {
        title: 'สร้างใบขอซื้อ (PRE)',
        icon: 'shopping-cart',  // เพิ่มไอคอน
        route: '/purchase-requisition',
        class: 'pre-menu'
      },
      {
        title: 'ประเภทคู่ค้า',
        icon: 'shopping-cart',
        route: '/suppliermanage',
        class: 'supplier-manage-menu'
      },
      {
        title: 'สินค้า',
        icon: 'box',
        route: '/products',
        class: 'product-menu'
      },
      {
        title: 'ประเภทสินค้า',
        icon: 'tags',
        route: '/product-types',
        class: 'product-type-menu'
      },
      {
        title: 'ประวัติซ่อม',
        icon: 'clipboard-list',
        route: '/repair-history',
        class: 'repair-history-menu'
      },
      {
        title: 'รายงานการซื้อ',
        icon: 'chart-bar',
        route: '/purchase-report',
        class: 'report-menu bg-indigo-500'
      },
      {
        title: 'รายงานใบขอซื้อ',
        icon: 'clipboard-list',
        route: '/purchase-request',
        class: 'request-menu bg-orange-500'
      }
    ],
      user: {}
    }
  },
  
  methods: {
    async fetchUser() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }

      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        this.agency = response.data.agency;
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' });
        }
      }
    }
  },
  computed: {
    filteredMenus() {
      let updatedMenus = [...this.menus];
    
      if (this.user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่') {
        updatedMenus.push({ title: 'อนุมัติ', route: '/approve-po', class: 'approve-po-menu' });
        updatedMenus.push({ title: 'รายการใบPOด่วน', route: '/po-list', class: 'po-list-menu' });
      }
      if (this.user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ') {
    updatedMenus.push({ title: 'รายการใบPOด่วน', route: '/po-list', class: 'po-list-menu' });
  }
      return updatedMenus;
    }
  },
  async created() {
    await this.fetchUser();
  }
};
</script>

<style scoped>
.home-container {
  padding: 20px;
}
</style>
