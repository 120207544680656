<template>
    <div class="p-6">
      <h1 class="text-2xl font-bold text-center mb-6">Stock Management</h1>
      <div class="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6">
        <router-link to="/purchase-add" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">เบิกสินค้า</button>
        </router-link>
        <router-link to="/importpage" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">นำเข้าสินค้า</button>
        </router-link>
        <router-link to="/warehouse-intake" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">รับเข้าเข้าคลัง</button>
        </router-link>
        <router-link to="/add-warehouse" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">Stock</button>
        </router-link>
        <router-link to="/payment-check" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">โอนย้ายสินค้า</button>
        </router-link>
        <router-link to="/stockdashboard" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">Dashboard</button>
        </router-link>
        <router-link to="/wheel-parts" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">จัดการรถยนต์</button>
        </router-link>
        <router-link to="/transaction-manage" custom v-slot="{ navigate }">
          <button @click="navigate" class="btn">ประวัติการเบิก</button>
        </router-link>
      </div>
    </div>
  </template>
  
  <style scoped>
  .btn {
    @apply bg-blue-500 text-white py-2 px-4 rounded shadow-md hover:bg-blue-700;
  }
  </style>
  