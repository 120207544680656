<template>
  <div class="po-form">
    <div class="header bg-white shadow mb-4 p-4 flex justify-between items-center">
      <h2 class="text-2xl font-bold text-gray-800">ใบสั่งซื้อด่วน (Purchase Order : PO)</h2>
      <div class="status-steps flex space-x-4">
        <!-- <span class="status-step active">เปิดใบสั่งซื้อ</span>
        <span class="status-step">รอดำเนินการ</span>
        <span class="status-step">รอการอนุมัติ</span>
        <span class="status-step">สำเร็จ</span>
        <span class="status-step">รับเข้าคลัง</span> -->
        <button v-if="fromPOList && poDetail.status === 'อนุมัติ'" @click="openExportModal" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
      Export PDF
    </button>

 <!-- Export Modal -->
 <div v-if="showExportModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกหน่วยงาน</h3>
                <div class="mt-2">
                  <table class="min-w-full table-auto">
                    <thead class="bg-gray-100">
                      <tr>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รูปภาพ</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อหน่วยงาน</th>
                        <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลือก</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="department in departments" :key="department.group_id">
                        <td class="px-6 py-4 whitespace-nowrap">
                          <img :src="transformImageUrl(department.image_url)" alt="Department Image" class="h-10 w-10 rounded-full">
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          {{ department.group_name }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          <input type="radio" :value="department" v-model="selectedDepartment">
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="exportToPdf" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
              ยืนยัน
            </button>
            <button @click="closeExportModal" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>



    
      </div>
    </div>
    <div v-if="user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ'">
  <div v-if="!poDetail.status || poDetail.status === ''" class="watermark">
    ยังไม่อนุมัติ
  </div>
  <div v-if="poDetail.status === 'อนุมัติ'" class="watermark approved">
    อนุมัติแล้ว
  </div>
  <!-- Content of PRForm goes here -->
</div>
    <div v-if="poDetail.status === 'อนุมัติ'" class="watermark approved">อนุมัติ</div>
    <div v-if="poDetail.status === 'ไม่อนุมัติ'" class="watermark disapproved">
      ไม่อนุมัติ
  <br><br> <div class="watermark-reason">{{ poDetail.disapproveReason }}</div>
  
    </div>
    
    <div class="content bg-white shadow p-4">
      
      <div class="form-grid grid grid-cols-5 gap-4">
        
        <div>
          <label class="block text-gray-700">วันที่สร้าง</label>
          <input v-model="poDetail.creationDate" type="date" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณาเลือกวันที่"  :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase || isPurchase">
        </div>
        <div>
          <label class="block text-gray-700">ชื่อผู้สร้างใบขอซื้อ</label>
          <input v-model="poDetail.creatorName" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณาเลือก" readonly >
        </div>
        <div>
          <label class="block text-gray-700">เลขที่ใบสั่งซื้อ</label>
          <input v-model="poDetail.poNumber" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="แสดงอัตโนมัติ" readonly>
        </div>


        <!-- Checkbox สำหรับรถนอกหน่วยงาน -->
<div class="mt-4">
  <label class="inline-flex items-center">
    <input type="checkbox" v-model="isExternalVehicle" class="form-checkbox" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase || isPurchase">
    <span class="ml-2">รถนอกบริษัท</span>
  </label>
</div>

        <div>
  <label class="block text-gray-700">ทะเบียนรถ</label>
  <input v-model="poDetail.vehicleReg" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="คลิกเพื่อเลือกทะเบียนรถ" @click="showVehicleModal = true" readonly :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase || isPurchase">
</div>

        <!-- ช่องกรอกรหัสรถ -->
<div>
  <label class="block text-gray-700">รหัสรถ</label>
  <input v-model="poDetail.vehicleCode" :readonly="!isEditableVehicleCode" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="แสดงอัตโนมัติ" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
</div>
        <div>
          <label class="block text-gray-700">รุ่น</label>
          <input v-model="poDetail.model" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณากรอก" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <div>
          <label class="block text-gray-700">เวอร์ชั่นรถ</label>
          <select v-model="poDetail.vehicleVersion" class="form-select rounded-md shadow-sm mt-1 block w-full" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
            <option v-for="version in vehicleVersions" :key="version" :value="version">V{{ version }}</option>
          </select>
        </div>
        <div>
          <label class="block text-gray-700">ยี่ห้อ</label>
          <input v-model="poDetail.brand" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณากรอก" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <div>
          <label class="block text-gray-700">เลขไมล์</label>
          <input v-model="poDetail.mileage" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณากรอก" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <div>
          <label class="block text-gray-700">เลขคัทซี</label>
          <input v-model="poDetail.chassisNumber" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณากรอก" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <div v-if="poDetail.status === 'อนุมัติ'">
  <div>
    <label class="block text-gray-700">ชื่อผู้ดำเนินการ</label>
    <input v-model="poDetail.operatorName" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" readonly placeholder="ดึงจากผู้กดดำเนินการ" :disabled="isMaintenanceAdmin || fromPOList">
  </div>
  <div>
    <label class="block text-gray-700">ตำแหน่ง</label>
    <input v-model="poDetail.position" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" readonly placeholder="ดึงจากผู้กดดำเนินการ" :disabled="isMaintenanceAdmin || fromPOList">
  </div>
</div>
        <div>
  <label class="block text-gray-700">ชื่อผู้จำหน่าย</label>
  <input v-model="poDetail.supplierName" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณาเลือกคู่ค้า" @click="showSupplierModal = true" readonly :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
</div>
        <div>
          <label class="block text-gray-700">เลขประจำตัวผู้เสียภาษี</label>
          <input v-model="poDetail.taxId" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="แสดงอัตโนมัติ" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <div>
          <label class="block text-gray-700">ช่องทางติดต่อ</label>
          <input v-model="poDetail.contactChannel" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="แสดงอัตโนมัติ" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <!-- <div>
    <label class="block text-gray-700">สถานที่รับอะไหล่</label>
    <input
      v-model="selectedWarehouseName"
      type="text"
      class="form-input rounded-md shadow-sm mt-1 block w-full"
      :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase"
      placeholder="กรุณาเลือกสถานที่รับอะไหล่"
      @click="showWarehouseModal = true"
      readonly
    >
  </div> -->
        <div>
  <label class="block text-gray-700">จำนวนเครดิต</label>
  <input 
    v-model="poDetail.creditTerms" 
    type="text" 
    class="form-input rounded-md shadow-sm mt-1 block w-full" 
    :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase"
    placeholder="กรุณากรอกข้อมูล" 
    @input="filterCreditTerms"
    @keypress="preventNonNumeric"
  >
</div>
        <!-- <div>
          <label class="block text-gray-700">หักภาษี</label>
          <input v-model="poDetail.taxDeduct" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณากรอกข้อมูล">
        </div> -->

       

  <div>
          <label class="block text-gray-700">รายละเอียด</label>
          <input v-model="poDetail.details" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรุณากรอกข้อมูล" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>

        <div>
    <label class="block text-gray-700">ที่อยู่จัดส่ง</label>
    <input
      v-model="poDetail.shippingAddress"
      type="text"
      class="form-input rounded-md shadow-sm mt-1 block w-full long-input"
      :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase"
      placeholder="แสดงอัตโนมัติ"
      @click="showGroupModal = true"
      readonly
    >
  </div>




    <div></div> <div></div> <div></div> 
    
    
    
    
    
    
    <div>
      <label class="block text-gray-700 font-bold">แนบไฟล์แจ้งซ่อม</label>
      <input type="file" @change="event => handleFileUpload(event, 'repair')" class="form-input rounded-md shadow-sm mt-1 block w-full" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
    </div>
      <div v-if="repairFileDetails" class="mt-4">
        <label class="text-gray-700 font-bold">ไฟล์แจ้งซ่อมที่แนบ:</label>
        <ul class="list-disc pl-5">
          <li>
            <button @click="openFile(repairFileDetails.url)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2">
              <!-- {{ repairFileDetails.name }} --> ดูไฟล์
            </button>
          </li>
        </ul>
  
    </div>

      <label class="block text-gray-700 font-bold">แนบไฟล์ใบเสร็จ</label>
      <input type="file" @change="event => handleFileUpload(event, 'receipt')" class="form-input rounded-md shadow-sm mt-1 block w-full" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
      <div v-if="receiptFileDetails" class="mt-4">
        <h4 class="text-gray-700 font-bold">ไฟล์ใบเสร็จที่แนบ:</h4>
        <ul class="list-disc pl-5">
          <li>
            <button @click="openFile(receiptFileDetails.url)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2">
              <!-- {{ receiptFileDetails.name }}  --> ดูไฟล์
            </button>
          </li>
        </ul>
      </div>
      


      </div>
      
    </div>
    <div class="content bg-white shadow p-4 mt-4 flex justify-end" v-if="isMaintenanceAdmin || isCentralMaintenanceHead || this.user.position_lo === 'ผู้จัดการฝ่ายซ่อมบำรุง' && !fromPOList">
      <!-- ปุ่มบันทึก -->
      <button 
        @click="saveForm" 
        :disabled="loading"  
        class="bg-blue-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" 
      >
        <span v-if="loading">กำลังบันทึก...</span>
        <span v-else>บันทึก</span>
      </button>
    </div>

    <div class="content bg-white shadow p-4 mt-4 flex justify-end" v-if="isPurchaseManager && poDetail.status !== 'อนุมัติ' && poDetail.status !== 'ไม่อนุมัติ'">
      <!-- ปุ่มอนุมัติ -->
      <button 
        @click="approveOrder" 
        :disabled="loading"  
        class="bg-green-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" 
      >
        <span v-if="loading">กำลังอนุมัติ...</span>
        <span v-else>อนุมัติ</span>
      </button>


     
    </div>
    <div v-if="isPurchaseManager && poDetail.status !== 'อนุมัติ' && poDetail.status !== 'ไม่อนุมัติ'" class="flex flex-col mt-4">
      <!-- กล่องข้อความสำหรับเหตุผลการไม่อนุมัติ -->
  <textarea 
    v-model="disapproveReason" 
    placeholder="กรุณาใส่เหตุผลสำหรับการไม่อนุมัติ" 
    class="form-textarea mt-1 block w-full ml-4"
  ></textarea>
  
  <!-- ปุ่มไม่อนุมัติ -->
  <button 
    @click="disapproveOrder" 
    :disabled="loading" 
    class="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 ml-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150" 
  >
    <span v-if="loading">กำลังไม่อนุมัติ...</span>
    <span v-else>ไม่อนุมัติ</span>
  </button>
    </div>


 <!-- Modal for selecting group address -->
 <!-- <div v-if="showGroupModal" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full h-4/5">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกที่อยู่จัดส่ง</h3>
              <div class="mt-2">
                <input v-model="searchGroupQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหากลุ่ม">
                <ul class="mt-4">
                  <li v-for="group in paginatedGroups" :key="group.group_id" @click="selectGroup(group)" class="cursor-pointer p-2 hover:bg-gray-200">{{ group.group_name }}</li>
                </ul>
                <div class="flex justify-between mt-4">
                  <button @click="prevGroupPage" :disabled="currentGroupPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                  <span>หน้าที่ {{ currentGroupPage }} จาก {{ totalGroupPages }}</span>
                  <button @click="nextGroupPage" :disabled="currentGroupPage === totalGroupPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="showGroupModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            ปิด
          </button>
        </div>
      </div>
    </div>
  </div> -->
  <div v-if="showGroupModal" class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full h-4/5">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกที่อยู่จัดส่ง</h3>
                    
                    <!-- Checkbox to toggle between groups and garages -->
                    <div>
                      <label class="inline-flex items-center">
                        <input type="checkbox" v-model="showGarages" class="form-checkbox">
                        <span class="ml-2">แสดงอู่แทนการแสดงกลุ่ม</span>
                      </label>
                    </div>
                    
                    <div class="mt-2">
                      <input v-model="searchQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหา">
                      <ul class="mt-4">
                        <li v-for="item in paginatedItems" :key="item.id || item.group_id" @click="selectItem(item)" class="cursor-pointer p-2 hover:bg-gray-200">
                          {{ item.company_name || item.group_name }}
                        </li>
                      </ul>
                      <div class="flex justify-between mt-4">
                        <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                        <span>หน้าที่ {{ currentPage }} จาก {{ totalPages }}</span>
                        <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
                      </div>
                    </div>
                    
                    <div v-if="showGarages" class="mt-4">
                      <h4 class="text-lg leading-6 font-medium text-gray-900">ข้อมูลอู่</h4>
                      <table class="min-w-full table-auto mt-4">
                        <thead class="bg-gray-100">
                          <tr>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ชื่อบริษัท</th>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ที่อยู่</th>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">โทรศัพท์</th>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ผู้ติดต่อ</th>
                            <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">อีเมล์</th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr v-for="garage in paginatedItems" :key="garage.id" @click="selectItem(garage)">
                            <td class="px-6 py-4 text-sm text-black-500">{{ garage.company_name }}</td>
                            <td class="px-6 py-4 text-sm text-black-500">{{ garage.address }}</td>
                            <td class="px-6 py-4 text-sm text-black-500">{{ garage.store_phone }}</td>
                            <td class="px-6 py-4 text-sm text-black-500">{{ garage.contact_name }}</td>
                            <td class="px-6 py-4 text-sm text-black-500">{{ garage.email }}</td>
                          </tr>
                          <tr v-if="paginatedItems.length === 0">
                            <td class="px-6 py-4 text-sm text-gray-500 text-center" colspan="5">ไม่พบข้อมูล</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button @click="showGroupModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  ปิด
                </button>
              </div>
            </div>
          </div>
        </div>


    <!-- Modal สำหรับเลือกสถานที่รับอะไหล่ -->
    <div v-if="showWarehouseModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full h-4/5">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  เลือกสถานที่รับอะไหล่
                </h3>
                <div class="mt-2">
                  <input v-model="searchWarehouseQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาสถานที่รับอะไหล่">
                  <ul class="mt-4">
                    <li v-for="warehouse in paginatedWarehouses" :key="warehouse.warehouse_id" @click="selectWarehouse(warehouse)" class="cursor-pointer p-2 hover:bg-gray-200">
                      <div class="flex items-center">
                        <img :src="convertImageUrl(warehouse.image_url)" alt="Warehouse Image" class="inline-block h-10 w-10 rounded-full mr-3">
                        <div>
                          <div class="text-sm font-medium text-gray-900">{{ warehouse.name }}</div>
                          <div class="text-sm text-gray-500">{{ warehouse.group_name }}</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="flex justify-between mt-4">
                    <button @click="prevWarehousePage" :disabled="currentWarehousePage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                    <span>หน้าที่ {{ currentWarehousePage }} จาก {{ totalWarehousePages }}</span>
                    <button @click="nextWarehousePage" :disabled="currentWarehousePage === totalWarehousePages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showWarehouseModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>



<!-- Modal สำหรับเลือกทะเบียนรถ -->
<div v-if="showVehicleModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full h-4/5">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกทะเบียนรถ</h3>
            <div class="mt-2">
              <input v-model="searchVehicleQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาทะเบียนรถ">
              <ul class="mt-4">
                <li v-for="vehicle in paginatedVehicles" :key="vehicle.VehicleID" @click="selectVehicle(vehicle)" class="cursor-pointer p-2 hover:bg-gray-200">{{ vehicle.LicensePlate }}</li>
              </ul>
              <div class="flex justify-between mt-4">
                <button @click="prevVehiclePage" :disabled="currentVehiclePage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                <span>หน้าที่ {{ currentVehiclePage }} จาก {{ totalVehiclePages }}</span>
                <button @click="nextVehiclePage" :disabled="currentVehiclePage === totalVehiclePages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
              </div>
              <div class="mt-4" v-if="isExternalVehicle">
                <label class="block text-gray-700">กรอกทะเบียนรถใหม่</label>
                <input v-model="newVehicleReg" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรอกทะเบียนรถใหม่">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="confirmNewVehicleReg" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          ยืนยัน
        </button>
        <button @click="showVehicleModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          ปิด
        </button>
      </div>
    </div>
  </div>
</div>


<!-- <div>
  <label class="block text-gray-700">ทะเบียนรถ</label>
  <input v-model="poDetail.vehicleReg" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="คลิกเพื่อเลือกทะเบียนรถ" @click="showVehicleModal = true" readonly>
</div>


<div>
  <label class="block text-gray-700">รหัสรถ</label>
  <input v-model="poDetail.vehicleCode" :readonly="!isEditableVehicleCode" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="แสดงอัตโนมัติ">
</div> -->


<!-- ส่วนเพิ่มรายการสินค้า -->
<div class="bg-white shadow mb-4 p-4">
  <h3 class="text-xl font-bold text-gray-700">รายการสินค้า</h3>
  <div class="flex justify-end space-x-2 mb-4" v-if="form.items.length < 10">
    <button @click="showProductModal = true" class="bg-green-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"     :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
      + เพิ่มรายการ
    </button>
  </div>
  <div class="overflow-x-auto">
    <table class="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รหัสสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รายการสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทสินค้า</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวน</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">หน่วยนับ</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ราคาต่อหน่วย</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวนเงิน</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">แนบรูปภาพ</th>
          <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ลบ</th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(item, index) in form.items" :key="index">
          <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium text-black-900">
            <input v-model="item.productCode" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="รหัสสินค้า" readonly>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
            <input v-model="item.productName" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full lg:w-32" placeholder="รายการสินค้า" readonly>
          </td>
          <td class="px-4 py-4 whitespace-no-wrap text-sm text-black-500">
            <input v-model="item.productType" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ประเภทสินค้า" readonly>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
  <input 
    v-model="item.quantity" 
    @input="validateQuantity(item)" 
    @keydown="preventDecimal($event)"
    type="number" 
    class="form-input rounded-md shadow-sm mt-1 block w-full bg-red-50" 
        :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase"
    placeholder="จำนวน" 
    min="1">
</td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
            <input v-model="item.unit" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full lg:w-8" placeholder="หน่วยนับ" readonly>
          </td>
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
  <input 
    v-model="item.unitPrice" 
    @input="event => filterNumericInput(event, item, 'unitPrice')" 
    @keypress="preventNonNumeric"
    type="number" 
    class="form-input rounded-md shadow-sm mt-1 block w-full bg-red-50" 
        :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase"
    placeholder="ราคาต่อหน่วย" 
    min="0">
</td>
<td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
  <input 
    v-model="item.totalPrice" 
    type="number" 
    class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" 
    placeholder="จำนวนเงิน" 
    readonly>
</td>
<td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
        <input type="file" @change="handleItemFileUpload($event, index)" class="form-input rounded-md shadow-sm mt-1 block w-full"     :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        <div v-if="item.itemFileUrl">
          <a :href="transformAttachmentUrl(item.itemFileUrl)" target="_blank" class="text-blue-500 hover:underline">ดูไฟล์</a>
        </div>
      </td>
      <td class="px-6 py-4 whitespace-no-wrap text-sm font-bold" >
        <button @click="removeItem(index)" class="text-red-600 hover:text-red-900"     :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">ลบ</button>
      </td>
        </tr>

        <tr v-if="form.items.length === 0">
          <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="9">ไม่พบข้อมูล</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<!-- Modal สำหรับเลือกผู้จำหน่าย -->
<div v-if="showSupplierModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full h-4/5">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกผู้จำหน่าย</h3>
            <div class="mt-2">
              <input v-model="searchSupplierQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาผู้จำหน่าย">
              <ul class="mt-4">
                <li v-for="supplier in paginatedSuppliers" :key="supplier.supplier_id" @click="selectSupplier(supplier)" class="cursor-pointer p-2 hover:bg-gray-200">{{ supplier.company_name }}</li>
              </ul>
              <div class="flex justify-between mt-4">
                <button @click="prevSupplierPage" :disabled="currentSupplierPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                <span>หน้าที่ {{ currentSupplierPage }} จาก {{ totalSupplierPages }}</span>
                <button @click="nextSupplierPage" :disabled="currentSupplierPage === totalSupplierPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="showSupplierModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          ปิด
        </button>
      </div>
    </div>
  </div>
</div>


<div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2">ประเภทราคา:</label>
    <select v-model="form.priceType" class="form-select mt-1 block w-full" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
      <option value="0">ไม่มี VAT</option>
      <option value="1">รวม VAT</option>
      <option value="2">แยก VAT</option>
    </select>
  </div>
  <div class="bg-white shadow mb-4 p-4">
      <div class="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div class="total-amount">
          <label for="totalAmount" class="block text-lg font-bold text-gray-700">รวมเงินทั้งสิ้น</label>
          <input v-model="form.totalAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50 text-lg" id="totalAmount" readonly>
        </div>
        <div class="discount-percent">
          <label for="discountPercent" class="block text-lg font-bold text-gray-700">ส่วนลด (%)</label>
          <input v-model="form.discountPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50 text-lg" id="discountPercent" @input="calculateAmounts" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <div class="discount-amount">
          <label for="discountAmount" class="block text-lg font-bold text-gray-700" >ส่วนลดเป็นเงิน</label>
  <input 
    v-model="form.discountAmount" 
    @input="calculateDiscountPercent" 
    type="number" 
    class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50 text-lg" 
    :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase"
    id="discountAmount" 
    :max="form.totalAmount" 
    step="0.01"
  >
      </div>
        <div class="amount-after-discount">
          <label for="amountAfterDiscount" class="block text-lg font-bold text-gray-700">ราคาหลังหักส่วนลด</label>
          <input v-model="form.amountAfterDiscount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-orange-50 text-lg" id="amountAfterDiscount" readonly :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <div class="vat-percent">
          <label for="vatPercent" class="block text-lg font-bold text-gray-700">ภาษีมูลค่าเพิ่ม (%)</label>
          <input v-model="form.vatPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full text-lg" id="vatPercent" @input="calculateAmounts" :disabled="isMaintenanceAdmin && fromPOList || isPurchaseManager || isPurchase">
        </div>
        <div class="vat-amount">
          <label for="vatAmount" class="block text-lg font-bold text-gray-700">คิดเป็นจำนวนเงิน</label>
          <input v-model="form.vatAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full text-lg" id="vatAmount" readonly >
        </div>
        <div class="grand-total">
          <label for="grandTotal" class="block text-lg font-bold text-gray-700">เป็นเงินทั้งสิ้น</label>
          <input v-model="form.grandTotal" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50 text-lg" id="grandTotal" readonly>
        </div>
        
  </div>
  </div>

<!-- Modal สำหรับเลือกสินค้า -->
<div v-if="showProductModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full w-full h-screen sm:h-4/5 max-h-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              เลือกสินค้า
            </h3>
            <div class="mt-4">
              <div class="flex flex-row space-x-4 mb-4">
                <input 
                  v-model="productSearchQuery" 
                  @input="fetchProducts" 
                  type="text" 
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                  placeholder="ค้นหาจากชื่อสินค้า">
                <input 
                  v-model="productTypeSearchQuery" 
                  @input="fetchProducts" 
                  type="text" 
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                  placeholder="ค้นหาจากประเภทสินค้า">
                <input 
                  v-model="productBrandSearchQuery" 
                  @input="fetchProducts" 
                  type="text" 
                  class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
                  placeholder="ค้นหาจากยี่ห้อ">
              </div>
              <table class="min-w-full divide-y divide-gray-200 mt-4">
                <thead>
                  <tr>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รหัสสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">หน่วยนับ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ยี่ห้อ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทพาหนะ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทอะไหล่</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ราคา/หน่วย</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลือก</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="product in paginatedFilteredProducts" :key="product.product_id">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium text-gray-900">{{ product.product_name }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_code }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.unit_name }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.brand }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.vehicle_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.part_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.price_per_unit }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium">
                      <button 
                        @click="selectProduct(product)" 
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        เลือก
                      </button>
                    </td>
                  </tr>
                  <tr v-if="paginatedFilteredProducts.length === 0">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="8">ไม่พบข้อมูล</td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-4 flex justify-between">
                <button @click="prevProductPage" :disabled="currentProductPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                <span>หน้าที่ {{ currentProductPage }} จาก {{ totalProductPages }}</span>
                <button @click="nextProductPage" :disabled="currentProductPage === totalProductPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="showProductModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          ปิด
        </button>
      </div>
    </div>
  </div>
</div>


</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import socket from '../../services/socket'; 
export default {

  data() {
  return {
    showExportModal: false,
    vehiclesPtt: [],
    departments: [],
    poDetail: {
      id: null, // Ensure this is correctly set
      creationDate: '',
      creatorName: '',
      poNumber: '',
      vehicleReg: '',
      vehicleCode: '',
      model: '',
      vehicleVersion: '',
      brand: '',
      mileage: '',
      chassisNumber: '',
      operatorName: '',
      position: '',
      supplierName: '',
      taxId: '',
      contactChannel: '',
      details: '',
      creditTerms: '',
      taxDeduct: '',
      shippingAddress: '',
      receivingWarehouse: '',
      status: '', // Ensure this field is present
      disapproveReason: '', // เพิ่มฟิลด์เหตุผลการไม่อนุมัติ
      items: []
    },
    form: {
      items: [],
      totalAmount: 0,
      discountPercent: 0,
      discountAmount: 0,
      amountAfterDiscount: 0,
      vatPercent: 0,
      vatAmount: 0,
      grandTotal: 0,
      receivingWarehouse: '',
      priceType: 0,
      repairFiles: [], // สำหรับไฟล์แจ้งซ่อม
      receiptFiles: [] // สำหรับไฟล์ใบเสร็จ
    },
    vehicleVersions: Array.from({ length: 15 }, (_, i) => `V${i + 1}`),
    showWarehouseModal: false,
    searchWarehouseQuery: '',
    warehouses: [],
    currentWarehousePage: 1,
    itemsPerPage: 10,
    user: {
        login_id: null, // Ensure this is correctly set
        fullName: '',
        group_id: '',
        position_lo: ''
      },
    suppliers: [],
    selectedSupplier: null,
    selectedSupplierTaxId: '',
    selectedWarehouse: null,
    selectedWarehouseName: '',

    products: [], // ตรวจสอบค่าเริ่มต้น
    showProductModal: false,
    productSearchQuery: '',
    productTypeSearchQuery: '',
    productBrandSearchQuery: '',

    currentProductPage: 1,
    files: [], // สำหรับจัดเก็บไฟล์ที่อัพโหลด

    showVehicleModal: false,
      searchVehicleQuery: '',
      currentVehiclePage: 1,
      isExternalVehicle: false,

      vehicles: [],
      paginatedVehicles: [],
      totalVehiclePages: 1,

      showSupplierModal: false,
      searchSupplierQuery: '',
      currentSupplierPage: 1,
  
      paginatedSuppliers: [],
      totalSupplierPages: 1,

      garages: [],
      showGarages: false,
      showGroupModal: false,
    userGroups: [],
    selectedGroupName: '',
    currentGroupPage: 1,
    searchGroupQuery: '',
    newVehicleReg: '', // เพิ่มฟิลด์สำหรับกรอกทะเบียนรถใหม่
    isEditableVehicleCode: false, // เพิ่มฟิลด์สำหรับการตรวจสอบการแก้ไขรหัสรถ
    paginatedGroups: [],
    totalGroupPages: 1,
    readOnly: false, // เพิ่มฟิลด์ readOnly
    loading: false, // สถานะ loading
    isMaintenanceAdmin: false,
    isCentralMaintenanceHead: false,
    isPurchaseManager: false, // เพิ่มตัวแปรนี้
    fromPOList: false, // เพิ่มตัวแปรนี้
    poData: {},
    orderStatus: '',
    disapproveReason: '',



      searchQuery: '',
      currentPage: 1,
    

      selectedItem: null,

  };
},
  computed: {
    repairFileDetails() {
    if (this.form.repairFile) {
      return { url: URL.createObjectURL(this.form.repairFile), name: this.form.repairFile.name };
    } else if (this.poDetail && this.poDetail.repairFiles && this.poDetail.repairFiles.length > 0) {
      const encodedFileName = this.extractFileName(this.poDetail.repairFiles[0]);
      return { url: this.transformAttachmentUrl(this.poDetail.repairFiles[0]), name: this.decodeFileName(encodedFileName) };
    }
    return null;
  },
  receiptFileDetails() {
    if (this.form.receiptFile) {
      return { url: URL.createObjectURL(this.form.receiptFile), name: this.form.receiptFile.name };
    } else if (this.poDetail && this.poDetail.receiptFiles && this.poDetail.receiptFiles.length > 0) {
      const encodedFileName = this.extractFileName(this.poDetail.receiptFiles[0]);
      return { url: this.transformAttachmentUrl(this.poDetail.receiptFiles[0]), name: this.decodeFileName(encodedFileName) };
    }
    return null;
  },
  //   repairFileDetails() {
  //   if (this.form.repairFile) {
  //     return { url: URL.createObjectURL(this.form.repairFile), name: this.form.repairFile.name };
  //   } else if (this.poDetail && this.poDetail.repairFiles && this.poDetail.repairFiles.length > 0) {
  //     return { url: this.transformAttachmentUrl(this.poDetail.repairFiles[0]), name: this.extractFileName(this.poDetail.repairFiles[0]) };
  //   }
  //   return null;
  // },
  // receiptFileDetails() {
  //   if (this.form.receiptFile) {
  //     return { url: URL.createObjectURL(this.form.receiptFile), name: this.form.receiptFile.name };
  //   } else if (this.poDetail && this.poDetail.receiptFiles && this.poDetail.receiptFiles.length > 0) {
  //     return { url: this.transformAttachmentUrl(this.poDetail.receiptFiles[0]), name: this.extractFileName(this.poDetail.receiptFiles[0]) };
  //   }
  //   return null;
  // },
    filteredProducts() {
 
    return this.products.filter(product =>
      product.product_name.toLowerCase().includes(this.productSearchQuery.toLowerCase()) &&
      product.product_type.toLowerCase().includes(this.productTypeSearchQuery.toLowerCase()) &&
      product.brand.toLowerCase().includes(this.productBrandSearchQuery.toLowerCase())
    );
    
  },
  filteredVehicles() {
  const table = this.isExternalVehicle ? this.vehiclesPtt : this.vehicles;

  // ตรวจสอบว่า table มีข้อมูลหรือไม่ก่อน filter
  if (table.length === 0) {
    return []; 
  }

  if (!this.searchVehicleQuery.trim()) {
    return table;
  }

  const searchTerm = this.searchVehicleQuery.toLowerCase();
  return table.filter(vehicle => 
    vehicle.LicensePlate && // ตรวจสอบว่า vehicle.LicensePlate มีค่า
    vehicle.LicensePlate.toLowerCase().includes(searchTerm)
  );
},
  // filteredVehicles() {
  //     // if (!this.searchVehicleQuery.trim()) {
  //     //   return this.vehicles;
  //     // }
  //     // const searchTerm = this.searchVehicleQuery.toLowerCase();
  //     // return this.vehicles.filter(vehicle => vehicle.LicensePlate.toLowerCase().includes(searchTerm));
     
  //   },

  paginatedFilteredProducts() {
    const start = (this.currentProductPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredProducts.slice(start, end);
  },
  totalProductPages() {
    return Math.ceil(this.filteredProducts.length / this.itemsPerPage);
  
  },
    filteredWarehouses() {
     
      return this.warehouses.filter(warehouse => 
        warehouse.name.toLowerCase().includes(this.searchWarehouseQuery.toLowerCase())
      );
    },
    paginatedWarehouses() {
      const start = (this.currentWarehousePage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredWarehouses.slice(start, end);
    },
    totalWarehousePages() {
      return Math.ceil(this.filteredWarehouses.length / this.itemsPerPage);
    },
    paginatedItems() {
      const items = this.showGarages ? this.filteredGarages : this.filteredUserGroups;
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return items.slice(start, end);
    },
    totalPages() {
      const items = this.showGarages ? this.filteredGarages : this.filteredUserGroups;
      return Math.ceil(items.length / this.itemsPerPage);
    },
    filteredUserGroups() {
      return this.userGroups.filter(group => group.group_name.includes(this.searchQuery));
    },
    filteredGarages() {
      return this.garages.filter(garage => garage.company_name.includes(this.searchQuery));
    }
  },
  mounted() {
    // this.generatePONumber();
    this.newVehicleReg = '';
    this.fetchDepartments();
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    openExportModal() {
      this.showExportModal = true;
    },
    closeExportModal() {
      this.showExportModal = false;
    },
    openExportModal2() {
      this.showExportModal2 = true;
    },
    closeExportModal2() {
      this.showExportModal2 = false;
    },
    transformImageUrl(url) {
      return url ? url.replace('https://brknew.synology.me/web/imageProducts', 'https://brkmeeting-room.online') : '';
    },
    async fetchDepartments() {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.get('https://brk-group.org/api/user-groups', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.departments = response.data;
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    },
    async exportToPdf() {
      if (!this.selectedDepartment) {
        Swal.fire('Error', 'กรุณาเลือกหน่วยงาน', 'error');
        return;
      }

      try {
        const token = localStorage.getItem('userToken');
        const imageUrl = this.transformImageUrl(this.selectedDepartment.image_url);
        const response = await axios.post(`https://brk-group.org/generate-urgentpo/${this.poDetail.id}`, {
          imageUrl: imageUrl,
          groupId: this.selectedDepartment.group_id
        }, {
          headers: { 'Authorization': `Bearer ${token}` },
          responseType: 'blob' // Important to handle the binary response
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank'); // Open the PDF in a new tab

      } catch (error) {
        console.error('Error exporting PDF:', error);
        Swal.fire('Error', 'Unable to export PDF', 'error');
      } finally {
        this.closeExportModal();
      }
    },

    async disapproveOrder() {
    if (!this.disapproveReason.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Warning',
        text: 'กรุณาใส่เหตุผลสำหรับการไม่อนุมัติ'
      });
      return;
    }

    if (!this.poDetail.id) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'ไม่สามารถระบุใบสั่งซื้อได้'
      });
      return;
    }

    if (this.loading) return;
    this.loading = true;

    try {
      console.log(`Disapproving order with ID: ${this.poDetail.id}`);
      console.log(`Disapprove Reason: ${this.disapproveReason}`);
      console.log(`Disapprove ID: ${this.user.login_id}`);

      if (!this.user.login_id) {
        console.error('User ID is undefined');
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'ไม่สามารถระบุ ID ของผู้ใช้งานได้'
        });
        this.loading = false;
        return;
      }

      const response = await axios.put(`https://brk-group.org/api/urgent_purchase_orders/${this.poDetail.id}/disapprove`, {
        disapprove_id: this.user.login_id,
        reason: this.disapproveReason
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('userToken')}`
        }
      });

      console.log('Disapprove response:', response.data);

      Swal.fire({
        icon: 'success',
        title: 'สำเร็จ',
        text: 'ใบสั่งซื้อได้ถูกเปลี่ยนแปลงแล้ว'
      });

      this.$router.push({ name: 'POList' });
    } catch (error) {
      console.error('Error disapproving order:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'เกิดข้อผิดพลาดในการไม่อนุมัติใบสั่งซื้อ กรุณาลองใหม่อีกครั้ง'
      });
    } finally {
      this.loading = false;
    }
  },

    // async fetchWarehouses() {
    //   const token = localStorage.getItem('userToken');
    //   if (!token) {
    //     this.$router.push({ name: 'Login' });
    //     return;
    //   }
    //   if (!this.user || !this.user.group_id) {
    //     console.error('User data or group_id is missing');
    //     return;
    //   }
    //   try {
    //     const response = await axios.get('https://brk-group.org/api/warehousespr', {
    //       headers: { 'Authorization': `Bearer ${token}` },
    //       params: { group_id: this.user.group_id }
    //     });
    //     this.warehouses = response.data.map(warehouse => ({
    //       ...warehouse,
    //       image_url: warehouse.image_url.replace('https://brknew.synology.me/web/imageProducts/Warehouse/', 'https://brkmeeting-room.online/Warehouse/')
    //     }));
    //     console.log("Fetched warehouses:", this.warehouses);
    //   } catch (error) {
    //     console.error('Error fetching warehouses:', error);
    //   }
    // },
    async fetchWarehouses() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }
    try {
    
      const response = await axios.get('https://brk-group.org/api/warehousespr', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.warehouses = response.data.map(warehouse => ({
        ...warehouse,
        image_url: warehouse.image_url.replace('https://brknew.synology.me/web/imageProducts/Warehouse/', 'https://brkmeeting-room.online/Warehouse/')
      }));
     
    } catch (error) {
      console.error('Error fetching warehouses:', error);
    }
  },


    async fetchUserGroups() {
    try {
      const response = await axios.get('https://brk-group.org/api/user_groups');
      this.userGroups = response.data;
      this.updatePaginatedGroups();
    } catch (error) {
      console.error('Error fetching user groups:', error);
    }
  },
  async fetchGarages() {
      try {
        const response = await axios.get('https://brk-group.org/api/garages');
        this.garages = response.data;
      } catch (error) {
        console.error('Error fetching garages:', error);
      }
    },
  selectGroup(group) {
    this.poDetail.shippingAddress = group.group_address; // Store the address in poDetail
    this.selectedGroupName = group.group_name; // Store the group name for display
    this.showGroupModal = false;
  },
  prevGroupPage() {
    if (this.currentGroupPage > 1) {
      this.currentGroupPage--;
      this.updatePaginatedGroups();
    }
  },
  nextGroupPage() {
    if (this.currentGroupPage < this.totalGroupPages) {
      this.currentGroupPage++;
      this.updatePaginatedGroups();
    }
  },
  updatePaginatedGroups() {
    const start = (this.currentGroupPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.paginatedGroups = this.userGroups.slice(start, end);
    this.totalGroupPages = Math.ceil(this.userGroups.length / this.itemsPerPage);
  },
    async fetchSuppliers() {
      try {
        const response = await axios.get('https://brk-group.org/api/suppliers', {
          params: { search: this.searchSupplierQuery }
        });
        this.suppliers = response.data;
        this.updatePaginatedSuppliers();
      } catch (error) {
        console.error('Error fetching suppliers:', error);
      }
    },
    selectSupplier(supplier) {
      this.poDetail.supplierName = supplier.company_name;
      this.poDetail.taxId = supplier.tax_id;
      this.poDetail.contactChannel = supplier.phone;
      this.showSupplierModal = false;
    },
    prevSupplierPage() {
      if (this.currentSupplierPage > 1) {
        this.currentSupplierPage--;
        this.updatePaginatedSuppliers();
      }
    },
    nextSupplierPage() {
      if (this.currentSupplierPage < this.totalSupplierPages) {
        this.currentSupplierPage++;
        this.updatePaginatedSuppliers();
      }
    },
    updatePaginatedSuppliers() {
      const start = (this.currentSupplierPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedSuppliers = this.suppliers.slice(start, end);
      this.totalSupplierPages = Math.ceil(this.suppliers.length / this.itemsPerPage);
    },
    // async fetchVehicles() {
    //   try {
    //     const response = await axios.get('https://brk-group.org/api/vehiclesPO', {
    //       params: { search: this.searchVehicleQuery }
    //     });
    //     this.vehicles = response.data;
    //     this.updatePaginatedVehicles();
    //   } catch (error) {
    //     console.error('Error fetching vehicles:', error);
    //   }
    // },
    async fetchVehicles() {
  try {
    const isExternal = this.isExternalVehicle ? '1' : '0'; // สร้างตัวแปร isExternal

    console.log('Sending isExternal:', isExternal); // เพิ่ม console.log

    const response = await axios.get('https://brk-group.org/api/vehiclesPOO', {
      params: {
        search: this.searchVehicleQuery,
        isExternal: isExternal // ส่งค่า isExternal ไปด้วย
      }
    });
    this.vehicles = response.data;
    this.updatePaginatedVehicles();
  } catch (error) {
    console.error('Error fetching vehicles:', error);
  }
},
    selectVehicle(vehicle) {
      if (!this.isExternalVehicle) {
    this.poDetail.VehicleID = vehicle.VehicleID;
    this.poDetail.vehicleReg = vehicle.LicensePlate;
    this.isExternalVehicle = false; // ไม่เลือก checkbox รถนอกหน่วยงาน
    this.showVehicleModal = false;
    this.isEditableVehicleCode = false; // ปิดการแก้ไขรหัสรถเมื่อเลือกจากรายการ
    this.loadVehicleDetails(vehicle.VehicleID);
      }
    else {
      this.poDetail.VehicleID = vehicle.VehicleID;
    this.poDetail.vehicleReg = vehicle.LicensePlate;
    this.isExternalVehicle = true; // ไม่เลือก checkbox รถนอกหน่วยงาน
    this.showVehicleModal = false;
    this.isEditableVehicleCode = false; // ปิดการแก้ไขรหัสรถเมื่อเลือกจากรายการ
    this.loadVehicleDetails(vehicle.VehicleID);
    }
  },
  

     // ฟังก์ชันสำหรับโหลดรายละเอียดของยานพาหนะ
     async loadVehicleDetails(vehicleID) {
    try {
      const response = await axios.get(`https://brk-group.org/api/vehicles/${vehicleID}`);
      const vehicleData = response.data;
      this.poDetail.vehicleReg = vehicleData.LicensePlate;
      this.poDetail.vehicleCode = vehicleData.VehicleNumber;
      this.poDetail.model = vehicleData.VehicleModel;
      this.poDetail.vehicleVersion = vehicleData.VehicleVersion;
      this.poDetail.chassisNumber = vehicleData.VehicleChassisNumber;
      this.poDetail.mileage = vehicleData.VehicleMileage;
      this.poDetail.brand = vehicleData.Brand;
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    }
  },
  // confirmNewVehicleReg() {
  //   if (this.isExternalVehicle && this.newVehicleReg.trim() !== '') {
  //     this.poDetail.vehicleReg = this.newVehicleReg.trim();
  //     this.poDetail.vehicleCode = '';
  //     this.poDetail.model = '';
  //     this.poDetail.vehicleVersion = '';
  //     this.poDetail.brand = '';
  //     this.poDetail.mileage = '';
  //     this.poDetail.chassisNumber = '';
  //     this.isEditableVehicleCode = true; // เปิดการแก้ไขรหัสรถเมื่อกรอกทะเบียนรถใหม่
  //     this.showVehicleModal = false;
  //   } else if (!this.isExternalVehicle) {
  //     this.showVehicleModal = false;
  //   } else {
  //     alert('กรุณากรอกทะเบียนรถ');
  //   }
  // },
  async confirmNewVehicleReg() {
  if (this.isExternalVehicle && this.newVehicleReg.trim() !== '') {
    try {
      // ตรวจสอบทะเบียนซ้ำใน backend
      const response = await axios.get('https://brk-group.org/api/check-vehicle-reg', {
        params: {
          vehicleReg: this.newVehicleReg.trim(),
          isExternalVehicle: this.isExternalVehicle ? '1' : '0'
        }
      });

      if (response.data.exists) {
        // แสดงข้อความแจ้งเตือนว่ามีทะเบียนรถซ้ำ
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ทะเบียนรถนี้มีอยู่แล้วในระบบ',
        });
      } else {
        // ไม่มีทะเบียนซ้ำ ดำเนินการต่อ
        this.poDetail.vehicleReg = this.newVehicleReg.trim();
        // ... (เคลียร์ค่าอื่นๆ ใน poDetail)

        this.isEditableVehicleCode = true;
        this.showVehicleModal = false;
      }
    } catch (error) {
      console.error('Error checking vehicle registration:', error);
      // แสดงข้อความแจ้งเตือนเมื่อเกิดข้อผิดพลาด
    }
  } else if (!this.isExternalVehicle) {
    this.showVehicleModal = false; // ปิด modal ถ้าไม่ใช่รถนอกหน่วยงาน
  } else {
    alert('กรุณากรอกทะเบียนรถ');
  }
},
    prevVehiclePage() {
      if (this.currentVehiclePage > 1) {
        this.currentVehiclePage--;
        this.updatePaginatedVehicles();
      }
    },
    nextVehiclePage() {
      if (this.currentVehiclePage < this.totalVehiclePages) {
        this.currentVehiclePage++;
        this.updatePaginatedVehicles();
      }
    },
    updatePaginatedVehicles() {
      const start = (this.currentVehiclePage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      this.paginatedVehicles = this.vehicles.slice(start, end);
      this.totalVehiclePages = Math.ceil(this.vehicles.length / this.itemsPerPage);
    },
    async generatePurchaseRequestNumber() {
      try {
        const response = await axios.get('https://brk-group.org/api/urgent_purchase_orders/generate_po_number', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        this.poDetail.poNumber = response.data.poNumber;
      } catch (error) {
        console.error('Error generating PO number:', error);
        // แสดงข้อความแจ้งเตือนกรณีเกิดข้อผิดพลาด
      }
    },
    selectItem(item) {
      this.selectedItem = item;
      this.poDetail.shippingAddress = item.address || item.group_address;
      this.showGroupModal = false;
    },
    async saveForm() {
    if (this.loading) return;

    if (!this.validateForm()) {
      const errorFields = Object.values(this.errors).join('\n');
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: `กรุณากรอกข้อมูลให้ครบถ้วน:\n${errorFields}`,
      });
      return;
    }

    this.loading = true;
    const token = localStorage.getItem('userToken');

    if (this.form.items.length === 0) {
      this.loading = false;
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณาเพิ่มรายการสินค้าอย่างน้อยหนึ่งรายการก่อนบันทึก',
      });
      return;
    }

    try {
      const formData = new FormData();
      for (const key in this.poDetail) {
        if (Object.prototype.hasOwnProperty.call(this.poDetail, key) && key !== 'items' && key !== 'repairFiles' && key !== 'receiptFiles') {
          formData.append(key, this.poDetail[key]);
        }
      }

      formData.append('priceType', this.form.priceType);
      formData.append('totalAmount', this.form.totalAmount);
      formData.append('discountPercent', this.form.discountPercent);
      formData.append('discountAmount', this.form.discountAmount);
      formData.append('amountAfterDiscount', this.form.amountAfterDiscount);
      formData.append('vatPercent', this.form.vatPercent);
      formData.append('vatAmount', this.form.vatAmount);
      formData.append('grandTotal', this.form.grandTotal);
      formData.append('items', JSON.stringify(this.form.items));

      this.form.items.forEach((item, index) => {
        if (item.file) {
          formData.append(`items[${index}][file]`, item.file);
        }
      });

      this.form.repairFiles.forEach((file, index) => {
        formData.append(`repair_files[${index}]`, file);
      });

      this.form.receiptFiles.forEach((file, index) => {
        formData.append(`receipt_files[${index}]`, file);
      });


          // Append isExternalVehicle value to formData
    formData.append('isExternalVehicle', this.isExternalVehicle ? '1' : '0');
    formData.append('noteVehicle', this.isExternalVehicle ? 'PTT' : ''); 

      const response = await axios.post('https://brk-group.org/api/urgent_purchase_orders', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Form saved successfully:', response?.data);
      Swal.fire({
        icon: 'success',
        title: 'บันทึกสำเร็จ',
        text: 'ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว',
      });
      socket.emit('message', { type: 'new_purchase_request', data: response.data });
      this.resetForm();
      this.$router.go(0);
    } catch (error) {
      console.error('Error saving form:', error);
      if (error.response && error.response.status === 401) {
        this.$router.push({ name: 'Login' });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: error.response?.data || 'ไม่สามารถบันทึกข้อมูลได้',
        });
      }
    } finally {
      this.loading = false;
    }
  },
filterCreditTerms(event) {
    const input = event.target.value;
    const filtered = input.replace(/[^0-9]/g, '');
    this.poDetail.creditTerms = filtered;
  },
resetForm() {
    const { creatorName } = this.poDetail; // เก็บข้อมูลที่ต้องการคงไว้
    this.generatePurchaseRequestNumber(); // Generate เลขที่ใบสั่งซื้อใหม่

    this.poDetail = {
      creationDate: '',
      creatorName, // คงชื่อผู้สร้างไว้
      poNumber: this.poDetail.poNumber, // ใช้เลขที่ใบสั่งซื้อที่ถูก generate ใหม่
      VehicleID: '',
      vehicleReg: '',
      vehicleCode: '',
      model: '',
      vehicleVersion: '',
      chassisNumber: '',
      mileage: '',
      brand: '',
      operatorName: '',
      position: '',
      supplierName: '',
      taxId: '',
      contactChannel: '',
      details: '',
      creditTerms: '',
      taxDeduct: '',
      shippingAddress: '',
      receivingWarehouse: '',
      selectedWarehouseName: '',
      files: '',
      status: '',
      items: []
    };
    this.form = {
      items: [],
      totalAmount: 0,
      discountPercent: 0,
      discountAmount: 0,
      amountAfterDiscount: 0,
      vatPercent: 0,
      vatAmount: 0,
      grandTotal: 0,
      receivingWarehouse: '',
      selectedWarehouseName: '',
      files: '',
      priceType: 0,
      repairFiles: [], // สำหรับไฟล์แจ้งซ่อม
      receiptFiles: [], // สำหรับไฟล์ใบเสร็จ
      
    };


    },
    async generatePONumber() {
    try {
      const response = await axios.get('https://brk-group.org/api/get_latest_po_number');
      const latestPONumber = response.data.latestPONumber;
      const newPONumber = this.incrementPONumber(latestPONumber);
      this.poDetail.poNumber = newPONumber;
    } catch (error) {
      console.error('Error generating PO number:', error);
      this.poDetail.poNumber = 'PO-U00001'; // ตั้งค่าเริ่มต้นหากเกิดข้อผิดพลาด
    }
  },

  incrementPONumber(latestPONumber) {
    const number = parseInt(latestPONumber.split('-')[1].substring(1)) + 1;
    return `PO-U${String(number).padStart(5, '0')}`;
  },
    prevProductPage() {
    if (this.currentProductPage > 1) {
      this.currentProductPage--;
    }
  },
  filterNumericInput(event, item, key) {
    const input = event.target.value;
    const filtered = parseFloat(input.replace(/[^0-9.]/g, '')) || 0;
    item[key] = filtered >= 0 ? filtered : 0;
    this.calculateItemTotal(item);
  },
 
  async loadPurchaseOrder(poId) {
    try {

      const response = await axios.get(`https://brk-group.org/api/urgent_purchase_orders/${poId}`);
      const poData = response.data;

      this.poDetail = {
        id: poData.po_id,
        creationDate: this.formatDate(poData.creation_date),
        creatorName: poData.creator_name,
        poNumber: poData.po_number,
        VehicleID: poData.VehicleID,
        vehicleReg: poData.vehicleReg,
        vehicleCode: poData.vehicleCode,
        model: poData.model,
        vehicleVersion: poData.vehicleVersion,
        chassisNumber: poData.chassisNumber,
        mileage: poData.mileage,
        brand: poData.brand,
        operatorName: poData.operator_name,
        position: poData.position,
        supplierName: poData.supplier_name,
        taxId: poData.tax_id,
        contactChannel: poData.contact_channel,
        details: poData.details,
        creditTerms: poData.credit_terms,
        taxDeduct: poData.tax_deduct,
        shippingAddress: poData.shipping_address,
        receivingWarehouse: poData.receiving_warehouse,
        status: poData.status,
        disapproveReason: poData.disapproveReason,
        items: poData.items.map(item => ({
            productCode: item.product_code,
            productName: item.product_name,
            productType: item.product_type,
            quantity: item.quantity,
            unit: item.unit_name,
            unitPrice: item.unit_price,
            totalPrice: item.total_price,
            itemFileUrl: item.item_file_url || ''
          })),
        repairFiles: poData.repairFileUrls || [],
        receiptFiles: poData.receiptFileUrls || [],
        // other fields...
      };
      this.form = {
        items: poData.items,
        totalAmount: poData.total_amount,
        discountPercent: poData.discount_percent,
        discountAmount: poData.discount_amount,
        amountAfterDiscount: poData.amount_after_discount,
        vatPercent: poData.vat_percent,
        vatAmount: poData.vat_amount,
        grandTotal: poData.grand_total,
        receivingWarehouse: poData.receiving_warehouse,
        priceType: poData.price_type,
        repairFiles: poData.repairFileUrls || [],
        receiptFiles: poData.receiptFileUrls || [],
        // other fields...
      };
   
 
      this.isEditableVehicleCode = true;
 

      // ดึงข้อมูลทะเบียนรถ
      if (poData.VehicleID) {
        await this.loadVehicleDetails(poData.VehicleID);
      }

      // ดึงข้อมูลคลังทั้งหมดก่อน
      await this.fetchWarehouses();

      // ดึงข้อมูลชื่อคลัง
      if (poData.receiving_warehouse) {
        
        this.fetchWarehouseName(poData.receiving_warehouse);
      }
    } catch (error) {
      console.error('Error loading purchase order:', error);
    }
  },
  formatDate(date) {
    if (!date) return '';
      const d = new Date(date);
      d.setMinutes(d.getMinutes() - d.getTimezoneOffset()); // Adjust for timezone
      return d.toISOString().split('T')[0];
    },
  transformAttachmentUrl(url) {
    if (url) {
      return url.replace(
        'https://brknew.synology.me/web/imageProducts',
        'https://brkmeeting-room.online'
      );
    }
    return url;
  },
  openFile(url) {
    window.open(url, '_blank');
  },
  // extractFileName(url) {
  //   return url.substring(url.lastIndexOf('/') + 1);
  // },
  extractFileName(fileUrl) {
    const fileNameWithExtension = fileUrl.split('/').pop();
    const base64Part = fileNameWithExtension.split('-')[1];
    return base64Part ? base64Part.split('.')[0] : '';
  },
  async fetchWarehouseName(warehouseId) {
    const token = localStorage.getItem('userToken');
    try {
      const response = await axios.get(`https://brk-group.org/api/warehouses/${warehouseId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const warehouse = response.data;
      this.selectedWarehouseName = warehouse.name;
    
    } catch (error) {
      console.error(`Error fetching warehouse name for ID ${warehouseId}:`, error);
    }
  },
 
  preventNonNumeric(event) {
    if ((event.key < '0' || event.key > '9') && event.key !== '.') {
      event.preventDefault();
    }
  },
  nextProductPage() {
    if (this.currentProductPage < this.totalProductPages) {
      this.currentProductPage++;
    }
  },
  selectProduct(product) {
    // ตรวจสอบว่าสินค้ามีอยู่ในรายการแล้วหรือไม่
    const isProductDuplicate = this.form.items.some(item => item.productCode === product.product_code);
    
    if (isProductDuplicate) {
      // แสดงการแจ้งเตือนเมื่อมีสินค้าซ้ำ
      Swal.fire({
        icon: 'error',
        title: 'สินค้าซ้ำ',
        text: 'สินค้าที่คุณเลือกมีอยู่แล้วในรายการ',
        confirmButtonText: 'ตกลง'
      });
    } else {
      // เพิ่มสินค้าลงในรายการ
      this.form.items.push({
        productId: product.product_id, // Ensure product_id is assigned correctly
        productCode: product.product_code,
        productName: product.product_name,
        productType: product.product_type,
        quantity: 1,
        unit: product.unit_name,
        unitPrice: product.price_per_unit,
        totalPrice: product.price_per_unit,
        image: null
      });
      this.calculateAmounts();
      this.showProductModal = false;
    }
  },
  calculateAmounts() {
    let totalAmount = 0;
    for (const item of this.form.items) {
      item.totalPrice = parseFloat(item.quantity * item.unitPrice) || 0;
      totalAmount += item.totalPrice;
    }

    this.form.totalAmount = totalAmount.toFixed(2);
    let  discountPercent = parseFloat(this.form.discountPercent) || 0;

    if (discountPercent < 0) discountPercent = 0;
    if (discountPercent > 100) discountPercent = 100;
    this.form.discountPercent = discountPercent.toFixed(2);

    const discountAmount = (totalAmount * discountPercent / 100).toFixed(2);
    this.form.discountAmount = discountAmount;
    const amountAfterDiscount = (totalAmount - discountAmount).toFixed(2);
    this.form.amountAfterDiscount = amountAfterDiscount;
    
    let vatAmount = 0;
    let grandTotal = parseFloat(amountAfterDiscount);

    if (this.form.priceType === '1') { // รวม VAT
      const vatPercent = 7;
      const amountWithoutVat = grandTotal / (1 + vatPercent / 100);
      vatAmount = grandTotal - amountWithoutVat;
      this.form.vatPercent = 0;
    } else if (this.form.priceType === '2') { // แยก VAT
      const vatPercent = 7;
      vatAmount = grandTotal * vatPercent / 100;
      grandTotal += vatAmount;
      this.form.vatPercent = vatPercent;
    } else { // ไม่มี VAT
      this.form.vatPercent = 0;
    }

    this.form.vatAmount = vatAmount.toFixed(2);
    this.form.grandTotal = grandTotal.toFixed(2);
  },
  calculateDiscountPercent() {
    if (this.form.totalAmount > 0) {
      this.form.discountPercent = (this.form.discountAmount / this.form.totalAmount) * 100;
      this.calculateAmounts(); // เรียกฟังก์ชันนี้เพื่อคำนวณจำนวนเงินอื่นๆ
    }
  },
  handlePriceTypeChange() {
    if (this.form.priceType === '1') {
      this.form.vatPercent = 0;
    } else if (this.form.priceType === '2') {
      this.form.vatPercent = 7;
    }
    this.calculateAmounts();
  },
  calculateItemTotal(item) {
    item.totalPrice = (item.quantity * item.unitPrice).toFixed(2);
    this.calculateAmounts();
  },
    addItem() {
    this.form.items.push({
      productCode: '',
      productName: '',
      productType: '',
      quantity: 1,
      unit: '',
      image: null
    });
  },
  removeItem(index) {
    this.form.items.splice(index, 1);
    this.calculateAmounts();
  },
  validateQuantity(item) {
    if (item.quantity < 1) {
      item.quantity = 1;
    }
    this.calculateAmounts();
  },
  preventDecimal(event) {
    if (event.key === '.' || event.key === 'Decimal') {
      event.preventDefault();
    }
  },
  decodeFileName(encodedFileName) {
    try {
      return decodeURIComponent(escape(window.atob(encodedFileName.replace(/_/g, '/'))));
    } catch (e) {
      console.error('Error decoding file name:', e);
      return 'Invalid File Name';
    }
  },
  handleFileUpload(event, type) {
    const files = event.target.files;
    if (type === 'repair') {
      this.form.repairFiles.push(...files);
    } else if (type === 'receipt') {
      this.form.receiptFiles.push(...files);
    }
  },
  handleItemFileUpload(event, index) {
    const file = event.target.files[0];
    this.form.items[index].file = file;
  },
//   handleFileUpload(event) {
//   const files = event.target.files;
//   for (let i = 0; i < files.length; i++) {
//     this.poDetail.repairFiles.push(files[i]);
//   }
// },
// handleFileSlipUpload(event) {
//   const files = event.target.files;
//   for (let i = 0; i < files.length; i++) {
//     this.poDetail.receiptFiles.push(files[i]);
//   }
// },
// handleReceiptFileUpload(event) {
//   const files = event.target.files;
//   for (let i = 0; i < files.length; i++) {
//     this.poDetail.receiptFiles.push(files[i]);
//   }
// },
  // handleItemFileUpload(event, index) {
  //   const file = event.target.files[0];
  //   this.form.items[index].image = file;
  // },
  async fetchProducts() {
  try {
    const response = await axios.get('https://brk-group.org/api/productselect', {
      params: { search: this.productSearchQuery }
    });
    this.products = response.data;

  } catch (error) {
    console.error('Error fetching products:', error);
  }
},
  async fetchUser() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }
      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const userData = response.data;
        this.user = {
          login_id: userData.login_id,
          fullName: `${userData.prefixname}${userData.fname} ${userData.lname}`,
          group_id: userData.group_id,
          position_lo: userData.position_lo
        };
        this.isMaintenanceAdmin = (this.user.position_lo === 'ธุรการซ่อมบำรุง');
        this.isPurchase = (this.user.position_lo === 'เจ้าหน้าที่ธุรการจัดซื้อ');
        this.isPurchaseManager = (this.user.position_lo === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่');
        this.isCentralMaintenanceHead = (this.user.position_lo === 'หัวหน้าซ่อมบำรุงส่วนกลาง'); // สมมติว่าข้อมูลตำแหน่งอยู่ใน this.user.position
    
        this.poDetail.creatorName = this.user.fullName; // ดึงชื่อผู้ใช้จากข้อมูลล็อคอิน
        await this.fetchWarehouses();
      } catch (error) {
        console.error('Error fetching user:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' });
        } else {
          alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
        }
      }
    },
    validateForm() {
      this.errors = {};
      const requiredFields = {
        creationDate: 'วันที่สร้าง',
        creatorName: 'ชื่อผู้สร้างใบขอซื้อ',
        poNumber: 'เลขที่ใบสั่งซื้อ',
        vehicleReg: 'ทะเบียนรถ',
        vehicleCode: 'รหัสรถ',
        model: 'รุ่น',
        vehicleVersion: 'เวอร์ชั่นรถ',
        brand: 'ยี่ห้อ',
        mileage: 'เลขไมล์',
        chassisNumber: 'เลขคัทซี',
        // operatorName: 'ชื่อผู้ดำเนินการ',
        // position: 'ตำแหน่ง',
        supplierName: 'ชื่อผู้จำหน่าย',
        // taxId: 'เลขประจำตัวผู้เสียภาษี',
        contactChannel: 'ช่องทางติดต่อ',
        details: 'รายละเอียด',
        creditTerms: 'จำนวนเครดิต',
        shippingAddress: 'ที่อยู่จัดส่ง',
        // selectedWarehouseName: 'สถานที่รับอะไหล่',
        // unitPrice: 'ราคาต่อหน่วย'
      };

      for (const field in requiredFields) {
        if (!this.poDetail[field] && !this[field]) {
          this.errors[field] = `กรุณากรอก ${requiredFields[field]}`;
        }
      }

      return Object.keys(this.errors).length === 0;
    },
  
    convertImageUrl(url) {
      return url.replace('https://brknew.synology.me/web/imageProducts/Warehouse/', 'https://brkmeeting-room.online/Warehouse/');
    },
    // addItem() {
    //   this.poDetail.items.push({
    //     productCode: '',
    //     productName: '',
    //     productType: '',
    //     quantity: 0,
    //     unit: '',
    //     unitPrice: 0,
    //     totalPrice: 0,
    //     status: ''
    //   });
    // },
    // handleFileUpload(event) {
    //   const file = event.target.files[0];
    //   this.poDetail.documentFile = file;
    // },
   
    selectWarehouse(warehouse) {
    this.poDetail.receivingWarehouse = warehouse.warehouse_id;
    this.selectedWarehouseName  = warehouse.name;
    this.showWarehouseModal = false;
  },
    prevWarehousePage() {
      if (this.currentWarehousePage > 1) {
        this.currentWarehousePage--;
      }
    },
    nextWarehousePage() {
      if (this.currentWarehousePage < this.totalWarehousePages) {
        this.currentWarehousePage++;
      }
    },
    async approveOrder() {
      if (this.loading) return;
      this.loading = true;

       try {
        const response = await axios.put(`https://brk-group.org/api/urgent_purchase_orders/${this.poDetail.id}/approve`, {
          approve_id: this.user.login_id // รวม ID ของผู้อนุมัติ
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
        });


        const { fullName, position } = response.data.approver;

        this.poDetail.status = 'อนุมัติ';
        this.poDetail.operatorName = fullName;
        this.poDetail.position = position;
        this.poDetail.approve_date = new Date().toLocaleString();

        Swal.fire({
          icon: 'success',
          title: 'อนุมัติสำเร็จ',
          text: 'สถานะใบสั่งซื้อได้ถูกอัปเดตเป็น "อนุมัติ" แล้ว'
        });
      } catch (error) {
        console.error('Error approving order:', error);
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถอนุมัติใบสั่งซื้อได้ กรุณาลองใหม่อีกครั้ง'
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    const userAgency = localStorage.getItem('userAgency');

    this.isMaintenanceAdmin = (userAgency  === 'ธุรการซ่อมบำรุง');
    this.isPurchase = (userAgency  === 'เจ้าหน้าที่ธุรการจัดซื้อ');
    // this.isPurchaseManager = (userPosition === 'ผู้จัดการฝ่ายจัดซื้ออะไหล่');
    
    this.fromPOList = this.$route.query.fromPOList === 'true';
    
    this.fetchUser();
    this.fetchUserGroups();
    this.fetchWarehouses();
    this.fetchProducts();
    this.generatePurchaseRequestNumber();
    this.fetchVehicles();
    this.fetchSuppliers();
    const poId = this.$route.params.id;
  if (poId) {

    this.loadPurchaseOrder(poId);
  } else {

    this.isEditMode = false;
    this.resetForm();
  }
  },
  watch: {
    isExternalVehicle(newValue) {
      if (newValue) {
        this.poDetail.vehicleReg = ''; // เมื่อ isExternalVehicle เป็น true ให้เซ็ต poDetail.vehicleReg เป็นค่าว่าง
        this.poDetail.vehicleCode = '';
        this.poDetail.model = '';
        this.poDetail.vehicleVersion = '';
        this.poDetail.brand = '';
        this.poDetail.mileage = '';
        this.poDetail.chassisNumber = '';
        this.vehicles = []; // เคลียร์ข้อมูล vehicles เมื่อ isExternalVehicle เปลี่ยนแปลง
        this.fetchVehicles(); // ดึงข้อมูลใหม่จากตารางที่ถูกต้อง
      }
      if (!newValue) {
        this.poDetail.vehicleReg = ''; // เมื่อ isExternalVehicle เป็น false ให้เซ็ต poDetail.vehicleReg เป็นค่าว่าง
        this.poDetail.vehicleCode = '';
        this.poDetail.model = '';
        this.poDetail.vehicleVersion = '';
        this.poDetail.brand = '';
        this.poDetail.mileage = '';
        this.poDetail.chassisNumber = '';
        this.vehicles = []; // เคลียร์ข้อมูล vehicles เมื่อ isExternalVehicle เปลี่ยนแปลง
        this.fetchVehicles(); // ดึงข้อมูลใหม่จากตารางที่ถูกต้อง
      }
    },
    showGarages(newValue) {
      if (newValue) {
        this.fetchGarages();
      } else {
        this.fetchUserGroups();
      }
    },
    searchQuery() {
      this.currentPage = 1;
    },
    searchGroupQuery() {
    this.currentGroupPage = 1;
    this.updatePaginatedGroups();
  },
  selectedSupplier(newSupplierId) {
    const selectedSupplier = this.suppliers.find(supplier => supplier.supplier_id === newSupplierId);
    this.selectedSupplierTaxId = selectedSupplier ? selectedSupplier.tax_id : '';
  },
  searchSupplierQuery() {
      this.fetchSuppliers();
    },
  searchVehicleQuery() {
      this.fetchVehicles();
    },
    'poDetail.receivingWarehouse'(newVal) {

    if (newVal) {
      this.fetchWarehouseName(newVal);
    } else {
      this.selectedWarehouseName = '';
    }
  },
  'form.items': {
    handler() {
      this.calculateAmounts();
    },
    deep: true
  },
  'form.priceType': 'calculateAmounts',
}
};
</script>

<style scoped>
.po-form {
  padding: 16px;
  background-color: #f7f8fa;
}

.header {
  border-bottom: 2px solid #eaeaea;
}

.status-steps {
  display: flex;
  justify-content: space-between;
}

.status-step {
  background-color: #eaeaea;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
}

.status-step.active {
  background-color: #007bff;
  color: #fff;
}

.form-grid {
  display: grid;
  gap: 16px;
}

.table-fixed {
  table-layout: fixed;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.watermark {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  z-index: 9999;
  pointer-events: none;
  user-select: none;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.watermark.approved {
  color: rgba(0, 128, 0, 0.3); /* สีเขียวสำหรับอนุมัติ */
}

.watermark.disapproved {
  color: rgba(255, 0, 0, 0.3); /* สีแดงสำหรับไม่อนุมัติ */
}

.watermark-reason {
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: rgba(255, 0, 0, 0.3); /* สีแดงสำหรับเหตุผลการไม่อนุมัติ */
  z-index: 9999;
  pointer-events: none;
  user-select: none;
  text-align: center;
  width: 100%;
}
</style>
