<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">จัดการยี่ห้อสินค้า</h1>
    <div class="flex justify-between items-center mt-8 mb-4">


      <input v-model="searchQuery" @input="fetchBrands" placeholder="ค้นหาจากชื่อหรือรหัสยี่ห้อสินค้า..." class="p-2 border rounded" />
      <div class="mb-4 flex flex-wrap items-center justify-between gap-4">
        <!-- <router-link to ="/products" class="bg-pink-500 hover:bg-pink-700 text-white font-bold py-2 px-4 rounded">รายการสินค้า</router-link>
      <router-link to ="/product-types" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">ประเภทสินค้า</router-link>
      <router-link to ="/brandsProducts" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">ยี่ห้อสินค้า</router-link>
      <router-link to ="/vehicleTypes" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">ประเภทรถ</router-link>
      <router-link to ="/partTypes" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ประเภทอะไหล่</router-link> -->
    </div>
      <button @click="showModal(true, {})" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">เพิ่มยี่ห้อสินค้า</button>
      
    </div>
    
    <table class="w-full table-auto">
      <thead>
        <tr class="bg-gray-200">
          <th class="px-4 py-2">รหัสยี่ห้อ</th>
          <th class="px-4 py-2">ชื่อยี่ห้อ</th>
          <th class="px-4 py-2">รายละเอียด</th>
          <th class="px-4 py-2">การจัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="brand in brands" :key="brand.brand_id">
          <td class="border px-4 py-2">{{ brand.brand_code_name }}</td>
          <td class="border px-4 py-2">{{ brand.brand_name }}</td>
          <td class="border px-4 py-2">{{ brand.description }}</td>
          <td class="border px-4 py-2 text-center">
            <button @click="showModal(true, brand)" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded">แก้ไข</button>
            <button @click="confirmDelete(brand.brand_id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">ลบ</button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <BrandModal :isVisible="isModalVisible" :brand="currentBrand" @close="showModal(false)" @save="saveBrand" /> -->
    <BrandModal :isVisible="isModalVisible" :brand="currentBrand" @update:isVisible="isModalVisible = $event" @save="saveBrand" />



  </div>
</template>

<script>
import axios from 'axios';
import BrandModal from './BrandModal.vue';
import Swal from 'sweetalert2';
export default {
  components: {
    BrandModal
  },
  data() {
    return {
      searchQuery: '',
      brands: [],
      isModalVisible: false,
      currentBrand: {}
    };
  },
  methods: {
    fetchBrands() {
      axios.get(`https://brk-group.org/api/brands?search=${this.searchQuery}`)
        .then(response => {
          this.brands = response.data;
        })
        .catch(error => {
  console.error("Error:", error); // Log the error to the console
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: `เกิดข้อผิดพลาด: ${error.message}`, // Display a more specific error message
  });
});

    },
    showModal(isVisible, brand) {
      this.isModalVisible = isVisible;
      this.currentBrand = Object.assign({}, brand);
    },
    saveBrand(brand) {
    const method = brand.brand_id ? 'put' : 'post';
    const url = brand.brand_id ? `https://brk-group.org/api/brands/${brand.brand_id}` : 'https://brk-group.org/api/brands';
    
    axios[method](url, brand)
      .then(() => {
        this.fetchBrands();
        this.showModal(false, {});
        Swal.fire(
          'สำเร็จ!',
          brand.brand_id ? 'ข้อมูลยี่ห้อสินค้าได้รับการอัปเดต' : 'เพิ่มยี่ห้อสินค้าสำเร็จ',
          'success'
        );
      })
      .catch(error => {
        console.error("Error:", error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `เกิดข้อผิดพลาด: ${error.message}`,
        });
      });
  },
    confirmDelete(brandId) {
      Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณจะไม่สามารถย้อนกลับได้หากลบ!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ใช่, ลบเลย!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`https://brk-group.org/api/brands/${brandId}`)
            .then(() => {
              this.fetchBrands();
              Swal.fire(
                'ลบเรียบร้อย!',
                'ยี่ห้อสินค้าของคุณได้ถูกลบ.',
                'success'
              );
            })
            .catch(error => {
  console.error("Error:", error); // Log the error to the console
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: `เกิดข้อผิดพลาด: ${error.message}`, // Display a more specific error message
  });
});

        }
      });
    }
  },
  mounted() {
    this.fetchBrands();
  }
};
</script>
