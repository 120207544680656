// import { createApp } from 'vue'
// import App from './App.vue'

// createApp(App).mount('#app')
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// import socket from './services/socket'; // เพิ่มการนำเข้า socket.js
import { io } from 'socket.io-client'; // นำเข้า socket.io-client

// ใน main.js
import './assets/tailwind.css';
const socket = io('https://brk-group.org'); 
const app = createApp(App);

app.config.globalProperties.$socket = socket;

app.use(router);

app.mount('#app');
const resizeObserverErr = 'ResizeObserver loop limit exceeded';
const resizeObserverErrRegex = new RegExp(resizeObserverErr);
window.addEventListener('error', (event) => {
    if (resizeObserverErrRegex.test(event.message)) {
        event.stopImmediatePropagation();
    }
});
