<template>
    <div class="forgot-password-container">
      <h1>Forgot Password</h1>
      <form @submit.prevent="submitForgotPassword">
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            v-model="email"
            required
            placeholder="Enter your email"
          />
        </div>
        <button type="submit">Submit</button>
      </form>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: '',
        message: ''
      };
    },
    methods: {
      async submitForgotPassword() {
        try {
          // ลบตัวแปร response ที่ไม่ได้ใช้งานออก
          await axios.post('https://brk-group.org/forgot-password', { email: this.email });
          this.message = 'If that email address is in our records, we will send you an email to reset your password.';
        } catch (error) {
          console.error('Failed to submit forgot password request:', error);
          this.message = 'Failed to submit forgot password request. Please try again.';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Your styles here */
  </style>
  