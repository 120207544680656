<template>
  <div class="container mx-auto p-4">
    <div class="flex justify-between mb-4">
      <input
        v-model="searchQuery"
        @input="fetchSupplierTypes"
        type="text"
        placeholder="ค้นหาประเภทคู่ค้า..."
        class="p-2 border rounded shadow"
      />
      <button @click="showAddForm = true" class="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        เพิ่มประเภทคู่ค้า
      </button>
    </div>

    <table class="table-auto w-full mb-4">
      <thead>
        <tr class="bg-gray-200">
          <th class="px-4 py-2">ID</th>
          <th class="px-4 py-2">ประเภท</th>
          <th class="px-4 py-2">รายละเอียด</th>
          <th class="px-4 py-2">Actions</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="supplierTypes && supplierTypes.length">
          <tr v-for="type in supplierTypes" :key="type.type_id" class="border-b">
            <td class="px-4 py-2">{{ type.type_id }}</td>
            <td class="px-4 py-2">{{ type.type_name }}</td>
            <td class="px-4 py-2">{{ type.description }}</td>
            <td class="px-4 py-2">
              <button @click="editType(type)" class="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">แก้ไข</button>
              <button @click="deleteType(type.type_id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">ลบ</button>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="4" class="text-center py-4">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <add-edit-supplier-type-modal v-if="showAddForm || currentType" :current-type="currentType" @close="closeModal" @refresh="fetchSupplierTypes" />
  </div><br><br>
</template>

<script>
import axios from 'axios';
import AddEditSupplierTypeModal from './AddEditSupplierTypeModal.vue'; // ตรวจสอบ path ว่าถูกต้อง
import Swal from 'sweetalert2';

export default {
  components: {
    AddEditSupplierTypeModal,
  },
  data() {
    return {
      searchQuery: '',
      supplierTypes: [],
      currentType: null,
      showAddForm: false,
    };
  },
  async created() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      const response = await axios.get('https://brk-group.org/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.user = response.data; // ตรวจสอบให้แน่ใจว่า login_id ถูกดึงมาใน user object
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        // ถ้า token ไม่ถูกต้อง ให้ redirect ไปยังหน้า Login
        this.$router.push({ name: 'Login' });
      }
    }
this.fetchUser();
  },
  methods: {
    async fetchUser() {
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        // console.log(this.user); // ตรวจสอบข้อมูลผู้ใช้
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    fetchSupplierTypes() {
      // เปลี่ยนจาก "search" เป็น "searchQuery" ที่ใช้ใน endpoint ของคุณ
      const params = {
        searchQuery: this.searchQuery,
      };

      axios.get(`https://brk-group.org/supplier-types`, { params })
        .then(response => {
          this.supplierTypes = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching the supplier types:", error);
        });
    },
    deleteType(typeId) {
    Swal.fire({
      title: 'คุณแน่ใจไหม?',
      text: "คุณจะไม่สามารถย้อนกลับการกระทำนี้ได้!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยันการลบ!'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`https://brk-group.org/supplier-types/${typeId}`)
          .then(() => {
            Swal.fire(
              'Deleted!',
              'ประเภทคู่ค้าของคุณถูกลบแล้ว.',
              'success'
            );
            this.fetchSupplierTypes(); // Refresh list after deletion
          })
          .catch(error => {
            console.error("There was an error deleting the type:", error);
            Swal.fire('Failed!', 'There was an error deleting the type.', 'error');
          });
      }
    });
  },
    editType(type) {
      this.currentType = type;
      this.showAddForm = true;
    },
    closeModal() {
      this.currentType = null;
      this.showAddForm = false;
      this.fetchSupplierTypes(); // Refresh list after adding or editing
    },
  },
  mounted() {
    this.fetchSupplierTypes();
  },
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>
