<template>
   <link href="https://fonts.googleapis.com/css2?family=Mitr:wght@200;400&display=swap" rel="stylesheet">
   <!-- <header class="bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500 text-white shadow-md"> 
   <nav class="container mx-auto px-6 py-3 flex justify-between items-center">
   <ul class="flex space-x-4 items-center">
<li v-if="isAuthenticated && (userPosition === 'Insurance' || userPosition === 'All') ">
  <router-link to="/insurancehome" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
    <OfficeBuildingIcon class="w-5 h-5" />
    <span>Insurance Home</span>
  </router-link>
</li>
<li v-if="isAuthenticated && (userPosition === 'Insurance' || userPosition === 'All')">
  <router-link to="/vehicles/add" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
    <PlusCircleIcon class="w-5 h-5" />
    <span>เพิ่มข้อมูลรถ</span>
  </router-link>
</li>
<li v-if="isAuthenticated && (userPosition === 'Insurance' || userPosition === 'All')">
  <router-link to="/vehicles" class="flex items-center space-x-2 text-xl font-bold hover:text-teal-200 transition duration-300">
    <ClipboardListIcon class="w-5 h-5" />
    <span>ดูรายละเอียดข้อมูล</span>
  </router-link>
</li>
</ul>
</nav></header> -->
  <div class="min-h-screen flex items-center justify-center bg-gray-100 px-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
      <button
        class="transform hover:scale-110 focus:outline-none transition duration-500 ease-in-out bg-gradient-to-r from-blue-400 to-blue-600 text-white shadow-xl rounded-2xl text-3xl w-72 h-72"
        @click="goToAddVehicle"
      >
        เพิ่มข้อมูลรถ
      </button>
      <button
        class="transform hover:scale-110 focus:outline-none transition duration-500 ease-in-out bg-gradient-to-r from-green-400 to-green-600 text-white shadow-xl rounded-2xl text-3xl w-72 h-72"
        @click="goToVehicleList"
      >
        ดูรายละเอียดข้อมูล
      </button>
      <!-- คุณสามารถเพิ่มปุ่มที่ 3 ด้วยเฉดสีที่คุณต้องการได้ -->

    </div>
  </div>
</template>

<script>
export default {
  name: 'InsuranceHome',
  methods: {
    goToAddVehicle() {
      this.$router.push('/vehicles/add');
    },
    goToVehicleList() {
      this.$router.push('/vehicles');
    },
    // วิธีเพิ่มเติมสำหรับการเปลี่ยนเส้นทางอื่นๆ...
  },
};
</script>
