<template>
  <div class="flex">
    <!-- Left panel for product list -->
    <div class="flex-1 p-4">
      <h1 class="text-xl font-bold mb-4">รายการสินค้าในคลังสินค้า</h1>
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>

            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              รูปสินค้า
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              รหัสสินค้า
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ชื่อสินค้า
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ประเภทรถ
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ประเภทอะไหล่
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              จำนวนสินค้า
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              หน่วยเรียก
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ยี่ห้อรถ
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ประเภทสินค้า
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="product in products" :key="product.stock_id" @click="openSupplierModal(product)">
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              <img :src="product.product_image_url" alt="Product Image" class="w-12 h-12 object-cover cursor-pointer" @click.stop="openImageModal(product.product_image_url)">
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ product.product_code }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ product.product_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ product.vehicle_type }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ product.part_type }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ product.quantity }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ product.unit_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ product.brand_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ product.product_type }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Right panel for additional warehouse data -->
    <div class="w-1/3 bg-white p-4 shadow-lg">
      <div class="p-4 mt-4 border-t border-gray-200">
    <p class="text-lg font-bold">วันที่ปัจจุบัน: {{ currentDate }}</p>
    <p class="text-lg font-semibold">รอบตรวจ </p>  <!--   {{ inspectionRound }}  -->  
    <p class="text-lg">สถานะ: {{ inspectionStatus }}</p>
    <p class="text-red-500 font-normal text-sm">รอบตรวจ: {{ nextInspectionDates }}</p>
  </div>
      <div class="p-4">
        <img :src="selectedWarehouse.image_url" alt="Warehouse Image" class="w-full h-48 object-cover mb-4">
        <div>
          <h3 class="text-xl font-bold">{{ selectedWarehouse.name }}</h3>
          <p class="text-gray-600">{{ selectedWarehouse.address }}</p>
        </div>
        <div class="mt-4">
      <p class="text-lg font-bold">จำนวนสินค้าทั้งหมด: {{ totalQuantity }}</p>
      <p class="text-lg font-bold">เป็นเงินทั้งสิ้น: {{ totalValue }}</p>
    </div>
      </div>
    </div>
  </div>


  <!-- Modal for full image display -->
  <div v-if="showImageModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
      <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="width: 80%; max-height: 80vh;">
        <div class="flex justify-center items-center">
          <img :src="fullImageUrl" alt="Full Image" class="zoomable" @wheel="zoomImage" style="max-height: 70vh;">
        </div>
        <div class="text-center mt-4">
          <button @click="showImageModal = false" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Close
          </button>
        </div>
      </div>
    </div>

    <!-- Modal for supplier details -->
    <div v-if="showSupplierModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
      <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="width: 80%; max-height: 80vh;">
        <div class="text-center">
          <h3 class="text-xl font-bold mb-4">รายละเอียด</h3>
          <button @click="showSupplierModal = false" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">
            Close
          </button>
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ทะเบียนรถ
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ร้านคู่ค้า
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  รหัสรับเข้า
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  วันที่รับเข้า
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  จำนวนคงเหลือ
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ราคาต่อหน่วย
                </th>
                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              เป็นเงินทั้งสิ้น
            </th> -->
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  สถานะ
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="receipt in receipts" :key="receipt.receipt_code">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ receipt.registration_number }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ receipt.supplier_name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ receipt.receipt_code }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ formatDate(receipt.receipt_date) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ receipt.remaining_quantity }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ receipt.unit_price }}
                </td>
                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {{ receipt.total_value }}
            </td> -->
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ receipt.registration_number ? 'ไม่พร้อม' : 'พร้อม' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <button @click="openEditModal(receipt)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                 แก้ไข
                </button>
                </td>
              </tr>
            </tbody>
          </table>
        
    </div>
  </div>
</div>




<!-- Modal for editing product quantity -->
<!-- <div v-if="showEditModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
  <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="width: 30%; max-height: 80vh;">
    <div class="text-center">
      <h3 class="text-xl font-bold mb-4">แก้ไขรายการสินค้า</h3>
      <form @submit.prevent="updateReceipt">
        <div class="mb-4">
          <label for="editQuantity" class="block text-left text-xs font-medium text-gray-500 uppercase tracking-wider">จำนวนคงเหลือ</label>
          <input v-model="editReceipt.remaining_quantity" id="editQuantity" type="number" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2">
        </div>
        <div class="mb-4">
          <label for="editUnitPrice" class="block text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ราคาต่อหน่วย</label>
          <input v-model="editReceipt.unit_price" id="editUnitPrice" type="number" step="0.01" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2">
        </div>
        <div class="mb-4">
          <label for="editTotalValue" class="block text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เป็นเงินทั้งสิ้น</label>
          <input :value="(editReceipt.remaining_quantity * editReceipt.unit_price).toFixed(2)" id="editTotalValue" type="number" step="0.01" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2" readonly>
        </div>
        <div class="mb-4">
          <label for="editNotes" class="block text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รายละเอียด</label>
          <textarea v-model="editReceipt.notes" id="editNotes" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"></textarea>
        </div>
        <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          ตกลง
        </button>
        <button @click="closeEditModal" type="button" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2">
          ปิด
        </button>
      </form>
    </div>
  </div>
</div> -->

</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      products: [],
      selectedWarehouse: {},
      showImageModal: false,
      fullImageUrl: '',
      zoomLevel: 1,
      showSupplierModal: false,
      supplier: {},
      receipts: [],
      showEditModal: false, // เพิ่มข้อมูลนี้
      editReceipt: {}, // เพิ่มข้อมูลนี้
      totalQuantity: 0, // เพิ่มข้อมูลนี้
      totalValue: 0, // เพิ่มข้อมูลนี้
      currentDate: '',
    };
  },
  emits: ['authSuccess'], // ประกาศ event listener ที่จะถูกส่งไปยัง component
  mounted() {
    const today = new Date();
  this.currentDate = today.toLocaleDateString('th-TH', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  },
  created() {
    this.fetchProducts();
    this.fetchWarehouseDetails();
    this.fetchTotalValue();
  },
  methods: {
     fetchProducts() {
    const warehouseId = this.$route.params.warehouseId;
    const groupId = this.$route.params.groupId;
    axios.get(`https://brk-group.org/api/warehouses/${warehouseId}/products?groupId=${groupId}`)
      .then(response => {
        const products = response.data.map(product => {
          // เปลี่ยน URL รูปภาพ
          if (product.product_image_url) {
            product.product_image_url = product.product_image_url.replace('https://brknew.synology.me/web/imageProducts', 'https://brkmeeting-room.online');
          }
          return product;
        });

        // รวมรายการที่มี product_code เดียวกัน
        const uniqueProducts = [];
        const productMap = new Map();

        products.forEach(product => {
          if (productMap.has(product.product_code)) {
            const existingProduct = productMap.get(product.product_code);
            existingProduct.quantity += product.quantity; // รวมจำนวนสินค้า
          } else {
            productMap.set(product.product_code, product);
          }
        });

        productMap.forEach(product => {
          uniqueProducts.push(product);
        });

        this.products = uniqueProducts;
        this.calculateTotals();
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  },
    // fetchProducts() {
    //   const warehouseId = this.$route.params.warehouseId;
    //   const groupId = this.$route.params.groupId;
    //   axios.get(`https://brk-group.org/api/warehouses/${warehouseId}/products?groupId=${groupId}`)
    //     .then(response => {
    //       this.products = response.data.map(product => {
    //         // เปลี่ยน URL รูปภาพ
    //         if (product.product_image_url) {
    //           product.product_image_url = product.product_image_url.replace('https://brknew.synology.me/web/imageProducts', 'https://brkmeeting-room.online');
    //         }
    //         return product;
    //       });
    //       this.calculateTotals();
    //     })
    //     .catch(error => {
    //       console.error('Error fetching products:', error);
    //     });
    // },
    fetchWarehouseDetails() {
      const warehouseId = this.$route.params.warehouseId;
      axios.get(`https://brk-group.org/api/warehouses/${warehouseId}`)
        .then(response => {
          this.selectedWarehouse = response.data;
          // เปลี่ยน URL รูปภาพของคลังสินค้า
          if (this.selectedWarehouse.image_url) {
            this.selectedWarehouse.image_url = this.selectedWarehouse.image_url.replace('https://brknew.synology.me/web/imageProducts', 'https://brkmeeting-room.online');
          }
        })
        .catch(error => {
          console.error('Error fetching warehouse details:', error);
        });
    },
    fetchTotalValue() {
    const warehouseId = this.$route.params.warehouseId;
    axios.get(`https://brk-group.org/api/warehouses/${warehouseId}/totalValue`)
      .then(response => {
        this.totalValue = Number(response.data.totalValue).toFixed(2);
      })
      .catch(error => {
        console.error('Error fetching total value:', error);
        this.totalValue = '0.00';
      });
  },
    calculateTotals() {
      this.totalQuantity = this.products.reduce((sum, product) => sum + product.quantity, 0);
    // this.totalValue = this.products.reduce((sum, product) => sum + (product.quantity * product.unit_price), 0).toFixed(2);
  },
    openImageModal(url) {
      this.fullImageUrl = url;
      this.showImageModal = true;
      this.zoomLevel = 1; // รีเซ็ตระดับการซูมเมื่อเปิด modal
    },
    zoomImage(event) {
      event.preventDefault();
      const scaleAmount = 0.1;
      if (event.deltaY < 0) {
        this.zoomLevel += scaleAmount;
      } else if (event.deltaY > 0) {
        this.zoomLevel = Math.max(1, this.zoomLevel - scaleAmount);
      }
      const image = event.target;
      image.style.transform = `scale(${this.zoomLevel})`;
    },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    openSupplierModal(product) {
    // ดึงข้อมูลร้านคู่ค้าจาก product และตั้งค่าใน supplier
    const warehouseId = this.$route.params.warehouseId;
    this.fetchProductReceipts(warehouseId, product.product_code); // ดึงข้อมูล receipts
  
    this.showSupplierModal = true;
  },
  fetchProductReceipts(warehouseId, productCode) {
    axios.get(`https://brk-group.org/api/products/${warehouseId}/${productCode}/receipts`)
      .then(response => {
        console.log(response.data); // ตรวจสอบข้อมูลที่ได้รับจาก API
        this.receipts = response.data;
      })
      .catch(error => {
        console.error('Error fetching product receipts:', error);
      });
  },
  openEditModal(receipt) {
    this.editReceipt = { ...receipt }; // Clone the receipt to avoid direct mutation
    this.showEditModal = true;
  },
  closeEditModal() {
    this.showEditModal = false;
    this.editReceipt = {};
  },
  updateReceipt() {
    axios.put(`https://brk-group.org/api/receipts/${this.editReceipt.receipt_code}`, this.editReceipt)
      .then(response => {
        this.receipts = response.data;
        this.fetchProductReceipts(this.editReceipt.product_code); // Refresh the receipts list
        this.closeEditModal();
      })
      .catch(error => {
        console.error('Error updating receipt:', error);
      });
  },

  }
}
</script>

<style scoped>
.flex {
  display: flex;
}
.relative {
  position: relative;
}
.object-cover {
  object-fit: cover;
}
.absolute {
  position: absolute;
}
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>