<template>
  <div class="modal" v-if="visible">
    <div class="modal-content">
      <div class="modal-header">
        <span class="close" @click="close">&times;</span>
        <h2>การแจ้งเตือน</h2>
      </div>
      <div class="modal-body">
        <p>เกิดขอผิดพลาดในการลบประเภทสินค้า : "<strong>{{ productTypeName }}</strong>"?</p>
        <p v-if="products.length > 0">
         ประเภทสินค้านี้ไม่สามารถลบได้
          <ul>
            <li v-for="product in products" :key="product.id">{{ product.name }}</li>
          </ul>
         กรุณาลบรายการสินค้าในประเภทสินค้านี้ ก่อนลบประเภทสินค้า
        </p>
        <p v-else>This action cannot be undone.</p>
      </div>
      <div class="modal-footer">
        <button @click="confirm" :disabled="products.length > 0"></button>
        <button @click="close">ปิด</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    products: Array,
    productTypeName: String
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirmDelete');
    }
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-header h2 {
  margin: 0;
}

.modal-body p {
  margin-top: 15px;
}

.modal-footer {
  text-align: right;
}

.modal-footer button {
  margin-left: 10px;
}
</style>
