<template>
  <div>
    Logging out...
  </div>
</template>

<script>
export default {
  name: 'UserLogout', // เปลี่ยนจาก 'Logout' เป็น 'UserLogout'
  mounted() {
    localStorage.removeItem('userToken');
    this.$router.push('/login');
  }
}
</script>

<style>
/* สไตล์ของคุณ */
</style>
