<template>
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 class="text-2xl font-semibold mb-6">เพิ่มคู่ค้าใหม่</h1>
      <form @submit.prevent="addSupplier" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
        <div class="sm:col-span-2">
          <label for="companyName" class="block text-sm font-medium text-gray-700">ชื่อบริษัท</label>
          <input type="text" id="companyName" v-model="supplier.company_name" 
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div>
          <label for="taxId" class="block text-sm font-medium text-gray-700">หมายเลขภาษี</label>
          <input type="text" id="taxId" v-model="supplier.tax_id"
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div>
          <label for="address" class="block text-sm font-medium text-gray-700">ที่อยู่</label>
          <textarea id="address" v-model="supplier.address"
                    class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"></textarea>
        </div>
        <div>
          <label for="phone" class="block text-sm font-medium text-gray-700">โทรศัพท์</label>
          <input type="text" id="phone" v-model="supplier.phone"
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div>
          <label for="contactName" class="block text-sm font-medium text-gray-700">ชื่อผู้ติดต่อ</label>
          <input type="text" id="contactName" v-model="supplier.contact_name"
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div>
          <label for="contactEmail" class="block text-sm font-medium text-gray-700">อีเมลผู้ติดต่อ</label>
          <input type="email" id="contactEmail" v-model="supplier.contact_email"
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div>
          <label for="website" class="block text-sm font-medium text-gray-700">เว็บไซต์</label>
          <input type="text" id="website" v-model="supplier.website"
                 class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
        </div>
        <div class="sm:col-span-2">
          <button type="submit" 
                  class="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  เพิ่มคู่ค้า
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      supplier: {
        company_name: '',
        tax_id: '',
        address: '',
        phone: '',
        contact_name: '',
        contact_email: '',
        website: ''
      }
    };
  },
  created() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
    }
  },
  methods: {
    async addSupplier() {
  try {
    await axios.post('https://brk-group.org/add-supplier', this.supplier);
    Swal.fire({
      icon: 'success',
      title: 'สำเร็จ',
      text: 'คู่ค้าถูกเพิ่มเรียบร้อยแล้ว',
    }).then(() => {
      this.$emit('supplierAdded'); // ส่งเหตุการณ์ 'supplierAdded' แทน
    });
  } catch (error) {
    console.error(error);
    Swal.fire({
      icon: 'error',
      title: 'ผิดพลาด',
      text: 'เกิดข้อผิดพลาดในการเพิ่มคู่ค้า',
    });
  }
}
  }
};
</script>
