<template>
    <div class="container mx-auto px-4 py-8 shadow-lg rounded-lg">
      <div class="flex justify-between mb-6">
        <h2 class="text-2xl font-semibold">ประวัติการซ่อม</h2>
        <div class="space-x-4">
          <button 
            @click="setPurchaseType('repair')" 
            :class="['px-4 py-2 rounded', purchaseType === 'repair' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700']">
            ซื้อเพื่อซ่อม
          </button>
          <button 
            @click="setPurchaseType('stock')" 
            :class="['px-4 py-2 rounded', purchaseType === 'stock' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700']">
            ซื้อเพื่อลงสต็อค
          </button>
        </div>
      </div>
  
      <div class="overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200 shadow overflow-hidden rounded-lg">
          <thead class="bg-gray-50">
            <tr>
              <th v-if="purchaseType === 'repair'" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ทะเบียนรถ</th>
              <th v-if="purchaseType === 'stock'" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สถานที่รับอะไหล่</th>
              <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">เลขที่เอกสาร</th>
              <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่เปิดเอกสาร</th>
              <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่ปิดเอกสาร</th>
              <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จำนวนรายการ</th>
              <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ร้านคู่ค้า</th>
              <th v-if="purchaseType === 'repair'" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">หน่วยงานที่ขอ</th>
              <th v-if="purchaseType === 'stock'" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">หน่วยงานที่เปิด</th>
              <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="item in displayedData" :key="item.id">
              <td v-if="purchaseType === 'repair'" class="px-6 py-4 whitespace-nowrap text-center">{{ item.vehicleRegistration }}</td>
              <td v-if="purchaseType === 'stock'" class="px-6 py-4 whitespace-nowrap text-center">{{ item.warehouse }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.documentNumber }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.openDate }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.closeDate }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.itemsCount }}</td>
              <td class="px-6 py-4 whitespace-nowrap text-center">{{ item.shop }}</td>
              <td v-if="purchaseType === 'repair'" class="px-6 py-4 whitespace-nowrap text-center">{{ item.requestingDepartment }}</td>
              <td v-if="purchaseType === 'stock'" class="px-6 py-4 whitespace-nowrap text-center">{{ item.openingDepartment }}</td>
              <!-- <td class="px-6 py-4 whitespace-nowrap text-center">
                <router-link :to="{ name: 'ViewDetail', params: { id: item.id } }" class="text-blue-600 hover:text-blue-900">ดูรายละเอียด</router-link>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="flex justify-between mt-6">
        <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">ย้อนกลับ</button>
        <span>หน้า {{ currentPage }} จาก {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 bg-gray-300 text-gray-700 rounded disabled:opacity-50">ถัดไป</button>
      </div>
    </div>
  </template>
  
  <script>
import axios from 'axios';

export default {
  
  data() {
    return {
      activeSection: 'stock', // Default section
      stockItems: [],
      repairItems: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
  },
  methods: {
    setActiveSection(section) {
      this.activeSection = section;
      this.fetchData();
    },
    async fetchData() {
      try {
        const response = await axios.get(`https://your-api-endpoint.com/data`, {
          params: {
            type: this.activeSection,
            page: this.currentPage,
            limit: this.itemsPerPage
          }
        });
        if (this.activeSection === 'stock') {
          this.stockItems = response.data.items;
        } else if (this.activeSection === 'repair') {
          this.repairItems = response.data.items;
        }
        this.totalItems = response.data.total;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    viewDetail(id) {
      this.$router.push({ name: 'ViewDetail', params: { id } });
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchData();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchData();
      }
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
  
  <style scoped>
  .container {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
  }
  button {
    transition: background-color 0.2s, color 0.2s;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #dee2e6;
    padding: 8px;
  }
  thead th {
    background-color: #f8f9fa;
  }
  tbody tr:nth-child(even) {
    background-color: #f8f9fa;
  }
  @media (max-width: 768px) {
    table, thead, tbody, th, td, tr {
      display: block;
    }
    tr {
      margin-bottom: 15px;
    }
    th, td {
      padding: 10px;
      text-align: right;
    }
    th::before, td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
    }
    th:last-child, td:last-child {
      text-align: center;
    }
  }
  </style>
  
