<template>
    <div v-if="isVisible" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full " @click.self="closeModal">
      <div class="relative top-20 mx-auto p-5 border w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 shadow-lg rounded-md bg-white">
        <header class="flex justify-between items-center p-4 border-b">
          <h2 class="text-xl font-semibold">
            {{ localBrand.brand_id ? 'แก้ไขยี่ห้อสินค้า' : 'เพิ่มยี่ห้อสินค้าใหม่' }}
          </h2>
          <button class="text-black" @click="closeModal">
            <!-- Assuming you're using Heroicons, for example -->
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </header>
        <section class="max-h-64 overflow-auto">
          <form class="p-4">
            <div class="mb-4">
              <label class="block text-sm font-bold mb-2" for="brand_code_name">รหัสยี่ห้อ</label>
              <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="brand_code_name" type="text" placeholder="รหัสยี่ห้อ" v-model="localBrand.brand_code_name">
            </div>
            <div class="mb-4">
              <label class="block text-sm font-bold mb-2" for="brand_name">ชื่อยี่ห้อ</label>
              <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="brand_name" type="text" placeholder="ชื่อยี่ห้อ" v-model="localBrand.brand_name">
            </div>
            <div class="mb-4">
              <label class="block text-sm font-bold mb-2" for="description">รายละเอียด</label>
              <textarea class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="description" placeholder="รายละเอียด" v-model="localBrand.description"></textarea>
            </div>
          </form>
        </section>
        <div class="flex justify-end items-center p-4 border-t">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2" @click="saveBrand">บันทึก</button>
          <button class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded" @click="closeModal">ยกเลิก</button>
        </div>
      </div>
    </div> 
  </template>
  
  <script>
  export default {
    props: {
      isVisible: Boolean,
      brand: Object
    },
    data() {
      return {
        localBrand: {},
      };
    },
    methods: {
      closeModal() {
        this.$emit('update:isVisible', false);
      },
      saveBrand() {
        this.$emit('save', this.localBrand);
       this.closeModal();
      },
    },
    watch: {
      brand: {
        immediate: true,
        handler(newVal) {
          this.localBrand = JSON.parse(JSON.stringify(newVal || { brand_code_name: '', brand_name: '', description: '' }));
        },
      },
      isVisible(newVal) {
        if (!newVal) {
          // Reset localBrand when the modal is closed
          this.localBrand = { brand_code_name: '', brand_name: '', description: '' };
        }
      }
    },
  };
  </script>
  
  <style>
  .modal.is-active {
    display: flex;
  }
  /* You might need to adjust the styling depending on your CSS framework or custom styles */
  </style>
  