<template>
    <div class="notifications">
      <h3>Notifications</h3>
      <ul v-if="notifications.length">
        <li v-for="notification in notifications" :key="notification.id" @click="markAsRead(notification.id)">
          {{ notification.message }}
          <!-- สามารถเพิ่มปุ่มหรือการกระทำอื่นๆ สำหรับแต่ละการแจ้งเตือนได้ที่นี่ -->
        </li>
      </ul>
      <p v-else>No new notifications.</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        notifications: [],
      };
    },
    async created() {
      await this.fetchNotifications();
    },
    methods: {
      async fetchNotifications() {
        try {
          const response = await axios.get('https://localhost:3000/notifications'); // ปรับ URL ให้ตรงกับ API endpoint ของคุณ
          this.notifications = response.data;
        } catch (error) {
          console.error("Failed to fetch notifications:", error);
        }
      },
      async markAsRead(notificationId) {
        try {
          // ส่งคำขอไปยัง backend เพื่ออัปเดตสถานะการอ่านของการแจ้งเตือน
          await axios.put(`https://localhost:3000/notifications/${notificationId}`, { readStatus: true });
          // อัปเดตรายการการแจ้งเตือนใน UI
          this.fetchNotifications();
        } catch (error) {
          console.error("Failed to mark notification as read:", error);
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .notifications ul {
    list-style: none;
    padding: 0;
  }
  .notifications li {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  .notifications li:hover {
    background-color: #f0f0f0;
  }
  </style>
  