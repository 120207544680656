<template>
    <div class="reset-password-container">
      <h1>Reset Password</h1>
      <form @submit.prevent="submitResetPassword">
        <div class="form-group">
          <label for="password">New Password</label>
          <input
            type="password"
            id="password"
            v-model="password"
            required
            placeholder="Enter new password"
          />
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm New Password</label>
          <input
            type="password"
            id="confirmPassword"
            v-model="confirmPassword"
            required
            placeholder="Confirm new password"
          />
        </div>
        <button type="submit">Reset Password</button>
      </form>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        password: '',
        confirmPassword: '',
        message: ''
      };
    },
    methods: {
      async submitResetPassword() {
        if (this.password !== this.confirmPassword) {
          this.message = 'Passwords do not match.';
          return;
        }
  
        // Assuming the token is passed as a query parameter
        const token = this.$route.query.token;
  
        try {
          await axios.post('https://brknew.synology.me:443/reset-password', {
            token,
            newPassword: this.password
          });
          this.message = 'Your password has been successfully reset.';
          // Redirect user to the login page or a confirmation page
        } catch (error) {
          console.error('Failed to reset password:', error);
          this.message = 'Failed to reset password. Please try again or contact support.';
        }
      }
    }
  };
  </script>
  
  <style scoped>
  /* Your styles here */
  </style>
  