<template>
   <link href="https://fonts.googleapis.com/css2?family=Mitr:wght@200;400&display=swap" rel="stylesheet">
  <div class="container mx-auto mt-10">
    <h1 class="text-2xl font-bold mb-5">รายการทะเบียนรถ</h1>
    <div class="flex space-x-3 mb-5">
      <div class="mb-5">
  <input type="text" v-model="searchQuery" placeholder="ค้นหาจากเบอร์รถ" class="py-2 px-4 rounded border border-gray-300">
   <!-- New input for license plate search -->
   <input type="text" v-model="searchLicensePlate" placeholder="ค้นหาจากทะเบียนรถ" class="py-2 px-4 rounded border border-gray-300">
  
  <!-- New input for department search -->
  <input type="text" v-model="searchDepartment" placeholder="ค้นหาจากหน่วยงาน" class="py-2 px-4 rounded border border-gray-300">

</div>
      <button @click="filter = 'all'" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        ทั้งหมด
      </button>
      <button @click="filter = 'nearExpiry'" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
        ใกล้หมดอายุ
      </button>
      <button @click="filter = 'expired'" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
        หมดอายุแล้ว
      </button>
      <div class="text-right mr-5">
        <br>
       <!-- จำนวน {{ filteredVehicles.length }} จาก {{ vehicles.length }} รายการ -->
       จำนวนรายการ: {{ displayCount }}
</div>

    </div>
    <table class="min-w-full divide-y divide-gray-200 border border-gray-300 table-auto ">
  <thead class="bg-gray-800 text-white">
     <tr>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">เบอร์รถ</th>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">ทะเบียนรถ</th>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">จังหวัด</th>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">ยี่ห้อ</th>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">ปีรุ่น</th>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">อายุรถ</th>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">ประเภท</th>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">วันที่หมดอายุ</th>
        <th class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider">การดำเนินการ</th>
    </tr>
  </thead>
  <tbody class="bg-white divide-y divide-gray-200">
    <tr v-for="vehicle in paginatedVehicles" :key="vehicle.VehicleID">
      <td class="px-6 py-4 whitespace-nowrap ">{{ vehicle.VehicleNumber }}</td>
      <td class="px-6 py-4 whitespace-nowrap">{{ vehicle.LicensePlate }}</td>
      <td class="px-6 py-4 whitespace-nowrap">{{ vehicle.Province }}</td>
      <td class="px-6 py-4 whitespace-nowrap">{{ vehicle.Brand }}</td>
      <td class="px-6 py-4 whitespace-nowrap">{{ vehicle.ModelYear }}</td>
      <td class="px-6 py-4 whitespace-nowrap">{{ vehicle.VehicleAge }}</td>
      <td class="px-6 py-4 whitespace-nowrap">{{ vehicle.VehicleType }}</td>
      <td class="px-6 py-4 whitespace-nowrap">{{ formatDate(vehicle.ExpiryDate) }}</td>
      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <router-link :to="{ name: 'VehicleDetail', params: { VehicleID: vehicle.VehicleID }}" class="text-green-600 hover:text-green-900">ดูรายละเอียด</router-link>
        <router-link :to="{ name: 'EditVehicle', params: { VehicleID: vehicle.VehicleID }}" class="text-blue-600 hover:text-blue-900 ml-4">แก้ไข</router-link>
        <button @click="confirmDelete(vehicle.VehicleID)" class="text-red-600 hover:text-red-900 ml-4">ลบ</button>
      </td>
    </tr>
  </tbody>
</table>
<div class="mt-4">
  <button 
    @click="changePage(currentPage - 1)" 
    :disabled="currentPage === 1" 
    class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
    ก่อนหน้า
  </button>
  <span>หน้า {{ currentPage }} จาก {{ totalPages }}</span>
  <button 
    @click="changePage(currentPage + 1)" 
    :disabled="currentPage === totalPages" 
    class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
    ถัดไป
  </button>
</div>
  </div>
</template>


<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import io from 'socket.io-client';
export default {
  data() {
    
    return {
      socket: null,
      vehicles: [],
      filter: 'all', // 'all', 'nearExpiry', 'expired'
      searchQuery: '',
      searchLicensePlate: '', // ใหม่: ค้นหาทะเบียนรถ
    searchDepartment: '', // ใหม่: ค้นหาหน่วยงาน
    currentPage: 1, // หน้าปัจจุบัน
    itemsPerPage: 10 // จำนวนรายการต่อหน้า
    };
  },
  async mounted() {
    await this.fetchVehicles();
    this.socket = io('https://brk-group.org');
    this.socket.on('connect', () => {
      console.log('Connected to server');
    });
    this.socket.on('newVehicle', (newVehicle) => {
      this.vehicles.push(newVehicle);
    });
  },
  beforeUnmount() { // เปลี่ยนจาก beforeDestroy เป็น beforeUnmount
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  computed: {
    paginatedVehicles() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredVehicles.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.filteredVehicles.length / this.itemsPerPage);
  },
    expiredVehiclesCount() {
    const today = new Date();
    return this.vehicles.filter(vehicle => {
      const expiryDate = new Date(vehicle.ExpiryDate);
      return expiryDate < today;
    }).length;
  },
  nearExpiryCount() {
    const today = new Date();
    const twoMonthsLater = new Date(today.getFullYear(), today.getMonth() + 2, today.getDate());
    return this.vehicles.filter(vehicle => {
      const expiryDate = new Date(vehicle.ExpiryDate);
      return expiryDate >= today && expiryDate <= twoMonthsLater;
    }).length;
  },
  filteredVehicles() {
  const today = new Date();
  const twoMonthsLater = new Date(today.getFullYear(), today.getMonth() + 2, today.getDate());
  return this.vehicles.filter(vehicle => {
    const expiryDate = new Date(vehicle.ExpiryDate);
    let matchesFilter = true;
    let matchesSearch = true;

    // Filter by expiry date
    if (this.filter === 'nearExpiry') {
      matchesFilter = expiryDate >= today && expiryDate <= twoMonthsLater;
    } else if (this.filter === 'expired') {
      matchesFilter = expiryDate < today;
    }

    // Filter by vehicle number
    if (this.searchQuery.trim()) {
      matchesSearch = vehicle.VehicleNumber.toLowerCase().includes(this.searchQuery.toLowerCase());
    }

    // Filter by license plate
    if (this.searchLicensePlate.trim()) {
      matchesSearch = matchesSearch && vehicle.LicensePlate.toLowerCase().includes(this.searchLicensePlate.toLowerCase());
    }

    // Filter by department
    if (this.searchDepartment.trim()) {
      matchesSearch = matchesSearch && vehicle.Department.toLowerCase().includes(this.searchDepartment.toLowerCase());
    }

    return matchesFilter && matchesSearch;
  });
},

  displayCount() {
    let count;
    switch (this.filter) {
      case 'all':
        count = `${this.filteredVehicles.length}/${this.vehicles.length}`;
        break;
      case 'nearExpiry':
        count = `${this.filteredVehicles.length}/${this.nearExpiryCount}`;
        break;
      case 'expired':
        count = `${this.filteredVehicles.length}/${this.expiredVehiclesCount}`;
        break;
      default:
        count = `${this.filteredVehicles.length}/${this.vehicles.length}`;
    }
    return count;
  }
},

  methods: {
    changePage(page) {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
    }
  },
    async fetchVehicles() {
  try {
    const response = await axios.get('https://brk-group.org/vehicles');
    console.log(response.data); // Check the data structure
    // Ensure the response is an array before assigning
    this.vehicles = Array.isArray(response.data.vehicles) ? response.data.vehicles : [];
  } catch (error) {
    console.error("Failed to fetch vehicles:", error);
    this.vehicles = []; // Ensure vehicles is reset to an empty array in case of error
  }
},
formatDate(date) {
  if (!date) return '';
      const d = new Date(date);
      d.setMinutes(d.getMinutes() - d.getTimezoneOffset()); // Adjust for timezone
      return d.toISOString().split('T')[0];
    },

async confirmDelete(vehicleId) {
  const result = await Swal.fire({
    title: 'คุณแน่ใจที่จะลบข้อมูลนี้หรือไม่?',
    text: "คุณไม่สามารถย้อนกลับการกระทำนี้ได้!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'ใช่, ลบเลย!',
    cancelButtonText: 'ยกเลิก'
  });

  if (result.isConfirmed) {
    try {
      await axios.delete(`https://brk-group.org/vehicles/${vehicleId}`);
      this.fetchVehicles(); // Refresh the list after deletion
      Swal.fire(
        'ลบเรียบร้อย!',
        'ข้อมูลรถถูกลบออกแล้ว',
        'success'
      );
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด!',
        text: `ไม่สามารถลบข้อมูลรถได้: ${error}`,
        confirmButtonText: 'ตกลง'
      });
    }
  }
    },
  },
};
</script>

<style scoped>

</style>
