<template>
    <div class="p-6 bg-gray-100">
   <!-- Header Section -->
   <div class="mb-4">
      <div class="flex justify-between items-center">
        <h1 class="text-xl font-semibold">รายการนำเข้าสินค้า</h1>
      
       <router-link to="/import-product" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
  นำเข้าสินค้า
</router-link>
      </div>
    </div>

 <!-- Filters Section -->
 <div class="mb-4 flex space-x-4">
      <select v-model="selectedStatus" class="border rounded p-2">
        <option value="">ทั้งหมด</option>
        <option value="รออนุมัติ">รออนุมัติ</option>
        <option value="รับเข้าคลัง">รับเข้าคลัง</option>
        <option value="ยกเลิก">ยกเลิก</option>
      </select>
      <input v-model="searchQuery" type="text" placeholder="ค้นหาผู้นำเข้าหรือคลังที่รับ" class="border rounded p-2" />
      <input v-model="startDate" type="date" class="border rounded p-2" />
      <input v-model="endDate" type="date" class="border rounded p-2" />
      <button @click="resetFilters" class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
        ล้างตัวกรอง
      </button>
    </div>

    <!-- Table Section -->
    <div>
      <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ลำดับ</th>
            <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">เลขที่เอกสาร</th>
            <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">วันที่นำเข้า</th>
            <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">จำนวนสินค้า</th>
            <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">คลังที่รับ</th>
            <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">สถานะ</th>
            <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ผู้นำเข้า</th>
            <th class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ดำเนินการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredImports" :key="index" :class="{'bg-red-100': item.status === 'ยกเลิก', 'bg-orange-100': item.status === 'รออนุมัติ', 'bg-green-100': item.status === 'รับเข้าคลัง'}">
            <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ index + 1 }}</td>
            <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.doc_number }}</td>
            <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ formatDate(item.import_date) }}</td>
            <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.quantity }}</td>
            <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.warehouse_name }}</td>
            <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.status }}</td>
            <td class="border-b font-bold p-4 pl-8 pt-0 pb-3 text-white-darker text-center">{{ item.importer_name }}</td>
            <td class="border-b p-4 pl-8 text-grey-darker text-center">
              <button @click="openDetailsModal(item.import_id)" class="text-blue-500 font-bold hover:underline">รายละเอียด</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


<!-- 
      <div>
        <table class="min-w-full bg-white">
        <thead>
          <tr>
            <th class="border-b font-medium p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ลำดับ</th>
            <th class="border-b font-medium p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ชื่อผู้นำเข้า</th>
            <th class="border-b font-medium p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">เลขที่เอกสาร</th>
            <th class="border-b font-medium p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">วันที่นำเข้า</th>
            <th class="border-b font-medium p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">จำนวนสินค้า</th>
            <th class="border-b font-medium p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">คลังที่รับ</th>
            <th class="border-b font-medium p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">สถานะ</th>
            <th class="border-b font-medium p-4 pl-8 pt-0 pb-3 text-grey-darker text-center">ดำเนินการ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredImports" :key="index">
            <td class="border-b p-4 pl-8 text-grey-darker text-center">{{ index + 1 }}</td>
            <td class="border-b p-4 pl-8 text-grey-darker text-center">{{ item.importer_name }}</td>
            <td class="border-b p-4 pl-8 text-grey-darker text-center">{{ item.doc_number }}</td>
            <td class="border-b p-4 pl-8 text-grey-darker text-center">{{ formatDate(item.import_date) }}</td>
            <td class="border-b p-4 pl-8 text-grey-darker text-center">{{ item.quantity }}</td>
            <td class="border-b p-4 pl-8 text-grey-darker text-center">{{ item.warehouse_name }}</td>
            <td class="border-b p-4 pl-8 text-grey-darker text-center">{{ item.status }}</td>
            <td class="border-b p-4 pl-8 text-grey-darker text-center">
              <button @click="openDetailsModal(item.import_id)" class="text-blue-500 hover:underline">รายละเอียด</button>
            </td>
          </tr>
        </tbody>
      </table>
      </div> -->
      
   
    
        <!-- Modal -->
    <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white p-6 rounded-lg shadow-lg w-3/4 h-4/5 overflow-y-auto">
        <h2 class="text-xl font-semibold mb-4">รายละเอียดการนำเข้า</h2>
        <div v-if="modalData" class="space-y-4 p-4 border rounded-lg shadow">
          <div class="flex items-center  border-b py-2">
            <strong class="w-1/3 text-right font-semibold pr-2">เลขที่เอกสาร:</strong>
            <span class="w-2/3">{{ modalData.doc_number }}</span>
          </div>
          <div class="flex items-center border-b py-2">
            <strong class="w-1/3 text-right font-semibold pr-2">วันที่นำเข้า:</strong>
            <span class="w-2/3">{{ formatDate(modalData.import_date) }}</span>
          </div>
          <div class="flex items-center border-b py-2">
            <strong class="w-1/3 text-right font-semibold pr-2">ผู้นำเข้า:</strong>
            <span class="w-2/3">{{ modalData.importerName }}</span>
          </div>
          <div class="flex items-center border-b py-2">
            <strong class="w-1/3 text-right font-semibold pr-2">ตำแหน่งผู้นำเข้า:</strong>
            <span class="w-2/3">{{ modalData.importerPosition }}</span>
          </div>
          <div class="flex items-center border-b py-2">
            <strong class="w-1/3 text-right font-semibold pr-2">หน่วยงาน:</strong>
            <span class="w-2/3">{{ modalData.agency }}</span>
          </div>
          <div class="flex items-center border-b py-2">
            <strong class="w-1/3 text-right font-semibold pr-2">คลังที่รับ:</strong>
            <span class="w-2/3">{{ modalData.warehouse_name }}</span>
          </div>
          <div v-if="modalData.status === 'ยกเลิก'" class="flex items-center border-b py-2 bg-red-200">
            <strong class="w-1/3 text-right font-semibold pr-2">สถานะ:</strong>
            <span class="w-2/3">{{ modalData.status }}</span>
          </div>
          <div v-if="modalData.status === 'ยกเลิก'" class="flex items-center border-b py-2 bg-orange-500">
            <strong class="w-1/3 text-right font-bold pr-2">ผู้ยกเลิก:</strong>
            <span class="w-2/3">{{ modalData.cancelName || 'ไม่ระบุ' }}</span>
          </div>
          <div v-if="modalData.status === 'ยกเลิก'" class="flex items-center border-b py-2 bg-orange-500">
            <strong class="w-1/3 text-right font-bold pr-2">ตำแหน่งผู้ยกเลิก:</strong>
            <span class="w-2/3">{{ modalData.cancelPosition || 'ไม่ระบุ' }}</span>
          </div>
          <div v-if="modalData.status_approve === 'ไม่อนุมัติ'" class="flex items-center border-b py-2 bg-red-500">
            <strong class="w-1/3 text-right font-bold pr-2">ผู้ไม่อนุมัติ:</strong>
            <span class="w-2/3">{{ modalData.inspectorName || 'ไม่ระบุ' }}</span>
          </div>
          <div v-if="modalData.status_approve === 'ไม่อนุมัติ'" class="flex items-center border-b py-2 bg-red-500">
            <strong class="w-1/3 text-right font-bold pr-2">ตำแหน่งผู้ไม่อนุมัติ:</strong>
            <span class="w-2/3">{{ modalData.inspectorPosition || 'ไม่ระบุ' }}</span>
          </div>
          <div v-if="modalData.status_approve === 'ไม่อนุมัติ'" class="flex items-center border-b py-2 bg-red-500">
            <strong class="w-1/3 text-right font-bold pr-2">เหตุผล:</strong>
            <span class="w-2/3">{{ modalData.note_approve || 'ไม่ระบุ' }}</span>
          </div>
          <div v-if="modalData.status === 'รับเข้าคลัง' && modalData.status_approve === 'เสร็จสิ้น'" class="flex items-center border-b py-2 bg-green-500">
            <strong class="w-1/3 text-right font-bold pr-2">ผู้อนุมัติ:</strong>
            <span class="w-2/3">{{ modalData.inspectorName || 'ไม่ระบุ' }}</span>
          </div>
          <div v-if="modalData.status === 'รับเข้าคลัง' && modalData.status_approve === 'เสร็จสิ้น'" class="flex items-center border-b py-2 bg-green-500">
            <strong class="w-1/3 text-right font-bold pr-2">ตำแหน่งผู้อนุมัติ:</strong>
            <span class="w-2/3">{{ modalData.inspectorPosition || 'ไม่ระบุ' }}</span>
          </div>
          <h3 class="mt-4 mb-2 text-lg font-semibold">รายการสินค้า</h3>
          <table class="min-w-full bg-white">
            <thead>
              <tr>
                <th class="border-b p-2 text-center"></th>
                <th class="border-b p-2 text-center">ชื่อสินค้า</th>
                <th class="border-b p-2 text-center">ประเภทสินค้า</th>
                <th class="border-b p-2 text-center">ประเภทอะไหล่</th>
                <th class="border-b p-2 text-center">ยี่ห้อรถ</th>
                <th class="border-b p-2 text-center">จำนวน</th>
                <th class="border-b p-2 text-center">ราคาต่อหน่วย</th>
                <th class="border-b p-2 text-center">ราคารวม</th>
                <th class="border-b p-2 text-center">รูปประกอบ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in modalData.products" :key="product.product_id">
                <td class="border-b p-2">
                  <img :src="product.product_image_url ? getImageUrl2(product.product_image_url) : ''" alt="Product Image" class="w-10 h-10">
                  </td>
                  
                <td class="border-b p-2">{{ product.product_name }}</td>
                <td class="border-b p-2">{{ product.product_type }}</td>
                <td class="border-b p-2">{{ product.part_type }}</td>
                <td class="border-b p-2">{{ product.brand_name }}</td>
                <td class="border-b p-2">{{ product.quantity }}</td>
                <td class="border-b p-2">{{ product.unitPrice }}</td>
                <td class="border-b p-2">{{ product.totalPrice }}</td>
                <td class="border-b p-2">
                  <img :src="product.import_image_url ? getImageUrl(product.import_image_url) : ''" alt="Import Image" class="w-10 h-10">
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-4 text-right">
          <button @click="closeModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">ปิด</button>
          <button v-if="modalData.status !== 'ยกเลิก'" @click="cancelImport(modalData.import_id)" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">ยกเลิกใบนำเข้า</button>
        </div>
        
      </div>
    </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      imports: [],
      showModal: false,
      modalData: null,
      selectedStatus: '', // สำหรับการกรองตามสถานะ
      searchQuery: '', // สำหรับการค้นหา
      startDate: '', // สำหรับการกรองช่วงวันที่
      endDate: '' // สำหรับการกรองช่วงวันที่
    };
  },
  mounted() {
    this.fetchImports();
    
  },
  computed: {
    pageCount() {
      return Math.ceil(this.products.length / this.itemsPerPage);
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.products.slice(start, end);
    },
    filteredImports() {
      return this.imports.filter(importItem => {
        const matchesStatus = this.selectedStatus === '' || importItem.status === this.selectedStatus;
        const matchesSearchQuery = this.searchQuery === '' ||
          (importItem.importerName && importItem.importerName.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
          (importItem.warehouse_name && importItem.warehouse_name.toLowerCase().includes(this.searchQuery.toLowerCase()));

        const importDate = new Date(new Date(importItem.import_date).setHours(0, 0, 0, 0));
        const startDate = this.startDate ? new Date(new Date(this.startDate).setHours(0, 0, 0, 0)) : null;
        const endDate = this.endDate ? new Date(new Date(this.endDate).setHours(23, 59, 59, 999)) : null;

        const matchesStartDate = !startDate || importDate >= startDate;
        const matchesEndDate = !endDate || importDate <= endDate;

        return matchesStatus && matchesSearchQuery && matchesStartDate && matchesEndDate;
      });
    },
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    async openDetailsModal(importId) {
      try {
        const response = await axios.get(`https://brk-group.org/api/imports/${importId}`);
        this.modalData = {
          ...response.data,
          importerName: `${response.data.importer_fname} ${response.data.importer_lname}`,
          importerPosition: response.data.importer_position,
          cancelName: `${response.data.cancel_fname} ${response.data.cancel_lname}`,
          cancelPosition: response.data.cancel_position,
          inspectorName: `${response.data.inspector_fname} ${response.data.inspector_lname}`,
          inspectorPosition: response.data.inspector_position,
          products: response.data.products.map(product => ({
            ...product,
            unitPrice: product.unit_price,
            totalPrice: product.quantity * product.unit_price,
          })),
        };
        this.showModal = true;
      } catch (error) {
        console.error('Error fetching import details:', error);
        alert('Failed to fetch import details due to a server error. Please try again later.');
      }
    },
    async cancelImport(importId) {
      try {
        const token = localStorage.getItem('userToken');
        const response = await axios.put(`https://brk-group.org/api/imports/${importId}/cancel`, {}, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (response.data === 'Import cancelled successfully') {
          this.modalData.status = 'ยกเลิก';
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          this.modalData.cancelName = `${decodedToken.fname} ${decodedToken.lname}`;
          this.modalData.cancelPosition = decodedToken.position_lo;
          this.showModal = false;
        } else {
          alert('การยกเลิกใบในเข้าไม่สำเร็จ');
        }
      } catch (error) {
        console.error('Error cancelling import:', error);
        alert('การยกเลิกใบในเข้าล้มเหลว');
      }
    },
    closeModal() {
      this.showModal = false;
      this.modalData = null;
    },
    async fetchImports() {
      try {
        const response = await axios.get('https://brk-group.org/api/imports');
        this.imports = response.data;
      } catch (error) {
        console.error('Error fetching imports:', error);
        alert('Failed to fetch imports due to a server error. Please try again later.');
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString('th-TH', options);
    },
    resetFilters() {
      this.selectedStatus = '';
      this.searchQuery = '';
      this.startDate = '';
      this.endDate = '';
    },
    getImageUrl(filename) {
      return filename.startsWith('https://') ? filename : `https://brkmeeting-room.online/Products/${encodeURIComponent(filename)}`;
    },
    getImageUrl2(filename) {
      return filename.startsWith('https://') ? filename : `https://brkmeeting-room.online/${encodeURIComponent(filename)}`;
    },
    openPartTypeModal() {
    this.showPartTypeModal = true;
  },
    submitImport() {
      // Logic to handle form submission
      // console.log('Form submitted:', this.importForm);
      // this.closeModal();
    },
    addNewProduct() {
      // Logic to add the new product to the database or state
      console.log("Product Added", this.newProduct);
      this.newProduct = { name: '' }; // Reset the product form
      this.showAddProductModal = false; // Close the modal after adding the product
    },
    addProduct() {
    if (this.newProduct.name && this.newProduct.code) { // ตรวจสอบการป้อนข้อมูลพื้นฐาน
      this.products.push({...this.newProduct});
      this.showAddProductModal = false;
      this.newProduct = { code: '', name: '', type: '', unit: '', details: '', alertQuantity: null }; // รีเซ็ตฟอร์ม
    } else {
      alert('กรุณากรอกข้อมูลที่จำเป็น');
    }
  },
  addOrUpdateProduct() {
    if (this.selectedProduct) {
      // อัปเดตสินค้าที่มีอยู่
      const index = this.products.findIndex(p => p.id === this.selectedProduct.id);
      this.products.splice(index, 1, {...this.selectedProduct});
    } else {
      // เพิ่มสินค้าใหม่
      this.products.push({...this.selectedProduct});
    }
    this.showModal = false;  // ปิดโมดัล
    this.selectedProduct = null;  // รีเซ็ตสินค้าที่เลือก
  },
    addPartType() {
     // เมื่อมีการส่งฟอร์ม, ให้เพิ่มหรืออัปเดตสินค้าในรายการสินค้า
     const product = this.products.find(p => p.product_code === this.partTypeForm.productCode);
    if (product) {
      product.quantity = this.partTypeForm.quantity; // อัปเดตจำนวน
      product.unitPrice = this.partTypeForm.unitPrice; // อัปเดตราคาต่อหน่วย
      product.totalPrice = this.partTypeForm.totalPrice; // อัปเดตยอดรวม
      product.description = this.partTypeForm.description; // อัปเดตคำอธิบาย
    }
    this.showPartTypeModal = false; // ปิด modal
  },
 
  
},

watch: {
  'partTypeForm.quantity': function() {
    this.partTypeForm.totalPrice = this.partTypeForm.quantity * this.partTypeForm.unitPrice;
  },
  'partTypeForm.unitPrice': function() {
    this.partTypeForm.totalPrice = this.partTypeForm.quantity * this.partTypeForm.unitPrice;
  }
},
created() {
    this.fetchImports();
  },
};
</script>

<style scoped>
/* Styles for your table and modal */
.table th, .table td {
  padding: 8px 12px;
  border: 1px solid #ccc;
}
</style>
  