<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-semibold mb-6">จัดการประเภทรถ</h1>
    <div class="mb-4 flex justify-between">
      <button @click="showModal(true, {})" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">เพิ่มประเภทรถ</button>
    </div>
    <div class="mb-4">
      <input v-model="searchQuery" @input="fetchVehicleTypes" placeholder="ค้นหาจากชื่อหรือรหัสประเภทรถ..." class="p-2 border rounded" />
    </div>
    <table class="w-full table-auto">
      <thead>
        <tr class="bg-gray-200">
          <th class="px-4 py-2">รหัสประเภท</th>
          <th class="px-4 py-2">ชื่อประเภท</th>
          <th class="px-4 py-2">รายละเอียด</th>
          <th class="px-4 py-2">การจัดการ</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="type in vehicleTypes" :key="type.vehicle_type_id">
          <td class="border px-4 py-2">{{ type.vehicle_code_name }}</td>
          <td class="border px-4 py-2">{{ type.type_name }}</td>
          <td class="border px-4 py-2">{{ type.description }}</td>
          <td class="border px-4 py-2 text-center">
            <button @click="showModal(true, type)" class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded">แก้ไข</button>
            <button @click="confirmDelete(type.vehicle_type_id)" class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">ลบ</button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Modal Component for Add/Edit Vehicle Type -->
    <VehicleTypeModal :isVisible="isModalVisible" :vehicleType="currentVehicleType" @close="showModal(false)" @save="saveVehicleType" />
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import VehicleTypeModal from './VehicleTypeModal.vue';

export default {
  components: {
    VehicleTypeModal
  },
  data() {
    return {
      searchQuery: '',
      vehicleTypes: [],
      isModalVisible: false,
      currentVehicleType: {} // Object for editing or adding new vehicle type
    };
  },
  methods: {
    fetchVehicleTypes() {
      axios.get(`https://brk-group.org/api/vehicle-types?search=${this.searchQuery}`)
        .then(response => {
          this.vehicleTypes = response.data;
        })
        .catch(error => {
          console.error("Error fetching vehicle types:", error);
          Swal.fire({
            icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณาติดต่อแผนก IT.',
          });
        });
    },
    showModal(show, vehicleType = {}) {
      this.isModalVisible = show;
      this.currentVehicleType = Object.assign({}, vehicleType);
    },
    saveVehicleType(vehicleType) {
      const url = vehicleType.vehicle_type_id ? `https://brk-group.org/api/vehicle-types/${vehicleType.vehicle_type_id}` : 'https://brk-group.org/api/vehicle-types';
      const method = vehicleType.vehicle_type_id ? 'put' : 'post';

      axios[method](url, vehicleType)
        .then(() => {
          this.fetchVehicleTypes();
          this.showModal(false);
          Swal.fire('สำเร็จ!',
            'ข้อมูลยี่ห้อสินค้าได้รับการอัปเดต',
            'success');
        })
        .catch(error => {
          console.error("Error saving vehicle type:", error);
          Swal.fire({
            icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณาติดต่อแผนก IT.',
          });
        });
    },
    confirmDelete(vehicleTypeId) {
      Swal.fire({
        title: 'คุณแน่ใจหรือไม่?',
        text: "คุณจะไม่สามารถย้อนกลับได้หากลบ!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`https://brk-group.org/api/vehicle-types/${vehicleTypeId}`)
            .then(() => {
              this.fetchVehicleTypes();
              Swal.fire(
                'ลบเรียบร้อย!',
                'ยี่ห้อสินค้าของคุณได้ถูกลบ.',
                'success'
              );
            })
            .catch(error => {
              console.error("Error deleting vehicle type:", error);
              Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด',
                text: 'กรุณาติดต่อแผนก IT.',
              });
            });
        }
      });
    }
  },
  mounted() {
    this.fetchVehicleTypes();
  }
};
</script>
