<template>
  <nav class="p-4 w-full">
    <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      <li v-for="menu in menus" :key="menu.title" class="flex items-center justify-center" :class="menu.class">
        <router-link :to="menu.route" class="w-full">
          <button class="flex items-center w-full p-4 text-black rounded-lg shadow-md hover:bg-opacity-75 transition-colors" :class="menu.class">
            <component :is="getIconComponent(menu.icon)" class="h-6 w-6 mr-2"></component>
            <span>{{ menu.title }}</span>
          </button>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { DocumentTextIcon, ArchiveIcon, ShoppingCartIcon, TagIcon, CubeIcon, ChartBarIcon, ClipboardListIcon } from '@heroicons/vue/outline';

export default {
  props: {
    menus: {
      type: Array,
      required: true
    }
  },
  components: {
    DocumentTextIcon,
    ArchiveIcon,
    ShoppingCartIcon,
    TagIcon,
    CubeIcon,
    ChartBarIcon,
    ClipboardListIcon
  },
  methods: {
    getIconComponent(iconName) {
      const iconComponents = {
        'document-text': DocumentTextIcon,
        'archive': ArchiveIcon,
        'shopping-cart': ShoppingCartIcon,
        'tags': TagIcon,
        'box': CubeIcon,
        'chart-bar': ChartBarIcon,
        'clipboard-list': ClipboardListIcon
      };
      return iconComponents[iconName] || DocumentTextIcon;
    }
  }
};
</script>

<style scoped>
nav {
  padding: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  text-align: left;
  transition: background-color 0.3s;
}

button:hover {
  opacity: 0.75;
}

/* ปรับแต่งสีของแต่ละเมนู */
.supplier-menu {
  background-color: #ffcccc;
}
.pr-menu {
  background-color: #ccffcc;
}
.pre-menu {
  background-color: #ccccff;
}
.supplier-manage-menu {
  background-color: #ffcc99;
}
.product-menu {
  background-color: #ff99cc;
}
.product-type-menu {
  background-color: #99ccff;
}
.repair-history-menu {
  background-color: #cc99ff;
}
.approve-po-menu {
  background-color: #ffff99;
}
.po-list-menu {
  background-color: #99ff99;
}
</style>
