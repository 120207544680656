<template>
  <div v-if="visible" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" @keydown.esc="close">
    <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
      <div class="mt-3 text-center">
        <span @click="close" class="close absolute top-0 right-0 m-3 text-gray-700 hover:text-gray-900 cursor-pointer">
          &times;
        </span>
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ localProductType.product_type_id ? 'แก้ไขประเภทสินค้า' : 'เพิ่มประเภทสินค้า' }}
        </h3>
        <form @submit.prevent="submitForm" class="mt-2">
          <div class="mx-auto max-w-lg">
            <div class="py-2">
              <input v-model="localProductType.product_code_name" type="text" placeholder="รหัสประเภทสินค้า" class="px-4 py-2 border rounded-lg w-full" required>
            </div>
            <div class="py-2">
             <!-- <label for="productCodeName" class="block text-sm font-medium text-gray-700">ชื่อประเภทสินค้า</label> -->
            <input v-model="localProductType.type_name" type="text" id="productCodeName" placeholder="ชื่อประเภทสินค้า" class="px-4 py-2 border rounded-lg w-full" required>
            </div>
            <div class="py-2">
              <textarea v-model="localProductType.description" placeholder="รายละเอียด" class="px-4 py-2 border rounded-lg w-full" rows="3"></textarea>
            </div>
            
            <div class="py-2 flex justify-end">
              <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                บันทึก
              </button>
              <button @click="close" type="button" class="ml-2 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                ยกเลิก
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  props: {
    visible: Boolean,
    productType: {
      type: Object,
      default: () => ({product_code_name: '', type_name: '', description: ''})
    }
  },
  data() {
    return {
      localProductType: this.productType ? { ...this.productType } : {product_code_name: '', type_name: '', description: '' }
    };
  },
  methods: {
    submitForm() {
      if (!this.localProductType.type_name) {
        Swal.fire('การแจ้งเตือน', 'ชื่อประเภทสินค้าเป็นข้อมูลจำเป็นและไม่สามารถเว้นว่างได้', 'warning');
        return;
      }

      const url = this.localProductType.product_type_id
        ? `https://brk-group.org/api/product-types/${this.localProductType.product_type_id}`
        : 'https://brk-group.org/api/product-types';

      const method = this.localProductType.product_type_id ? 'put' : 'post';
      const successMessage = this.localProductType.product_type_id
        ? 'แก้ไขประเภทสินค้าสำเร็จ!!'
        : 'เพิ่มประเภทสินค้าสำเร็จ!!';

      axios[method](url, this.localProductType)
        .then(() => {
          Swal.fire('สำเร็จ', successMessage, 'success');
          this.$emit('saved'); // Notify parent component about the save operation
          this.close();
        })
        .catch(error => {
          console.error('Error saving product type:', error);
          Swal.fire('ผิดพลาด', 'ไม่สามารถบันทึกประเภทสินค้าได้ กรุณาลองใหม่อีกครั้ง', 'error');
        });
    },





    close() {
      // Reset local product type and emit close event to parent component
      this.localProductType = { type_name: '', description: '' ,product_code_name: ''};
      this.$emit('close');
    }
  },
  watch: {
    // Watch for changes in productType prop to update localProductType
    productType: {
      deep: true,
      handler(newVal) {
        this.localProductType = { ...newVal };
      }
    }
  }
};
</script>

<style scoped>
.close {
  /* Style for close button */
}
</style>
