<template>
  <div v-if="isVisible" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center z-50">
    <div class="bg-white rounded-lg w-1/2">
      <div class="flex flex-col items-stretch">
        <div class="text-lg font-semibold p-5 border-b">
          {{ partType.part_type_id ? 'แก้ไขประเภทอะไหล่' : 'เพิ่มประเภทอะไหล่' }}
        </div>
        <div class="p-5">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="type_name">
              ชื่อประเภท
            </label>
            <input v-model="localPartType.type_name" id="type_name" type="text" placeholder="ชื่อประเภทอะไหล่"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="description">
              รายละเอียด
            </label>
            <textarea v-model="localPartType.description" id="description" placeholder="รายละเอียด"
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
          </div>
        </div>
        <div class="flex justify-end p-5 border-t">
          <button @click="savePartType" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-l">
            บันทึก
          </button>
          <button @click="closeModal" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-r">
            ยกเลิก
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    partType: Object,
  },
  data() {
    return {
      localPartType: this.partType ? { ...this.partType } : { type_name: '', description: '' },
    };
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    savePartType() {
      // Validation or API call to save the part type
      this.$emit('save', this.localPartType);
      this.closeModal();
    },
  },
  watch: {
    partType: {
      immediate: true,
      deep: true,
      handler(newVal) {
        this.localPartType = newVal ? { ...newVal } : { type_name: '', description: '' };
      },
    },
  },
};
</script>
