<template>
 <div class="p-5 bg-white rounded-lg shadow max-w-7xl mx-auto">
    <div class="mb-8 flex justify-between items-center">
      <div class="relative w-full">
        <input v-model="searchQuery" @input="fetchSuppliers" placeholder="ค้นหาจากชื่อบริษัท..." class="pl-10 pr-3 py-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out bg-white text-gray-700 placeholder-gray-400">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg class="h-5 w-5 text-gray-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
        </div>
      </div>
      <button @click="isModalOpen = true" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition ease-in duration-200">
        เพิ่มคู่ค้าใหม่
      </button>
    </div>
    
    <div v-if="isModalOpen" class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div class="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
        <div class="mt-3 text-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900"></h3>
          <div class="mt-2 px-7 py-3">
            <!-- Include the AddSupplier form component here if it's separated or copy its template part directly -->
            <AddSupplierForm @supplierAdded="handleRefresh" />
          </div>
          <div class="items-center px-4 py-3">
            <button @click="isModalOpen = false" class="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 transition ease-in duration-200">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto mt-6">
      <table class="min-w-full divide-y divide-gray-200 shadow overflow-hidden rounded-lg">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              ชื่อบริษัท
            </th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              หมายเลขภาษี
            </th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              ที่อยู่
            </th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="supplier in paginatedSuppliers" :key="supplier.supplier_id" :class="{'bg-gray-200': supplier.status_s === 'inactive'}">
            <td class="px-6 py-4 whitespace-nowrap">
              {{ supplier.company_name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              {{ supplier.tax_id }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap">
              {{ supplier.address }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
              <router-link :to="{ name: 'ViewSupplier', params: { supplier_id: supplier.supplier_id }}" class="text-green-600 hover:text-indigo-900 mr-2">ดูรายละเอียด </router-link>
              <router-link :to="{ name: 'EditSupplier', params: { supplier_id: supplier.supplier_id }}" class="text-indigo-600 hover:text-indigo-900 mr-2">แก้ไข</router-link>
              <button @click="toggleSupplierStatus(supplier.supplier_id, supplier.status_s)" class="text-magento-600 hover:text-pink-900 ml-4">
                {{ supplier.status_s === 'active' ? 'ปิดใช้งาน' : 'เปิดใช้งาน' }}
              </button>
              <button @click="deleteSupplier(supplier.supplier_id)" class="text-red-600 hover:text-red-900 ml-4">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div><br><br>
   <!-- Pagination Controls -->
   <div class="flex justify-center mt-4">
      <button 
        @click="prevPage" 
        :disabled="currentPage === 1"
        class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
      >
        Previous
      </button>
      <button 
        @click="nextPage" 
        :disabled="currentPage === totalPages"
        class="px-4 py-2 mx-1 bg-gray-200 text-gray-600 rounded-md"
      >
        Next
      </button>
    </div>
</template>

<script>
import AddSupplierForm from './AddSupplier.vue'; // อาจต้องปรับเปลี่ยนตามที่คุณจัดเรียง components
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  components: {
    AddSupplierForm
  },
  data() {
    return {
      suppliers: [],
      searchQuery: '', // Add this line
      isModalOpen: false, // Add this line
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    paginatedSuppliers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.suppliers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.suppliers.length / this.itemsPerPage);
    }
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async fetchSuppliers() {
      try {
        const response = await axios.get(`https://brk-group.org/suppliers`, {
          params: { search: this.searchQuery }
        });
        this.suppliers = response.data;
      } catch (error) {
        console.error("Failed to fetch suppliers:", error);
      }
    },
    async toggleSupplierStatus(supplierId, currentStatus) {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    try {
      await axios.put(`https://brk-group.org/suppliers/${supplierId}/status`, { status_s: newStatus });
      Swal.fire('สำเร็จ!', `สถานะคู่ค้าได้ถูกเปลี่ยนเป็น ${newStatus}`, 'success');
      this.fetchSuppliers(); // รีเฟรชรายการคู่ค้า
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการเปลี่ยนสถานะคู่ค้า:", error);
      Swal.fire('ผิดพลาด', 'ไม่สามารถเปลี่ยนสถานะคู่ค้าได้', 'error');
    }
  },
    handleRefresh() {
    this.isModalOpen = false; // ปิด modal
    this.fetchSuppliers(); // รีเฟรชรายการ suppliers
  },
    deleteSupplier(supplierId) {
      Swal.fire({
        title: `คุณต้องการลบบริษัทนี้ ใช่หรือไม่?`,
          text: "คุณจะไม่สามารถย้อนกลับการกระทำนี้ได้!",
          icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน!'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`https://brk-group.org/suppliers/${supplierId}`)
            .then(() => {
              Swal.fire(
                'สำเร็จ!',
                'บริษัทที่ท่านเลือกได้ถูกลบ.',
                'success'
              );
              this.fetchSuppliers();
            })
            .catch(error => {
              console.error("เกิดข้อผิดพลาด:", error);
              Swal.fire('ไม่สามารถลบได้ กรุณาติดต่อแผนกไอที', 'error');
            });
        }
      });
    }
  },
  mounted() {
    this.fetchSuppliers();
  },
};
</script>
<style scoped>
@media (max-width: 640px) {
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  thead {
    display: none;
  }

  tbody tr {
    display: block;
    margin-bottom: 1rem;
  }

  tbody td {
    display: block;
    text-align: right;
    padding-left: 50%;
    position: relative;
  }

  tbody td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 1rem;
    font-weight: bold;
    text-align: left;
  }
}
</style>
