<template>
    <div>
      <EmployeeList />
    </div>
  </template>
  
  <script>
  import EmployeeList from '@/components/Hr/EmployeeList.vue';
  
  export default {
    components: {
      EmployeeList,
    },
  };
  </script>
  