<template>
  <div class="p-6">
    <button @click="showAddModal = true" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4">
      เพิ่มคลังสินค้า
    </button>
<br><br>
 <!-- Card for each warehouse -->
 
 <div class="p-6 bg-gray-100">
    <div class="grid grid-cols-4 gap-4">
      <div v-for="warehouse in warehouses" :key="warehouse.warehouse_id" class="bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-300 overflow-hidden cursor-pointer">
        <div class="relative w-full h-48" @click="openImageModal(warehouse.image_url)">
          <img :src="warehouse.image_url" alt="Warehouse Image" class="w-full h-full object-cover object-center">
        </div>
        <div class="p-4">
          <h5 class="text-lg font-bold mb-2">{{ warehouse.group_name }}</h5>
          <p class="text-sm font-bold mb-1">{{ warehouse.name }}</p>
          <p class="text-gray-700 text-sm mb-4">{{ warehouse.address }}</p>
          <p @click.stop="openUsersModal(warehouse.warehouse_id)" class="text-blue-500 cursor-pointer hover:underline">ดูผู้ดูแลคลัง</p>
          <button @click.stop="openDetailsModal(warehouse)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            View Details
          </button>
          <router-link :to="{ name: 'products-page', params: { warehouseId: warehouse.warehouse_id, groupId: warehouse.group_id } }" class="mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded inline-block text-center">
              ดูรายการสินค้า
            </router-link>
        </div>
      </div>
    </div>

<!-- Modal for full image display -->
<div v-if="showImageModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
  <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="width: 80%; max-height: 80vh;">
    <div class="flex justify-center items-center">
      <img :src="fullImageUrl" alt="Full Image" class="zoomable" @wheel="zoomImage" style="max-height: 70vh;">
    </div>
    <div class="text-center mt-4">
      <button @click="showImageModal = false" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
        Close
      </button>
    </div>
  </div>
</div>

    <!-- Modal for warehouse details -->
    <div v-if="showDetailsModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
      <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="width: 80%; max-height: 80vh;">
        <h3 class="text-lg font-bold">{{ selectedWarehouse.name }}</h3>
        <p>{{ selectedWarehouse.address }}</p>
        <p>Group ID: {{ selectedWarehouse.group_id }}</p>
        <button @click="showDetailsModal = false" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">
          Close
        </button>
      </div>
    </div>
  </div>

      <!-- Modal for warehouse ผู้ดูแล -->
      <div v-if="showUsersModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
  <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="max-width: 650px; max-height: 80vh;">
    <h3 class="text-lg font-bold">ผู้ดูแลคลังสินค้า</h3>
    <table class="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            ชื่อ-นามสกุล
          </th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            ตำแหน่ง
          </th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            หน่วยงาน
          </th>
          <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            แผนก
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="user in users" :key="user.login_id">
          <td class="px-6 py-4 whitespace-nowrap">
            {{ user.full_name }} 
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            {{ user.position }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            {{ user.agency }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            {{ user.department }}
          </td>
        </tr>
      </tbody>
    </table>
    <button @click="showUsersModal = false" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
      Close
    </button>
  </div>
</div>







   <!-- Modal ที่แสนเยอะ -->
    <div v-if="showAddModal" class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center p-4">
      <div class="bg-white p-8 rounded-lg shadow-lg overflow-auto" style="max-width: 80vw; max-height: 80vh; width: auto; height: auto;">
        <h2 class="text-xl font-bold mb-6">เพิ่มคลังสินค้า</h2>
        <form @submit.prevent="addWarehouse">
          <!-- Image Upload -->
          <div class="mb-4">
            <label for="imageUpload" class="block text-gray-700 text-sm font-bold mb-2">แนบรูปภาพคลังสินค้า</label>
            <input type="file" id="imageUpload" @change="handleFileUpload" accept="image/*" class="mb-2">
            <div v-if="imagePreview" class="mt-2">
              <img :src="imagePreview" alt="Preview Image" class="max-w-xs max-h-56">
            </div>
          </div>

          <!-- Warehouse Details -->
          <div class="mb-4">
            <label for="name" class="block text-gray-700 text-sm font-bold mb-2">ชื่อคลังสินค้า</label>
            <input type="text" id="name" v-model="warehouse.name" placeholder="กรอกชื่อคลังสินค้า" class="border p-2 rounded w-full" required>
          </div>
          <div class="mb-4">
            <label for="group" class="block text-gray-700 text-sm font-bold mb-2">หน่วยงาน</label>
            <select id="group" v-model="warehouse.groupId" class="border p-2 rounded w-full" required>
              <option disabled value="">กรุณาเลือกหน่วยงาน</option>
              <option v-for="group in groups" :key="group.group_id" :value="group.group_id">
                {{ group.group_name }}
              </option>
            </select>
          </div>

          <!-- Managers Assignment -->
          <div v-for="(manager, index) in warehouse.managers" :key="index" class="mb-4">
            <label :for="'manager' + index" class="block text-gray-700 text-sm font-bold mb-2">
              {{ manager.role }}
            </label>
            <select :id="'manager' + index" v-model="manager.loginId" class="border p-2 rounded w-full" required>
              <option disabled value="">เลือกผู้ดูแล</option>
              <option v-for="option in managersOptions" :key="option.login_id" :value="option.login_id">
                {{ option.fullname }} ({{ option.position_lo }}) ({{ option.group_name }})
              </option>
            </select>
          </div>

          <!-- Warehouse Address -->
          <div class="mb-4">
            <label for="address" class="block text-gray-700 text-sm font-bold mb-2">ที่อยู่คลังสินค้า</label>
            <textarea id="address" v-model="warehouse.address" rows="3" placeholder="ป้อนที่อยู่คลังสินค้า" class="border p-2 rounded w-full" required></textarea>
          </div>

          <!-- Submission Buttons -->
          <button type="submit" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            บันทึก
          </button>
          <button @click="closeModal" type="button" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            ปิด
          </button>
        </form>
      </div>
    </div>
  </div>
</template>



<script>
import axios from 'axios';

export default {
  data() {
    return {
      showAddModal: false,
      showImageModal: false,
      showDetailsModal: false,
      showUsersModal: false,
      selectedWarehouse: null,
      fullImageUrl: '',
      groups: [],
      users: [],
      managersOptions: [],
      imagePreview: null,
      file: null,
      warehouses: [],
      warehouse: {
        name: '',
        address: '',
        groupId: null,
        group_name: '',
        image_url: '',
        managers: [
          { loginId: null, role: 'ผู้จัดการคนที่ 1' },
          { loginId: null, role: 'ผู้จัดการคนที่ 2' },
          { loginId: null, role: 'ธุรการคนที่ 1' },
          { loginId: null, role: 'ธุรการคนที่ 2' }
        ]
      },
      zoomLevel: 1
    };
  },
  mounted() {
    this.fetchGroups();
    this.fetchManagers();
    this.fetchWarehouses();
  },
  methods: {
    gotoProductsPage(warehouseId) {
    // Replace 'ProductsPage.vue' with the actual route or file you're using
    this.$router.push({ name: 'products-page', params: { warehouseId: warehouseId } });
  },
    fetchWarehouses() {
    axios.get('https://brk-group.org/api/warehouses')
      .then(response => {
        this.warehouses = response.data; // ตรวจสอบว่าข้อมูลถูกเก็บไว้ในตัวแปรที่ถูกต้อง
        console.log(this.warehouses); // ใช้ console.log ตรวจสอบข้อมูลที่ได้รับ
      })
      .catch(error => {
        console.error('Error fetching warehouses:', error);
      });
    },
    fetchUser(warehouseId) {
      axios.get(`https://brk-group.org/api/users/${warehouseId}`)
        .then(response => {
          this.users = response.data;
        })
        .catch(error => {
          console.error('Error fetching users:', error);
          this.users = [];
        });
    },
    zoomImage(event) {
      event.preventDefault();
      const scaleAmount = 0.1;
      if (event.deltaY < 0) {
        this.zoomLevel += scaleAmount;
      } else if (event.deltaY > 0) {
        this.zoomLevel = Math.max(1, this.zoomLevel - scaleAmount);
      }
      const image = event.target;
      image.style.transform = `scale(${this.zoomLevel})`;
    },
    openUsersModal(warehouseId) {
      this.fetchUser(warehouseId);
      this.showUsersModal = true;
    },
    openImageModal(url) {
      this.fullImageUrl = url;
      this.showImageModal = true;
    },
    openDetailsModal(warehouse) {
      this.selectedWarehouse = warehouse;
      this.showDetailsModal = true;
    },
    //modal ที่แสนเยอะ
    fetchGroups() {
      axios.get('https://brk-group.org/api/groups')
        .then(response => {
          this.groups = response.data;
        })
        .catch(error => console.error('Error fetching groups:', error));
    },
    fetchManagers() {
      axios.get('https://brk-group.org/api/managers')
        .then(response => {
          this.managersOptions = response.data.map(manager => ({
            login_id: manager.login_id,
            fullname: manager.fullname,
            position_lo: manager.position_lo,
            group_name: manager.group_name
          }));
        })
        .catch(error => console.error('Error loading managers:', error));
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.file = file;
      if (file) {
        const reader = new FileReader();
        reader.onload = e => {
          this.imagePreview = e.target.result;
          this.warehouse.image_url = e.target.result; // Consider not storing image URL in warehouse object if it's not needed
        };
        reader.readAsDataURL(file);
      }
    },
    addWarehouse() {
      if (!this.warehouse.name || !this.warehouse.address || !this.warehouse.groupId || !this.warehouse.managers.every(m => m.loginId)) {
        alert('กรุณากรอกข้อมูลในทุกช่องที่ต้องการและเลือกผู้จัดการ');
        return;
      }
      const formData = new FormData();
      if (this.file) {
      formData.append('image', this.file);
      } else {
      alert('กรุณาแนบรูปภาพคลังสินค้า');
      return;
      }
      formData.append('name', this.warehouse.name);
      formData.append('address', this.warehouse.address);
      formData.append('groupId', this.warehouse.groupId);
      formData.append('managers', JSON.stringify(this.warehouse.managers));

      axios.post('https://brk-group.org/api/warehouses', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        alert(`Warehouse successfully added! ID: ${response.data.warehouseId}`);
        this.closeModal();
        this.fetchWarehouses();
      }).catch(error => {
        console.error('Error adding warehouse:', error);
        alert('Failed to add warehouse: ' + error.message);
      });
    },
    closeModal() {
      this.showAddModal = false;
      this.imagePreview = null;
      this.warehouse = { name: '', address: '', groupId: null, image_url: '', managers: this.warehouse.managers.map(m => ({ ...m, loginId: null })) };
    }
  }
}
</script>