<template>
  <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
    <div class="bg-white p-6 rounded-lg max-w-sm w-full">
      <h2 class="font-semibold text-xl mb-4">{{ currentType ? 'แก้ไข' : 'เพิ่ม' }} ประเภท</h2>
      <form @submit.prevent="handleSubmit">
        <div class="mb-4">
          <label for="typeName" class="block text-sm font-medium text-gray-700">ประเภท</label>
          <input type="text" id="typeName" v-model="form.type_name" required class="mt-1 block w-full border-gray-300 rounded-md shadow-sm">
        </div>
        <div class="mb-4">
          <label for="description" class="block text-sm font-medium text-gray-700">รายละเอียด</label>
          <textarea id="description" v-model="form.description" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm"></textarea>
        </div>
        <div class="flex justify-end space-x-2">
          <button type="submit" class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            {{ currentType ? 'Update' : 'Create' }}
          </button>
          <button type="button" @click="$emit('close')" class="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  props: {
    currentType: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      form: {
        type_name: '',
        description: '',
      },
    };
  },
  watch: {
    currentType: {
      handler(newValue) {
        this.form = newValue ? { ...newValue } : this.defaultForm();
      },
      immediate: true,
    },
  },
  methods: {
    defaultForm() {
      return {
        type_name: '',
        description: '',
      };
    },
    async handleSubmit() {
      const url = this.currentType
        ? `https://brk-group.org/supplier-types/${this.currentType.type_id}`
        : `https://brk-group.org/supplier-types`;
      const method = this.currentType ? 'put' : 'post';

      try {
        await axios[method](url, this.form);
        Swal.fire({
          icon: 'success',
          title: 'สำเร็จ!',
          text: `ประเภทได้รับการ ${this.currentType ? 'แก้ไข' : 'เพิ่ม'} แล้ว.`,
        });
        this.$emit('refresh');
        this.$emit('close');
      } catch (error) {
        console.error("Failed to submit the form:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'There was an issue submitting the form. Please try again.',
        });
      }
    },
  },
};
</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 500px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.btn-submit {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #0056b3;
}
</style>
