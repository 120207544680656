<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
    <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
      <h1 class="text-2xl font-bold text-gray-800 mb-6 text-center">เชื่อมต่อบัญชี LINE ของคุณ</h1>

    
      <!-- ขั้นตอนการเชื่อมต่อ LINE -->
      <div class="mb-8">
        <ol class="list-decimal list-inside space-y-4">
          <!-- <li>
            คลิกปุ่ม <strong>เพิ่ม</strong> เพื่อเพิ่มบัญชี LINE ของ Service -->
            <!-- <div class="mt-4"> -->
              <!-- <img src="/path/to/add-image.png" alt="Add LINE Account" class="rounded-lg shadow-sm border"> -->
            <!-- </div> -->
          <!-- </li> -->
          <li>
            คลิกปุ่ม <strong class="font-bold">เพิ่มเพื่อน</strong>
            <a 
              href="https://line.me/R/ti/p/@077vdrqz" 
              target="_blank" 
              class="mt-2 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-full transition-colors w-full text-center block">
              เพิ่มเพื่อน
            </a>
            
          </li>
        
          
          <li>
            เมื่อเพิ่มเพื่อนสำเร็จแล้ว กดปุ่ม <strong class="font-bold">เชื่อมต่อบัญชี Line</strong> 
            <div class="mt-4 flex flex-col items-center">
              <!-- <img src="/path/to/capture-image.png" alt="Capture QR Code" class="rounded-lg shadow-sm border mb-4"> -->
              <!-- <img src="/path/to/qrcode-image.png" alt="QR Code" class="rounded-lg shadow-sm border w-48 h-48"> ต่ายบุญเคิม -->
            </div>
          </li>
        </ol>
          <!-- แสดงข้อความเมื่อเชื่อมต่อสำเร็จ -->
          <div v-if="isConnected" class="text-center mb-4">
  <span class="inline-block bg-green-500 text-white px-6 py-3 rounded-full text-lg font-bold shadow-lg transform transition-transform duration-300 hover:scale-105">
    เชื่อมต่อแล้ว
  </span>
</div>
      <!-- แสดงปุ่มเชื่อมต่อเมื่อยังไม่ได้เชื่อมต่อ -->
      <div v-else class="text-center mb-4">
        <span class="inline-block bg-gray-200 text-gray-600 px-4 py-2 rounded-full">ยังไม่ได้เชื่อมต่อ</span>
        <button @click="connectLine" class="mt-2 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-full transition-colors w-full">
          เชื่อมต่อบัญชี LINE
        </button>
      </div>
      <div v-if="isConnected" class="text-center mb-4">
  
    <!-- ปุ่มยกเลิกการเชื่อมต่อ -->
    <button @click="disconnectLine" class="mt-2 bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded-full transition-colors w-full">
      ยกเลิกการเชื่อมต่อ LINE
    </button>
  </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  export default {
    name: 'LineConnect',
    data() {
      return {
        isConnected: false, // ค่าเริ่มต้นว่าไม่ได้เชื่อมต่อ
      };
    },
    methods: {
      async fetchUser() {
      const token = localStorage.getItem('userToken');
      // console.log('User token:', token); // ตรวจสอบค่า token
      if (!token) {
        console.log('No token found. Redirecting to login.');
        this.$router.push({ name: 'Login' });
        return;
      }

      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        // console.log('User data fetched:', response.data); // ตรวจสอบข้อมูลผู้ใช้ที่ดึงมา
        // เก็บเฉพาะชื่อผู้ใช้ (username) ลงใน localStorage
        localStorage.setItem('userNameLogin', response.data.username); // ใช้ฟิลด์ `username` จากข้อมูลที่ส่งคืน
      } catch (error) {
        console.error('Error fetching user:', error);
        if (error.response && error.response.status === 401) {
          console.log('Invalid token. Clearing storage and redirecting to login.');
          localStorage.removeItem('userToken');
          localStorage.removeItem('userNameLogin');
          this.$router.push({ name: 'Login' });
        }
      }
    },
      generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      connectLine() {
    const state = this.generateRandomString(16); // สร้างค่า state แบบสุ่ม
    const nonce = this.generateRandomString(16); // สร้างค่า nonce แบบสุ่ม

    // เก็บค่า state และ nonce ใน localStorage เพื่อตรวจสอบภายหลัง
    localStorage.setItem('lineState', state);
    localStorage.setItem('lineNonce', nonce);

    // ดึงค่า username จาก localStorage
    const username = localStorage.getItem('userNameLogin'); // ดึงชื่อผู้ใช้จาก localStorage

    if (!username) {
      alert('กรุณาล็อกอินก่อนทำการเชื่อมต่อ LINE');
      return;
    }

    // สร้าง state ที่มี username เข้ารหัสใน JSON string
    const stateWithUsername = JSON.stringify({ state, username });

    const lineLoginUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2006044471&redirect_uri=${encodeURIComponent('https://brk-group.org/callback')}&state=${encodeURIComponent(stateWithUsername)}&scope=profile%20openid&nonce=${nonce}`;
    
    window.location.href = lineLoginUrl;
  },
  checkConnection() {
            // ตรวจสอบสถานะการเชื่อมต่อจาก cookie หรือ localStorage
            const lineUserId = document.cookie.replace(/(?:(?:^|.*;\s*)lineUserId\s*=\s*([^;]*).*$)|^.*$/, "$1");
            if (lineUserId) {
                this.isConnected = true;
                this.showSuccessAlert();
            }
        },
        showSuccessAlert() {
          if (this.isConnected) {
        Swal.fire({
          icon: 'success',
          title: 'เชื่อมต่อสำเร็จ!',
          text: 'คุณได้เชื่อมต่อบัญชี LINE ของคุณแล้ว.',
          confirmButtonText: 'ตกลง'
        });
      }
    },
    async checkConnectionStatus() {
    const token = localStorage.getItem('userToken');
    const username = localStorage.getItem('userNameLogin');

    console.log('Token:', token);  // ตรวจสอบค่า token
    console.log('Username:', username);  // ตรวจสอบค่า username

    if (!token || !username) {
        console.log('Token or Username not found. Redirecting to login.');
        this.$router.push({ name: 'Login' });
        return;
    }

    try {
        const response = await axios.get(`https://brk-group.org/api/user/line-status?username=${username}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        console.log('Response from line-status API:', response.data);

        if (response.data.connected) {
            this.isConnected = true;
            console.log('LINE connection found. isConnected is now:', this.isConnected);
            this.showSuccessAlert();
        } else {
            this.isConnected = false;
            console.log('No LINE connection found. isConnected is now:', this.isConnected);
            Swal.fire({
                icon: 'info',
                title: 'ยังไม่ได้เชื่อมต่อ',
                text: 'คุณยังไม่ได้เชื่อมต่อบัญชี LINE ของคุณ.',
                confirmButtonText: 'ตกลง'
            });
        }
    } catch (error) {
        console.error('Error checking connection status:', error);
        Swal.fire({
            icon: 'error',
            title: 'ข้อผิดพลาด',
            text: 'ไม่สามารถตรวจสอบสถานะการเชื่อมต่อได้ กรุณาลองใหม่อีกครั้ง.',
            confirmButtonText: 'ตกลง'
        });
    }
},

async disconnectLine() {
      const token = localStorage.getItem('userToken');
      const username = localStorage.getItem('userNameLogin');

      if (!token || !username) {
        this.$router.push({ name: 'Login' });
        return;
      }

      try {
        const response = await axios.post('https://brk-group.org/api/user/disconnect-line', { username }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 200) {
          this.isConnected = false;
          Swal.fire({
            icon: 'success',
            title: 'ลบการเชื่อมต่อสำเร็จ!',
            text: 'คุณได้ลบการเชื่อมต่อบัญชี LINE ของคุณเรียบร้อยแล้ว.',
            confirmButtonText: 'ตกลง'
          });
        }
      } catch (error) {
        console.error('Error disconnecting LINE:', error);
      }
    },
    },
    created() {
      this.checkConnection();
      this.fetchUser();
      this.checkConnectionStatus();
    },
    
    mounted() {
        this.checkConnection();
        this.showSuccessAlert(); // แสดงแจ้งเตือนเมื่อเชื่อมต่อสำเร็จใน mounted hook
        this.checkConnectionStatus(); // เรียกเพื่อเช็คสถานะการเชื่อมต่อ
    }
  };
  </script>
  
  <style scoped>
  /* ใช้ Tailwind CSS เพื่อจัดการสไตล์ */
  </style>
  