<template>
    <div class="p-4 grid grid-cols-3 gap-4">
        <!-- <router-link to="/repair-form" class="bg-red-200 hover:bg-red-300 text-red-800 font-semibold py-2 px-4 rounded shadow">
      แจ้งซ่อม
    </router-link> -->
    <router-link 
      to="/purchase-recives" 
      class="bg-blue-200 hover:bg-blue-300 text-blue-800 font-semibold py-2 px-4 rounded shadow">
      รายการใบ PR และ PO
    </router-link>
       <router-link to="/purchase-requisition" class="bg-yellow-200 hover:bg-yellow-300 text-yellow-800 font-semibold py-2 px-4 rounded shadow">
             เปิดใบขอซื้อ
      </router-link>
      <!-- <button class="bg-green-200 hover:bg-green-300 text-green-800 font-semibold py-2 px-4 rounded shadow">
        อนุมัติ
      </button>
      <button class="bg-purple-200 hover:bg-purple-300 text-purple-800 font-semibold py-2 px-4 rounded shadow">
        ใบงานซ่อม
      </button>
      <button class="bg-pink-200 hover:bg-pink-300 text-pink-800 font-semibold py-2 px-4 rounded shadow">
        ตรวจสอบ
      </button> -->
      <router-link to="/repair-garage" class="bg-teal-200 hover:bg-teal-300 text-teal-800 font-semibold py-2 px-4 rounded shadow">
      อู่นอก
    </router-link>
      <!-- <button class="bg-orange-200 hover:bg-orange-300 text-orange-800 font-semibold py-2 px-4 rounded shadow">
        ประเภทของซ่อม
      </button>
      <button class="bg-indigo-200 hover:bg-indigo-300 text-indigo-800 font-semibold py-2 px-4 rounded shadow">
        dashboard
      </button>
      <button class="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 rounded shadow">
        ห้ามถูก
      </button> -->
      <router-link to="/purchase-orders" class="bg-teal-200 hover:bg-teal-300 text-teal-800 font-semibold py-2 px-4 rounded shadow">
      เปิดใบขอซื้อด่วน
    </router-link>

    <router-link to="/po-list" class="bg-purple-200 hover:bg-purple-300 text-teal-800 font-semibold py-2 px-4 rounded shadow">รายการใบสั่งซื้อด่วน</router-link>
  
    </div>
  </template>
  
  <script>
  export default {
    name: 'HomeRepair',
  created() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
    }
  }
   
  };
  </script>
  
  <style scoped>
  /* You can add custom styles here if needed */
  </style>
  