<template>
    <div>
      <h1 class="text-2xl font-bold mb-4">รายการใบสั่งซื้อด่วน</h1>
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">เลขที่เอกสาร</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ทะเบียนรถ</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่สร้าง</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">จำนวนรายการที่สั่งซื้อ</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">ร้านคู่ค้า</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">สถานะ</th>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="order in paginatedOrders" :key="order.po_id">
            <td class="px-6 py-4 whitespace-nowrap">{{ order.po_number }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ order.LicensePlate }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ formatDate(order.creation_date) }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ order.items_count }}</td>
            <td class="px-6 py-4 whitespace-nowrap">{{ order.supplier_name }}</td>
            <td class="px-6 py-4 whitespace-nowrap" :class="{'bg-green-100': order.status === 'อนุมัติ','bg-red-100': order.status === 'ไม่อนุมัติ'}">
              {{ order.status }}</td>
            <td class="px-6 py-4 whitespace-nowrap">
              <button @click="viewDetails(order.po_id)" class="bg-blue-500 text-white px-4 py-2 rounded">ดูรายละเอียด</button>
            </td>
          </tr>
          <tr v-if="purchaseOrders.length === 0">
            <td class="px-6 py-4 whitespace-nowrap text-center" colspan="6">ไม่พบข้อมูล</td>
          </tr>
        </tbody>
      </table>
       <!-- Pagination Buttons -->
    <div class="flex justify-center mt-4">
      <button
        class="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded"
        :disabled="currentPage === 1"
        @click="currentPage--"
      >
        Previous
      </button>
      <button
        v-for="page in totalPages"
        :key="page"
        class="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded"
        :class="{ 'bg-blue-500 text-white': page === currentPage }"
        @click="currentPage = page"
      >
        {{ page }}
      </button>
      <button
        class="bg-gray-300 text-gray-700 px-4 py-2 mx-1 rounded"
        :disabled="currentPage === totalPages"
        @click="currentPage++"
      >
        Next
      </button>
    </div>
    </div>
  </template>
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  export default {
    data() {
      return {
        purchaseOrders: [],
        currentPage: 1,
        
        itemsPerPage: 10
      };
    },
    computed: {
  paginatedOrders() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.purchaseOrders.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.purchaseOrders.length / this.itemsPerPage);
  }
},
    methods: {
        async fetchPurchaseOrders() {
      try {
        const response = await axios.get('https://brk-group.org/api/urgent_purchase_orders', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
        });
        this.purchaseOrders = response.data;
      } catch (error) {
        console.error('Error fetching purchase orders:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch purchase orders. Please try again later.'
        });
      }
    },
    viewDetails(poId) {
      this.$router.push({ name: 'POFormEdit', params: { id: poId }, query: { fromPOList: 'true' } });
    },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    async fetchUserData() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }

      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        this.agency = response.data.agency;
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' });
        }
      }
    }
  },
  created() {
    this.fetchPurchaseOrders();
    this.fetchUserData();
    const poId = this.$route.params.id;
    if (poId) {
      this.fetchPOData(poId);

    }
  }
  };
  </script>
  
  <style scoped>
  /* เพิ่มสไตล์ที่คุณต้องการที่นี่ */
  </style>
  