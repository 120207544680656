<template>
  <div class="approve-po">
    <div class="sidebar">
      <ul>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('approvalPending')">อนุมัติใบสั่งซื้อ</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('approvePurchaseRequest')">อนุมัติใบขอซื้อ</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('statusChange')">อนุมัติเปลี่ยนสถานะ</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('productImport')">อนุมัตินำเข้าสินค้า</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('productTransfer')">อนุมัติโอนย้ายสินค้า</a>
    <a href="#" class="block py-2.5 px-4 rounded hover:bg-gray-200" @click="setActiveSection('stockCheck')">อนุมัติตรวจสอบคลัง</a>
      </ul>
    </div>


    <div class="content">
      <div v-if="activeSection === 'approvalPending'">
      <h1 class="text-lg font-bold">การอนุมัติ</h1><br>
      <div class="filters">
        <input type="text" v-model="search.name" placeholder="ค้นหาชื่อ">
        <select v-model="search.status">
          <option value="">ค้นหาสถานะ</option>
          <option value="ยกเลิกใบสั่งซื้อ">ยกเลิกใบสั่งซื้อ</option>
          <option value="รอผู้จัดการฝ่ายจัดซื้อ">รอผู้จัดการฝ่ายจัดซื้อ</option>
          <option value="รอการอนุมัติ">รอการอนุมัติ</option>
          <option value="รอการสั่งซื้อ">รอการสั่งซื้อ</option>
          <option value="รอรับอะไหล่">รอรับอะไหล่</option>
          <option value="รับอะไหล่แล้ว">รับอะไหล่แล้ว</option>
        </select>
        <input type="date" v-model="search.startDate">
        <input type="date" v-model="search.endDate">
      </div>
      <table class="approval-table">
        <thead>
          <tr>
            <th>ชื่อ</th>
            <th>เลขที่ใบสั่งซื้อ</th>
            <th>วันที่แจ้งซ่อม</th>
            <th>สถานที่รับอะไหล่</th>
            <th>สถานะ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filteredItems" :key="item.po_id" @click="goToDetail(item.po_id, item.status)" :class="getStatusColorClass(item.status)">
            <td>{{ item.recorder_name }}</td>
            <td>{{ item.po_number }}</td>
            <td>{{ formatDate(item.po_date) }}</td>
            <td>{{ item.warehouse_name }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)">ย้อนกลับ</button>
        <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }">{{ page }}</button>
        <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">ถัดไป</button>
      </div>
    </div>


    <div v-if="activeSection === 'approvePurchaseRequest'">
  <h1>อนุมัติใบขอซื้อ</h1>
  <div class="filters">
    <input type="text" v-model="search.name" placeholder="ค้นหาชื่อ">
    <input type="date" v-model="search.startDate">
    <input type="date" v-model="search.endDate">
  </div>
  <table class="approval-table">
    <thead>
      <tr>
        <th>ชื่อ</th>
        <th>เลขที่ใบขอซื้อ</th>
        <th>วันที่ขอซื้อ</th>
        <th>หน่วยงาน</th>
        <th>สถานะ</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="request in filteredApprovePurchaseRequests" :key="request.pr_id" @click="goToPRDetail(request.pr_id)">
        <td>{{ request.recorder_name }}</td>
        <td>{{ request.purchase_request_number }}</td>
        <td>{{ formatDate(request.request_date) }}</td>
        <td>{{ request.warehouse_name }}</td>
        <td>{{ request.status_pr }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
    <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)">Previous</button>
    <button v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }">{{ page }}</button>
    <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">Next</button>
  </div>
  </div>



</div>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ApprovePO',
  data() {
    return {
      activeSection: 'approvalPending',
      search: {
        name: '',
        status: '',
        startDate: '',
        endDate: ''
      },
      items: [],
      approvePurchaseRequests: [], // เพิ่มส่วนนี้สำหรับการดึงข้อมูลใบขอซื้อ
      user: {},
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    filteredItems() {
    const filtered = this.items.filter(item => {
      const recorderName = item.recorder_name || ''; 
      const status = item.status || ''; 
      const poDate = item.po_date ? new Date(item.po_date) : null; 
      const startDate = this.search.startDate ? new Date(this.search.startDate) : null;
      const endDate = this.search.endDate ? new Date(this.search.endDate) : null;

      return (
        recorderName.includes(this.search.name) && 
        status.includes(this.search.status) && 
        (!startDate || (poDate && poDate >= startDate)) && 
        (!endDate || (poDate && poDate <= endDate))
      );
    });

    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = this.currentPage * this.itemsPerPage;
    return filtered.slice(start, end);
  },
    filteredApprovePurchaseRequests() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.approvePurchaseRequests.slice(start, end);
  },
  totalPages() {
    return Math.ceil(this.items.length / this.itemsPerPage);
  }
  },
  async created() {
      const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }

    try {
      const response = await axios.get('https://brk-group.org/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.user = response.data; // ตรวจสอบให้แน่ใจว่า login_id ถูกดึงมาใน user object
    } catch (error) {
      console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        // ถ้า token ไม่ถูกต้อง ให้ redirect ไปยังหน้า Login
        this.$router.push({ name: 'Login' });
      }
    }

     
      this.fetchData();
      this.fetchApprovePurchaseRequests();
    },
 
  methods: {
    getStatusColorClass(status) {
    switch (status) {
      case 'ยกเลิกใบสั่งซื้อ':
        return 'bg-red-200 text-black-100';
      case 'รอผู้จัดการฝ่ายจัดซื้อ':
        return 'bg-yellow-200 text-black-100';
      case 'รอการอนุมัติ':
        return 'bg-orange-200 text-black-100';
      case 'รอการสั่งซื้อ':
        return 'bg-blue-200 text-black-100';
      case 'รอรับอะไหล่':
        return 'bg-purple-200 text-black-100';
      case 'รับอะไหล่แล้ว':
        return 'bg-green-200 text-black-100';
      default:
        return ''; // หรือ class อื่นๆ สำหรับสถานะที่ไม่ได้ระบุ
    }
  },
    async fetchUser() {
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        this.user = response.data;
        // console.log(this.user); // ตรวจสอบข้อมูลผู้ใช้
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    goToDetail(poId, status) {
    const query = status === 'รอการอนุมัติ' ? { fromApprove: true } : {};
    this.$router.push({ name: 'PODetail', params: { poId }, query });
    },
    goToPRDetail(prId) {
    this.$router.push({ name: 'PRDetail', params: { id: prId }, query: { fromApprovePO: true } });
  },
    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = d.getFullYear();
      return `${day}/${month}/${year}`;
    },
    setActiveSection(section) {
      this.activeSection = section;
      this.fetchData();
      this.fetchApprovePurchaseRequests();
    },
    changePage(page) {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
    }
  },
    fetchData() {
      axios.get('https://brk-group.org/api/approve_purchase_orders')
        .then(response => {
          // เรียงข้อมูลตาม po_id โดยเรียงจากมากไปน้อย
          this.items = response.data.sort((a, b) => b.po_id - a.po_id);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    fetchApprovePurchaseRequests() {
    axios.get('https://brk-group.org/api/approve_purchase_requests')
      .then(response => {
        this.approvePurchaseRequests = response.data;
      })
      .catch(error => {
        console.error('Error fetching purchase requests:', error);
      });
  }
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
.approve-po {
  display: flex;
  flex-direction: column;
}

.sidebar {
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.sidebar li {
  padding: 10px;
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.sidebar li.active {
  background-color: #ddd;
}

.content {
  flex-grow: 1;
  padding: 20px;
}

.filters {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.filters input,
.filters select {
  margin-right: 10px;
  padding: 5px;
  flex: 1;
  min-width: 150px;
}

.approval-table {
  width: 100%;
  border-collapse: collapse;
}

.approval-table th, .approval-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.approval-table th {
  background-color: #f2f2f2;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination .active {
  font-weight: bold;
}

@media (min-width: 600px) {
  .approve-po {
    flex-direction: row;
  }

  .sidebar {
    width: 250px;
    flex-direction: column;
  }

  .sidebar ul {
    flex-direction: column;
  }

  .sidebar li {
    text-align: left;
  }

  .filters {
    flex-direction: row;
  }
}

.bg-red-500 { background-color: #ef4444; } /* สีแดง */
.bg-yellow-500 { background-color: #eab308; } /* สีเหลือง */
.bg-orange-500 { background-color: #f97316; } /* สีส้ม */
.bg-blue-400 { background-color: #60a5fa; } /* สีฟ้า */
.bg-purple-500 { background-color: #a855f7; } /* สีม่วง */
.bg-green-500 { background-color: #22c55e; } /* สีเขียว */
.text-white-100 { color: white; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; } /* ปรับระยะห่างด้านข้าง */
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; } /* ปรับระยะห่างด้านบนล่าง */
.rounded-md { border-radius: 0.375rem; } /* ปรับความโค้งมุม */
</style>