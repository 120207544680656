<template>
  <div class="p-4">
    <h1 class="text-3xl font-bold mb-4">อู่นอก</h1>
    <button @click="showAddModal = true" class="bg-blue-500 text-white px-4 py-2 rounded-md mb-4">เพิ่มอู่นอก</button>
    <!-- Modal Add-->
    <div v-if="showAddModal" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div class="bg-white p-6 rounded-lg w-4/5 max-w-xl max-h-screen overflow-y-auto relative">
        <button @click="closeModal" class="absolute top-0 right-0 mt-4 mr-4 z-10 text-gray-600 hover:text-gray-800 focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <h2 class="text-2xl font-bold mb-4">เพิ่มอู่นอก</h2>
        <form @submit.prevent="submitNewGarage">
        <!-- แนบไฟล์ภาพ -->
        <div class="mb-4">
          <label for="image" class="block text-gray-700 font-bold mb-2">แนบไฟล์ภาพ:</label>
          <input type="file" id="image" @change="onFileChange" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- วันที่เพิ่ม -->
        <div class="mb-4">
          <label for="dateAdded" class="block text-gray-700 font-bold mb-2">วันที่เพิ่ม:</label>
          <input type="date" v-model="newGarage.dateAdded" id="dateAdded" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- ชื่อร้าน/บริษัท -->
        <div class="mb-4">
          <label for="companyName" class="block text-gray-700 font-bold mb-2">ชื่อร้าน/บริษัท:</label>
          <input type="text" v-model="newGarage.companyName" id="companyName" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- ที่อยู่ -->
        <div class="mb-4">
          <label for="address" class="block text-gray-700 font-bold mb-2">ที่อยู่:</label>
          <textarea v-model="newGarage.address" id="address" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
        </div>
        <!-- เบอร์โทรศัพท์ร้าน -->
        <div class="mb-4">
          <label for="storePhone" class="block text-gray-700 font-bold mb-2">เบอร์โทรศัพท์ร้าน:</label>
          <input type="text" v-model="newGarage.storePhone" id="storePhone" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- เลขประจำตัวผู้เสียภาษี -->
        <div class="mb-4">
          <label for="taxID" class="block text-gray-700 font-bold mb-2">เลขประจำตัวผู้เสียภาษี:</label>
          <input type="text" v-model="newGarage.taxID" id="taxID" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- ชื่อผู้ติดต่อ -->
        <div class="mb-4">
          <label for="contactName" class="block text-gray-700 font-bold mb-2">ชื่อผู้ติดต่อ:</label>
          <input type="text" v-model="newGarage.contactName" id="contactName" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- อีเมล์ร้าน -->
        <div class="mb-4">
          <label for="email" class="block text-gray-700 font-bold mb-2">อีเมล์ร้าน:</label>
          <input type="email" v-model="newGarage.email" id="email" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- รายละเอียดสินค้าหรือบริการ -->
        <div class="mb-4">
          <label for="productDetails" class="block text-gray-700 font-bold mb-2">รายละเอียดสินค้าหรือบริการ:</label>
          <textarea v-model="newGarage.productDetails" id="productDetails" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
        </div>
        <!-- ตำแหน่ง -->
        <div class="mb-4">
          <label for="position" class="block text-gray-700 font-bold mb-2">ตำแหน่ง:</label>
          <input type="text" v-model="newGarage.position" id="position" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- ข้อมูลการชำระเงิน -->
        <div class="mb-4">
          <label for="paymentInfo" class="block text-gray-700 font-bold mb-2">ข้อมูลการชำระเงิน:</label>
          <select v-model="newGarage.paymentInfo" id="paymentInfo" class="border border-gray-400 rounded-md px-3 py-2 w-full">
          <option value="การโอนเงิน">การโอนเงิน</option>
          <option value="เงินสด">เงินสด</option>
          <option value="เช็ค">เช็ค</option>
          <option value="บัตรเครดิต">บัตรเครดิต</option>
          <option value="ทุกรูปแบบ">ทุกรูปแบบ</option>
          <option value="อื่นๆ">อื่นๆ</option>
          </select>
        </div>

        <!-- ข้อมูลสัญญาหรือข้อตกลง -->
        <div class="mb-4">
          <label for="contractInfo" class="block text-gray-700 font-bold mb-2">ข้อมูลสัญญาหรือข้อตกลง:</label>
          <textarea v-model="newGarage.contractInfo" id="contractInfo" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
        </div>
        <!-- ข้อมูลการจัดส่ง -->
        <div class="mb-4">
          <label for="deliveryInfo" class="block text-gray-700 font-bold mb-2">ข้อมูลการจัดส่ง:</label>
          <textarea v-model="newGarage.deliveryInfo" id="deliveryInfo" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
        </div>
        <!-- ข้อมูลการสนับสนุนหลังการขาย -->
        <div class="mb-4">
          <label for="aftersalesSupport" class="block text-gray-700 font-bold mb-2">ข้อมูลการสนับสนุนหลังการขาย:</label>
          <textarea v-model="newGarage.aftersalesSupport" id="aftersalesSupport" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
        </div>
        <!-- ประเภทร้าน -->
        <div class="mb-4">
          <label for="storeType" class="block text-gray-700 font-bold mb-2">ประเภทร้าน:</label>
          <input type="text" v-model="newGarage.storeType" id="storeType" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <!-- แนบเอกสารเพิ่มเติม -->
        <div class="mb-4">
          <label for="additionalDocuments" class="block text-gray-700 font-bold mb-2">แนบเอกสารเพิ่มเติม:</label>
          <input type="file" id="additionalDocuments" @change="onAdditionalFileChange" class="border border-gray-400 rounded-md px-3 py-2 w-full">
        </div>
        <div class="flex justify-end">
          <button type="submit" class="bg-green-500 text-white px-4 py-2 rounded-md mr-2">บันทึก</button>
          <button @click="closeModal" class="bg-gray-500 text-white px-4 py-2 rounded-md">ยกเลิก</button>
        </div>
      </form>
        <br><br> 
      </div>
    </div>
 <!-- Modal Edit-->
    <div v-if="showEditModal" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
    <div class="bg-white p-6 rounded-lg w-4/5 max-w-xl max-h-screen overflow-y-auto relative">
      <button @click="closeEditModal" class="absolute top-0 right-0 mt-4 mr-4 z-10 text-gray-600 hover:text-gray-800 focus:outline-none">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
      <h2 class="text-2xl font-bold mb-4">แก้ไขอู่นอก</h2>
      <form @submit.prevent="submitEditedGarage">
  <!-- แนบไฟล์ภาพ -->
  <div class="mb-4">
    <label for="editImage" class="block text-gray-700 font-bold mb-2">แนบไฟล์ภาพ:</label>
    <input type="file" id="editImage" @change="onEditFileChange" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- วันที่เพิ่ม -->
  <div class="mb-4">
    <label for="editDateAdded" class="block text-gray-700 font-bold mb-2">วันที่เพิ่ม:</label>
    <input type="date" v-model="editedGarage.date_added" id="editDateAdded" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- ชื่อร้าน/บริษัท -->
  <div class="mb-4">
    <label for="editCompanyName" class="block text-gray-700 font-bold mb-2">ชื่อร้าน/บริษัท:</label>
    <input type="text" v-model="editedGarage.company_name" id="editCompanyName" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- ที่อยู่ -->
  <div class="mb-4">
    <label for="editAddress" class="block text-gray-700 font-bold mb-2">ที่อยู่:</label>
    <textarea v-model="editedGarage.address" id="editAddress" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
  </div>
  <!-- เบอร์โทรศัพท์ร้าน -->
  <div class="mb-4">
    <label for="editStorePhone" class="block text-gray-700 font-bold mb-2">เบอร์โทรศัพท์ร้าน:</label>
    <input type="text" v-model="editedGarage.store_phone" id="editStorePhone" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- เลขประจำตัวผู้เสียภาษี -->
  <div class="mb-4">
    <label for="editTaxID" class="block text-gray-700 font-bold mb-2">เลขประจำตัวผู้เสียภาษี:</label>
    <input type="text" v-model="editedGarage.tax_id" id="editTaxID" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- ชื่อผู้ติดต่อ -->
  <div class="mb-4">
    <label for="editContactName" class="block text-gray-700 font-bold mb-2">ชื่อผู้ติดต่อ:</label>
    <input type="text" v-model="editedGarage.contact_name" id="editContactName" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- อีเมล์ร้าน -->
  <div class="mb-4">
    <label for="editEmail" class="block text-gray-700 font-bold mb-2">อีเมล์ร้าน:</label>
    <input type="email" v-model="editedGarage.email" id="editEmail" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- รายละเอียดสินค้าหรือบริการ -->
  <div class="mb-4">
    <label for="editProductDetails" class="block text-gray-700 font-bold mb-2">รายละเอียดสินค้าหรือบริการ:</label>
    <textarea v-model="editedGarage.product_details" id="editProductDetails" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
  </div>
  <!-- ตำแหน่ง -->
  <div class="mb-4">
    <label for="editPosition" class="block text-gray-700 font-bold mb-2">ตำแหน่ง:</label>
    <input type="text" v-model="editedGarage.position" id="editPosition" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- ข้อมูลการชำระเงิน -->
  <div class="mb-4">
  <label for="editPaymentInfo" class="block text-gray-700 font-bold mb-2">ข้อมูลการชำระเงิน:</label>
  <select v-model="editedGarage.payment_info" id="editPaymentInfo" class="border border-gray-400 rounded-md px-3 py-2 w-full">
    <option value="การโอนเงิน">การโอนเงิน</option>
    <option value="เงินสด">เงินสด</option>
    <option value="เช็ค">เช็ค</option>
    <option value="บัตรเครดิต">บัตรเครดิต</option>
    <option value="ทุกรูปแบบ">ทุกรูปแบบ</option>
    <option value="อื่นๆ">อื่นๆ</option>
  </select>
</div>

  <!-- ข้อมูลสัญญาหรือข้อตกลง -->
  <div class="mb-4">
    <label for="editContractInfo" class="block text-gray-700 font-bold mb-2">ข้อมูลสัญญาหรือข้อตกลง:</label>
    <textarea v-model="editedGarage.contract_info" id="editContractInfo" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
  </div>
  <!-- ข้อมูลการจัดส่ง -->
  <div class="mb-4">
    <label for="editDeliveryInfo" class="block text-gray-700 font-bold mb-2">ข้อมูลการจัดส่ง:</label>
    <textarea v-model="editedGarage.delivery_info" id="editDeliveryInfo" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
  </div>
  <!-- ข้อมูลการสนับสนุนหลังการขาย -->
  <div class="mb-4">
    <label for="editAftersalesSupport" class="block text-gray-700 font-bold mb-2">ข้อมูลการสนับสนุนหลังการขาย:</label>
    <textarea v-model="editedGarage.aftersales_support" id="editAftersalesSupport" class="border border-gray-400 rounded-md px-3 py-2 w-full"></textarea>
  </div>
  <!-- ประเภทร้าน -->
  <div class="mb-4">
    <label for="editStoreType" class="block text-gray-700 font-bold mb-2">ประเภทร้าน:</label>
    <input type="text" v-model="editedGarage.store_type" id="editStoreType" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <!-- แนบเอกสารเพิ่มเติม -->
  <div class="mb-4">
    <label for="editAdditionalDocuments" class="block text-gray-700 font-bold mb-2">แนบเอกสารเพิ่มเติม:</label>
    <input type="file" id="editAdditionalDocuments" @change="onEditAdditionalFileChange" class="border border-gray-400 rounded-md px-3 py-2 w-full">
  </div>
  <div class="flex justify-end">
    <button type="submit" class="bg-green-500 text-white px-4 py-2 rounded-md mr-2">บันทึก</button>
    <button @click="closeEditModal" class="bg-gray-500 text-white px-4 py-2 rounded-md">ยกเลิก</button>
  </div>
  <br><br>
</form>
    </div>
  </div>

  <table class="w-full text-sm text-gray-800 bg-white">
    <thead class="text-xs text-gray-800 uppercase bg-gray-100">
      <tr>
   
        <th scope="col" class="py-3 px-6">รูปภาพ</th>
        <th scope="col" class="py-3 px-6">รายชื่อคู่ค้า</th>
        <th scope="col" class="py-3 px-6">เลขประจำตัวผู้เสียภาษี</th>
        <th scope="col" class="py-3 px-6">เบอร์โทร</th>
        <th scope="col" class="py-3 px-6">ประเภทร้าน</th>
        <th scope="col" class="py-3 px-6">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="garage in garages" :key="garage.id" class="border-b border-gray-300 hover:bg-gray-50">
        <td class="py-4 px-6" v-if="showIdColumn">{{ garage.id }}</td>

        <td class="py-4 px-6">
          <img :src="garage.image_path" alt="Garage Image" class="w-20 h-auto rounded-full">
        </td>
        <td class="py-4 px-6">{{ garage.company_name }}</td>
        <td class="py-4 px-6">{{ garage.tax_id }}</td>
        <td class="py-4 px-6">{{ garage.store_phone }}</td>
        <td class="py-4 px-6">{{ garage.store_type }}</td>
        <td class="py-4 px-6">
          <button @click="editGarage(garage.id)" class="text-white bg-yellow-500 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:focus:ring-yellow-900">แก้ไข</button>
           <button @click="deleteGarage(garage.id)" class="text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:focus:ring-red-900">ลบ</button>
        </td>
      </tr>
    </tbody>
  </table>

  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'GarageOutView',
  data() {
    return {
      showAddModal: false,
      showEditModal: false,
      newGarage: {
        dateAdded: '',
        companyName: '',
        address: '',
        storePhone: '',
        taxID: '',
        contactName: '',
        email: '',
        productDetails: '',
        position: '',
        paymentInfo: '',
        contractInfo: '',
        deliveryInfo: '',
        aftersalesSupport: '',
        storeType: ''
      },
      file: null,
      additionalFile: null,
      garages: [],
      showIdColumn: false,
      editedGarage: {
        dateAdded: '',
        companyName: '',
        address: '',
        storePhone: '',
        taxID: '',
        contactName: '',
        email: '',
        productDetails: '',
        position: '',
        paymentInfo: '',
        contractInfo: '',
        deliveryInfo: '',
        aftersalesSupport: '',
        storeType: ''
      },
    }
  },
  created() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
    }
    
    this.fetchGarages();
  },
  methods: {
    async editGarage(id) {
      try {
      const response = await axios.get(`https://brk-group.org/garages/${id}`);
      if (response.data) {
        this.editedGarage = {
          ...response.data,
          date_added: this.formatDate(response.data.date_added)
        };
        this.showEditModal = true;
      } else {
        alert('Garage not found');
      }
    } catch (error) {
      console.error('Error fetching garage details:', error);
      alert('Failed to load garage details for editing');
    }
  },
  formatDate(date) {
  if (!date) return null;
  const d = new Date(date);
  return d.toISOString().split('T')[0];  // แปลงวันที่เป็น ISO string และตัดเวลาออก
},
    closeEditModal() {
      this.showEditModal = false;
    },
    openEditModal(garage) {
      this.editedGarage = { ...garage };
      this.showEditModal = true;
    },
    async submitEditedGarage() {
      let formData = new FormData();
      // Append all editable fields and files
      formData.append('image', this.editedGarage.image);
      formData.append('additionalDocuments', this.editedGarage.additionalDocuments);
      // Append other editable properties, ensure they are defined in editedGarage
      Object.keys(this.editedGarage).forEach(key => {
        if (key !== 'image' && key !== 'additionalDocuments') {
          formData.append(key, this.editedGarage[key]);
        }
      });

      try {
        const response = await axios.put(`https://brk-group.org/update-garage/${this.editedGarage.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.status === 200) {
          alert('อู่นอกถูกแก้ไขเรียบร้อยแล้ว!');
          this.closeEditModal();
          this.fetchGarages(); // Refresh the list of garages
        } else {
          alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('เกิดข้อผิดพลาดในการส่งข้อมูล');
      }
    },
    
    closeModal() {
      this.showAddModal = false;
    },
    onFileChange(e) {
      this.file = e.target.files[0];
    },
    onAdditionalFileChange(e) {
      this.additionalFile = e.target.files[0];
    },
    async fetchGarages() {
      try {
        const response = await axios.get('https://brk-group.org/garages');
        this.garages = response.data;
      } catch (error) {
        console.error('Error fetching garages:', error);
      }
    },
    async submitNewGarage() {
      let formData = new FormData();
      formData.append('image', this.file);
      if (this.additionalFile) {
        formData.append('additionalDocuments', this.additionalFile);
      }
      Object.keys(this.newGarage).forEach(key => {
        formData.append(key, this.newGarage[key]);
      });

      try {
        const response = await axios.post('https://brk-group.org/garages', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.status === 201) {
          alert('อู่นอกถูกเพิ่มเรียบร้อยแล้ว!');
          this.closeModal();
          this.fetchGarages();
        } else {
          alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('เกิดข้อผิดพลาดในการส่งข้อมูล');
      }
    },
    deleteGarage(id) {
    if (!id) {
      alert(' ID is missing!');
      return;
    }
    axios.delete(`https://brk-group.org/garages/${id}`)
      .then(() => {
        alert('Garage deleted successfully!');
        this.fetchGarages();  // Refresh the list after deletion
      })
      .catch(error => {
        console.error('Error deleting garage:', error);
        alert('Failed to delete garage');
      });
      },
  }
}
</script>
<style>
td.py-4.px-6:nth-child(0) {
  display: none;
}
</style>