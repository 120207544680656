<template>
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col mt-8">
      <div class="py-2">
        <div class="flex justify-between mb-4 items-center">
          <h2 class="text-2xl font-semibold leading-tight">สร้างใบขอซื้อ (Purchase Requisition: PR)</h2>
          <div class="flex space-x-4">
            <!-- <button @click="setPurchaseType('repair')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
             ซื้อเพื่อซ่อม
            </button>
            <button @click="setPurchaseType('stock')" class="bg-purple-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
              ซื้อเพื่อสต็อค
            </button> -->
            <!-- <button @click="exportData" class="bg-blue-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
              Export
            </button> -->
            <button v-if="form.purchaseType === 'repair'" @click="saveForm()" :disabled="loading" class="bg-green-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 relative">
  <span v-if="loading" ></span>
  <span v-else>บันทึก</span>
</button>
<button v-if="form.purchaseType === 'stock'" @click="saveStockForm()" :disabled="loading" class="bg-blue-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 relative">
  <span v-if="loading" ></span>
  <span v-else>บันทึกเพื่อสต็อค</span>
</button>
          </div>
        </div>
       <!-- Added/Updated Code -->
       <div class="bg-white shadow mb-4 p-4">
  <div class="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
    <div class="col-span-1 lg:col-span-1">
      <h1>วันที่ขอใบสั่งซื้อ</h1>
      <input v-model="form.requestDate" type="date" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="วันที่ขอใบสั่งซื้อ">
    </div>
    <div class="col-span-1 lg:col-span-1">
      <input v-model="form.creatorName" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full bg-gray-100 cursor-not-allowed" placeholder="ชื่อผู้สร้างใบขอซื้อ" readonly>
    </div>
    <div class="col-span-1 lg:col-span-1">
      <input v-model="form.purchaseRequestNumber" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full bg-gray-100 cursor-not-allowed" placeholder="เลขที่ใบขอซื้อ" readonly>
    </div>
    <div class="col-span-1 lg:col-span-1">
      <input v-model="form.departmentName" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full bg-gray-100 cursor-not-allowed" placeholder="หน่วยงาน" readonly>
    </div>
    <div class="col-span-1 lg:col-span-1">
      <input @click="showWarehouseModal = true" v-model="selectedWarehouse" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาสถานที่รับอะไหล่" readonly>
    </div>

    <!-- <div class="col-span-1 lg:col-span-1">
      <input v-model="form.creditTerms" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="จำนวนเครดิต (วัน)">
    </div> -->
    <!-- <div class="col-span-1 lg:col-span-1">
      <h2>กำหนดส่งของ</h2>
      <input v-model="form.deliveryDueDate" type="date" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กำหนดส่งของ">
    </div> -->

    <div v-if="form.purchaseType === 'repair'" class="col-span-1 lg:col-span-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
      <input @click="showVehicleModal = true" v-model="selectedVehicle" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full bg-gray-100 cursor-not-allowed" placeholder="ค้นหาทะเบียนรถ" readonly>
        <!-- ช่องเลือกหน่วยงาน -->
        <div>
          <label class="block text-gray-700 font-bold"><span class="text-red-500">* </span>หน่วยงานรถ <span class="text-red-500">*</span></label>
    <input @click="showDepartmentModal = true" v-model="selectedDepartmentName" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full bg-gray-100 cursor-not-allowed" placeholder="เลือกหน่วยงาน" readonly>
        </div>
    <div>
  <label class="block text-gray-700">รหัสรถ</label>
  <input v-model="form.vehicleCode" :readonly="!form.isExternalVehicle" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="รหัสรถ">
</div>

      <!-- ช่องกรอกรุ่น -->
<div>
  <label class="block text-gray-700">รุ่น</label>
  <input v-model="form.vehicleModel" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="รุ่น">
</div>

      

      <!-- ช่องเลือกเวอร์ชั่นรถ -->
<div>
  <label class="block text-gray-700">เวอร์ชั่นรถ</label>
  <select v-model="form.vehicleVersion" class="form-input rounded-md shadow-sm mt-1 block w-full">
    <option disabled value="">เลือกเวอร์ชั่นรถ</option>
    <option v-for="version in vehicleVersions" :key="version" :value="version">
      {{ version }}
    </option>
  </select>
</div>



     <!-- ช่องกรอกเลขคัสซีรถ -->
<div>
  <label class="block text-gray-700">เลขคัสซีรถ</label>
  <input v-model="form.vehicleChassisNumber" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="เลขคัสซีรถ">
</div>

      <!-- ช่องกรอกเลขไมค์รถ -->
<div>
  <label class="block text-gray-700">เลขไมค์รถ</label>
  <input v-model="form.vehicleMileage" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="เลขไมค์รถ">
</div>
      <!-- ช่องกรอกยี่ห้อ -->
<div>
  <label class="block text-gray-700">ยี่ห้อ</label>
  <input v-model="selectedBrand" :readonly="!form.isExternalVehicle" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ยี่ห้อ">
</div>

    <h2 class="bg-red-100 font-bold">
      แนบใบแจ้งซ่อม
      <input type="file" @change="handleFileUpload" class="form-input rounded-md shadow-sm mt-1 block w-full">
    </h2>
    <h2 class="bg-red-100 font-bold">
      แนบใบประวัติ 
      <input type="file" @change="handleFileUpload2" class="form-input rounded-md shadow-sm mt-1 block w-full">
    </h2>
    
   </div>


    <div class="col-span-1 lg:col-span-1">
      <input v-model="form.details" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="รายละเอียด">
    </div>




  </div>
</div>



    
        <div class="bg-white shadow mb-4 p-4">
          <h3 class="text-xl font-bold text-gray-700">รายการสินค้า</h3>
          <div class="flex justify-end space-x-2 mb-4" v-if="itemCount < 10">
            <button @click="setPurchaseType('repair')" class="bg-yellow-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
             ซื้อเพื่อซ่อม
            </button>
            <button @click="setPurchaseType('stock')" class="bg-purple-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
              ซื้อเพื่อสต็อค
            </button>
            <button @click="addItem" class="bg-green-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
              + เพิ่มรายการ
            </button>
          </div>
          <div class="item-count-label font-bold text-left bg-gray-100">
               จำนวนรายการ: {{ itemCount }}
          </div>
          <div class="overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รหัสสินค้า</th>
                  <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">รายการสินค้า</th>
                  <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ประเภทสินค้า</th>
                  <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวน</th>
                  <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">หน่วยนับ</th>
                  <!-- <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ราคาต่อหน่วย</th> -->
                  <!-- <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">จำนวนเงิน</th> -->
                  <!-- <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">สถานะ</th> -->
                  <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">แนบรูปภาพ</th>
                  <th class="px-6 py-3 text-center text-base font-bold text-black-500 uppercase tracking-wider">ลบ</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(item, index) in form.items" :key="index">
                  <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium text-black-900">
                    <input v-model="item.productCode" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="รหัสสินค้า" readonly>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
                    <input v-model="item.productName" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full lg:w-32" placeholder="รายการสินค้า" readonly>
                  </td>
                  <td class="px-4 py-4 whitespace-no-wrap text-sm text-black-500">
                    <input v-model="item.productType" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ประเภทสินค้า" readonly>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
  <input v-model="item.quantity" @input="validateQuantity(item)" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-red-50" placeholder="จำนวน" min="1">
</td>

                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
                    <input v-model="item.unit" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full lg:w-8" placeholder="หน่วยนับ" readonly>
                  </td>
                  <!-- <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
                    <input v-model="item.unitPrice" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-red-50" placeholder="ราคาต่อหน่วย" @input="calculateItemTotal(item)">
                  </td> -->
                  <!-- <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
                    <input v-model="item.totalPrice" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" placeholder="จำนวนเงิน" @input="calculateAmounts" readonly>
                  </td> -->
                  <!-- <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
                    <input v-model="item.status" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="สถานะ">
                  </td> -->
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-black-500">
              <input type="file" @change="handleItemFileUpload($event, index)" class="form-input rounded-md shadow-sm mt-1 block w-full">
            </td>

                  <td class="px-6 py-4 whitespace-no-wrap text-sm font-bold">
                    <button @click="removeItem(index)" class="text-red-600 hover:text-red-900">ลบ</button>
                  </td>
                </tr>
                <tr v-if="form.items.length === 0">
                  <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="9">ไม่พบข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>      
       <!-- ส่วนนี้สำหรับรายการ PR ที่สร้างสำเร็จ -->
  <div class="bg-white shadow mb-4 p-4">
    <h3 class="text-xl font-bold text-gray-700">รายการขอเปิดใบขอซื้อ (PR)</h3>
    <div class="mt-4">
      <input v-model="searchPRQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหา PR">
    </div>
    <div class="overflow-x-auto mt-4">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ผู้สร้าง</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลขที่เอกสาร</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">วันที่เสนอ</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">หน่วยงาน</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">จำนวนรายการ</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">สถานะ</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เพิ่มเติม</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="pr in paginatedPRs" :key="pr.id">
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-900">{{ pr.creatorName }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-900">{{ pr.documentNumber }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-900">{{ pr.requestDate }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-900">{{ pr.group_name }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-900">{{ pr.itemCount }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-900">{{ pr.status }}</td>
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-900">...</td>
          </tr>
          <tr v-if="filteredPRs.length === 0">
            <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="7">ไม่พบข้อมูล</td>
          </tr>
        </tbody>
      </table>
      <div class="flex justify-between mt-4">
        <button @click="prevPRPage" :disabled="currentPRPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
        <span>หน้าที่ {{ currentPRPage }} จาก {{ totalPRPages }}</span>
        <button @click="nextPRPage" :disabled="currentPRPage === totalPRPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
      </div>
        </div>
      </div>

 <!-- Modal สำหรับเลือกหน่วยงาน -->
 <div v-if="showDepartmentModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full h-4/5">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกหน่วยงาน</h3>
                <div class="mt-2">
                  <input v-model="searchDepartmentQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาหน่วยงาน">
                  <ul class="mt-4">
                    <li v-for="department in filteredDepartments" :key="department.group_id" @click="selectDepartment(department)" class="cursor-pointer p-2 hover:bg-gray-200">{{ department.group_name }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showDepartmentModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>



    <!-- Modal สำหรับเลือกทะเบียนรถ -->
    <div v-if="showVehicleModal" class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full h-4/5">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกทะเบียนรถ</h3>
              <div class="mt-2">
                <!-- <div class="flex items-center">
                  <input type="checkbox" v-model="isExternalVehicleSearch" id="externalVehicleSearchCheckbox">
                  <label for="externalVehicleSearchCheckbox" class="text-gray-700 ml-2">ค้นหารถนอกหน่วยงาน</label>
                </div>c -->
                <input v-model="searchVehicleQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาทะเบียนรถ">
                <ul class="mt-4">
                  <li v-for="vehicle in paginatedVehicles" :key="vehicle.VehicleID" @click="selectVehicle(vehicle)" class="cursor-pointer p-2 hover:bg-gray-200">{{ vehicle.LicensePlate }}</li>
                </ul>
                <div class="flex justify-between mt-4">
                  <button @click="prevVehiclePage" :disabled="currentVehiclePage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                  <span>หน้าที่ {{ currentVehiclePage }} จาก {{ totalVehiclePages }}</span>
                  <button @click="nextVehiclePage" :disabled="currentVehiclePage === totalVehiclePages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
                </div>
                <!-- <div class="mt-4">
                  <input type="checkbox" v-model="form.isExternalVehicle" id="externalVehicleCheckbox">
                  <label for="externalVehicleCheckbox" class="text-gray-700 ml-2">รถนอกหน่วยงาน</label>
                  <div v-if="form.isExternalVehicle" class="mt-4">
                    <label class="block text-gray-700">ทะเบียนรถใหม่</label>
                    <input v-model="newVehicleReg" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรอกทะเบียนรถใหม่">
                    <button @click="saveExternalVehicle" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                      บันทึก
                    </button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="showVehicleModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            ปิด
          </button>
        </div>
      </div>
    </div>
  </div>


  <div v-if="loading" class="loading-overlay">
    <div class="spinner"></div>
  </div>
<!-- 
<div v-if="showVehicleModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl w-full h-4/5">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900">เลือกทะเบียนรถ</h3>
            <div class="mt-2">
              <input v-model="searchVehicleQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาทะเบียนรถ">
              <ul class="mt-4">
                <li v-for="vehicle in paginatedVehicles" :key="vehicle.VehicleID" @click="selectVehicle(vehicle)" class="cursor-pointer p-2 hover:bg-gray-200">{{ vehicle.LicensePlate }}</li>
              </ul>
              <div class="flex justify-between mt-4">
                <button @click="prevVehiclePage" :disabled="currentVehiclePage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                <span>หน้าที่ {{ currentVehiclePage }} จาก {{ totalVehiclePages }}</span>
                <button @click="nextVehiclePage" :disabled="currentVehiclePage === totalVehiclePages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
              </div>
              <div class="mt-4">
                <input type="checkbox" v-model="form.isExternalVehicle" id="externalVehicleCheckbox">
                <label for="externalVehicleCheckbox" class="text-gray-700 ml-2">รถนอกหน่วยงาน</label>
                <div v-if="form.isExternalVehicle" class="mt-4">
      <label class="block text-gray-700">ทะเบียนรถใหม่</label>
      <input v-model="newVehicleReg" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="กรอกทะเบียนรถใหม่">
      <button @click="saveExternalVehicle" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
        บันทึก
      </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="showVehicleModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          ปิด
        </button>
      </div>
    </div>
  </div>
</div> -->
<!-- Modal สำหรับเลือกสถานที่รับอะไหล่ -->
<!-- <div v-if="showWarehouseModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full h-4/5">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              เลือกสถานที่รับอะไหล่
            </h3>
            <div class="mt-2">
              <input v-model="searchWarehouseQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาสถานที่รับอะไหล่">
              <ul class="mt-4">
                <li v-for="warehouse in paginatedWarehouses" :key="warehouse.warehouse_id" @click="selectWarehouse(warehouse)" class="cursor-pointer p-2 hover:bg-gray-200">
                  <div class="flex items-center">
                    <img :src="warehouse.image_url" alt="Warehouse Image" class="inline-block h-10 w-10 rounded-full mr-3">
                    <div>
                      <div class="text-sm font-medium text-gray-900">{{ warehouse.name }}</div>
                      <div class="text-sm text-gray-500">{{ warehouse.group_name }}</div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="flex justify-between mt-4">
                <button @click="prevWarehousePage" :disabled="currentWarehousePage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                <span>หน้าที่ {{ currentWarehousePage }} จาก {{ totalWarehousePages }}</span>
                <button @click="nextWarehousePage" :disabled="currentWarehousePage === totalWarehousePages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="showWarehouseModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          ปิด
        </button>
      </div>
    </div>
  </div>
</div> -->

  <!-- Modal สำหรับเลือกสถานที่รับอะไหล่ -->
  <div v-if="showWarehouseModal" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full h-4/5">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  เลือกสถานที่รับอะไหล่
                </h3>
                <div class="mt-2">
                  <input type="checkbox" v-model="isExternalSupplierSearch" id="externalSupplierCheckbox">
                  <label for="externalSupplierCheckbox" class="text-gray-700 ml-2">อู่นอก</label>
                  <input v-model="searchWarehouseQuery" type="text" class="form-input rounded-md shadow-sm mt-1 block w-full" placeholder="ค้นหาสถานที่รับอะไหล่">
                  <ul class="mt-4">
                    <li v-for="warehouse in paginatedWarehouses" :key="warehouse.warehouse_id || warehouse.id" @click="selectWarehouse(warehouse)" class="cursor-pointer p-2 hover:bg-gray-200">
                      <div class="flex items-center">
                        <img :src="transformImageUrl(warehouse.image_url)" alt="Warehouse Image" class="inline-block h-10 w-10 rounded-full mr-3">
                        <div>
                          <div class="text-sm font-medium text-gray-900">{{ warehouse.name || warehouse.company_name }}</div>
                          <div class="text-sm text-gray-500">{{ warehouse.group_name || warehouse.address }}</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="flex justify-between mt-4">
                    <button @click="prevWarehousePage" :disabled="currentWarehousePage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                    <span>หน้าที่ {{ currentWarehousePage }} จาก {{ totalWarehousePages }}</span>
                    <button @click="nextWarehousePage" :disabled="currentWarehousePage === totalWarehousePages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showWarehouseModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              ปิด
            </button>
          </div>
        </div>
      </div>
    </div>


<!-- Modal สำหรับเลือกสินค้า -->
<div v-if="showProductModal" class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity" aria-hidden="true">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-full w-full h-screen sm:h-4/5 max-h-full">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 h-full overflow-y-auto">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
              เลือกสินค้า
            </h3>
            <div class="mt-4">
  <div class="flex flex-row space-x-4 mb-4">
    <input 
      v-model="productSearchQuery" 
      @input="fetchProducts" 
      type="text" 
      class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
      placeholder="ค้นหาจากชื่อสินค้า">
    <input 
      v-model="productTypeSearchQuery" 
      @input="fetchProducts" 
      type="text" 
      class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
      placeholder="ค้นหาจากประเภทสินค้า">
    <input 
      v-model="productBrandSearchQuery" 
      @input="fetchProducts" 
      type="text" 
      class="form-input rounded-md shadow-sm mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50" 
      placeholder="ค้นหาจากยี่ห้อ">
  </div>

              <table class="min-w-full divide-y divide-gray-200 mt-4">
                <thead>
                  <tr>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ชื่อสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">รหัสสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทสินค้า</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ยี่ห้อ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทพาหนะ</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ประเภทอะไหล่</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ราคา/หน่วย</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">เลือก</th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="product in paginatedFilteredProducts" :key="product.product_id">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium text-gray-900">{{ product.product_name }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_code }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.product_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.brand }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.vehicle_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.part_type }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500">{{ product.price_per_unit }}</td>
                    <td class="px-6 py-4 whitespace-no-wrap text-sm font-medium">
                      <button 
                        @click="selectProduct(product)" 
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        เลือก
                      </button>
                    </td>
                  </tr>
                  <tr v-if="paginatedFilteredProducts.length === 0">
                    <td class="px-6 py-4 whitespace-no-wrap text-sm text-gray-500" colspan="8">ไม่พบข้อมูล</td>
                  </tr>
                </tbody>
              </table>
              <div class="mt-4 flex justify-between">
                <button @click="prevProductPage" :disabled="currentProductPage === 1" class="px-4 py-2 bg-gray-300 rounded-md">ก่อนหน้า</button>
                <span>หน้าที่ {{ currentProductPage }} จาก {{ totalProductPages }}</span>
                <button @click="nextProductPage" :disabled="currentProductPage === totalProductPages" class="px-4 py-2 bg-gray-300 rounded-md">ถัดไป</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button @click="showProductModal = false" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          ปิด
        </button>
      </div>
    </div>
  </div>
  </div>
</div>
</div>
</div>
</template>
 <!-- <div class="bg-white shadow mb-4 p-4">
          <div class="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div>
              <label for="totalAmount" class="block text-sm font-medium text-gray-700">รวมเงินทั้งสิ้น</label>
              <input v-model="form.totalAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="totalAmount" @input="calculateAmounts">
            </div>
            <div>
              <label for="discountPercent" class="block text-sm font-medium text-gray-700">ส่วนลด (%)</label>
              <input v-model="form.discountPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-yellow-50" id="discountPercent" @input="calculateAmounts">
            </div>
            <div>
              <label for="amountAfterDiscount" class="block text-sm font-medium text-gray-700">ราคาหลังหักส่วนลด</label>
              <input v-model="form.amountAfterDiscount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-orange-50" id="amountAfterDiscount" readonly>
            </div>
            <div>
              <label for="vatPercent" class="block text-sm font-medium text-gray-700">ภาษีมูลค่าเพิ่ม (%)</label>
              <input v-model="form.vatPercent" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" id="vatPercent" @input="calculateAmounts">
            </div>
            <div>
              <label for="vatAmount" class="block text-sm font-medium text-gray-700">คิดเป็นจำนวนเงิน</label>
              <input v-model="form.vatAmount" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full" id="vatAmount" readonly>
            </div>
            <div>
              <label for="grandTotal" class="block text-sm font-medium text-gray-700">เป็นเงินทั้งสิ้น</label>
              <input v-model="form.grandTotal" type="number" class="form-input rounded-md shadow-sm mt-1 block w-full bg-green-50" id="grandTotal" readonly>
            </div>
          </div>
        </div> -->
<script>
import axios from 'axios';
import Swal from 'sweetalert2';
// import socket from '../../services/socket'; 
// import io from 'socket.io-client';
export default {
  data() {
    return {
      isExternalVehicleSearch: false,
 
      form: {
      requestDate: '',
      creatorId: '',
      creatorName: '',
      purchaseRequestNumber: '',
      department: '',
      departmentId: '',
      departmentName: '',
      
      details: '',

      isExternalVehicle: false,
     // creditTerms: '',
      deliveryDueDate: '',
      totalAmount: 0.00,
      discount: 0.00,
      discountPercent: 0,
      amountAfterDiscount: 0.00,
      vatPercent: 0,
      vatAmount: 0.00,
      grandTotal: 0.00,
      status_pr: 'รอตรวจสอบ',
      purchaseType: '',
      VehicleID: '',
      warehouse_id: null,
      garages_id: null,
      items: [],
      vehicleModel: '',
      vehicleCode: '',
      vehicleVersion: '',
      vehicleChassisNumber: '',
      vehicleMileage: '',
      files: []
    },
    loading: false, // สถานะ loading เริ่มต้นเป็น false
    suppliers: [],
    showSupplierModal: false,
    showProductModal: false,
    productSearchQuery: '',
    currentWarehousePage: 1,
      productTypeSearchQuery: '',
      productBrandSearchQuery: '',
    products: [],
    searchQuery: '',
    currentPage: 1,
    itemsPerPage: 10,
    currentProductPage: 1,
    productsPerPage: 10,
    vehicles: [],
    warehouses: [],

    isExternalSupplier: false, // ใช้สำหรับตรวจสอบว่าติ๊กเลือกอู่นอกหรือไม่
    isExternalSupplierSearch: false,
    showVehicleModal: false,
    showWarehouseModal: false,
    selectedVehicle: '',
    selectedBrand: '',
    selectedWarehouse: '',
    searchVehicleQuery: '',
    searchWarehouseQuery: '',
    currentVehiclePage: 1,
    prs: [],
    prItems: [],
    departments: [],
    searchPRQuery: '',
    currentPRPage: 1,
    prsPerPage: 5,
    file: null,
    files: [],
    files2: null, // Add this line
    user: { group_id: null, },
    vehicleVersions: ['V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'V9', 'V10', 'V11', 'V12', 'V13', 'V14', 'V15'],
    selectedDepartmentName: '', // เก็บชื่อหน่วยงานที่เลือก
    showDepartmentModal: false,
    searchDepartmentQuery: '',
    isExternalVehicle: false,
    newVehicleReg: '',
    };
  },
  watch: {
 
 
    searchVehicleQuery() {
      this.filterVehicles();
    },
    isExternalVehicleSearch() {
      this.filterVehicles();
    },
    searchWarehouseQuery() {
      this.fetchWarehouses();
    },
    isExternalSupplierSearch() {
      this.fetchWarehouses();
    }
  },
  mounted() {
    this.fetchWarehouses();
  },
  async created() {
    const token = localStorage.getItem('userToken');
    if (!token) {
      this.$router.push({ name: 'Login' });
      return;
    }
    // this.socket = io('https://brk-group.org');
    // this.socket.on('purchase_request_saved', (data) => {
    //   console.log('Received purchase_request_saved event:', data);
    //   if (data.purchaseRequestNumber !== this.form.purchaseRequestNumber) {
    //     this.generatePurchaseRequestNumber();
    //   }
    // });
    try {
      const response = await axios.get('https://brk-group.org/api/user', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      this.user = response.data; // ตรวจสอบให้แน่ใจว่า login_id ถูกดึงมาใน user object
    } catch (error) {
      // console.error('Error fetching user data:', error);
      if (error.response && error.response.status === 401) {
        // ถ้า token ไม่ถูกต้อง ให้ redirect ไปยังหน้า Login
        this.$router.push({ name: 'Login' });
      }
    }
    this.populateUserData();

    this.fetchProducts();
    await this.fetchDepartments();
    await this.fetchUser();
    await this.populateUserData();
    await this.generatePurchaseRequestNumber();
   await this.fetchSuppliers();
    await this.fetchVehicles();
    await this.fetchWarehouses();
    await this.filterVehicles();
    await this.fetchPRs(); // เรียกใช้งานการดึงข้อมูล PR
  },

  computed: {
    filteredDepartments() {
      return this.departments.filter(department => 
        department.group_name.includes(this.searchDepartmentQuery)
      );
    },
    filteredProducts() {
      return this.products.filter(product => {
        return (
          product.product_name.toLowerCase().includes(this.productSearchQuery.toLowerCase()) &&
          product.product_type.toLowerCase().includes(this.productTypeSearchQuery.toLowerCase()) &&
          product.brand.toLowerCase().includes(this.productBrandSearchQuery.toLowerCase())
        );
      });
    },
    hasItems() {
      return this.form.items.length > 0;
    },
    itemCount() {
      return this.form.items.length;
    },
    paginatedSuppliers() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.suppliers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.suppliers.length / this.itemsPerPage);
    },
    paginatedFilteredProducts() {
      const start = (this.currentProductPage - 1) * this.productsPerPage;
      const end = start + this.productsPerPage;
      return this.filteredProducts.slice(start, end);
    },
    paginatedProducts() {
    if (!this.products) return [];
    const start = (this.currentProductPage - 1) * this.productsPerPage;
    const end = start + this.productsPerPage;
    return this.products.slice(start, end);
  },
  totalProductPages() {
    if (!this.products) return 1;
    return Math.ceil(this.products.length / this.productsPerPage);
  },
  filteredVehicles() {
    return this.vehicles.filter(vehicle =>
      vehicle.LicensePlate && vehicle.LicensePlate.toLowerCase().includes(this.searchVehicleQuery.toLowerCase())
    );
  },
  // filteredWarehouses() {
  //     console.log('Filtering warehouses:', this.searchWarehouseQuery, this.warehouses);
  //     return this.warehouses.filter(warehouse => 
  //       warehouse.name.toLowerCase().includes(this.searchWarehouseQuery.toLowerCase())
  //     );
  //   },
  filteredWarehouses() {
      console.log('Filtering warehouses:', this.searchWarehouseQuery, this.warehouses);
      return this.warehouses.filter(warehouse => 
        warehouse.name?.toLowerCase().includes(this.searchWarehouseQuery.toLowerCase()) ||
        warehouse.company_name?.toLowerCase().includes(this.searchWarehouseQuery.toLowerCase())
      );
    },
    // paginatedWarehouses() {
    //   const start = (this.currentWarehousePage - 1) * this.itemsPerPage;
    //   const end = start + this.itemsPerPage;
    //   return this.filteredWarehouses.slice(start, end);
    // },
    paginatedWarehouses() {
      const start = (this.currentWarehousePage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredWarehouses.slice(start, end);
    },
    totalWarehousePages() {
      return Math.ceil(this.filteredWarehouses.length / this.itemsPerPage);
    },
  paginatedVehicles() {
      const start = (this.currentVehiclePage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredVehicles.slice(start, end);
    },
    totalVehiclePages() {
      return Math.ceil(this.filteredVehicles.length / this.itemsPerPage);
    },
    filteredPRs() {
      return this.prs.filter(pr =>
        pr.creatorName.toLowerCase().includes(this.searchPRQuery.toLowerCase()) ||
        pr.documentNumber.toLowerCase().includes(this.searchPRQuery.toLowerCase()) ||
        pr.department.toLowerCase().includes(this.searchPRQuery.toLowerCase()) ||
        pr.status.toLowerCase().includes(this.searchPRQuery.toLowerCase())
      );
    },
    paginatedPRs() {
      const start = (this.currentPRPage - 1) * this.prsPerPage;
      const end = start + this.prsPerPage;
      return this.filteredPRs.slice(start, end);
    },
    totalPRPages() {
      return Math.ceil(this.filteredPRs.length / this.prsPerPage);
    },
  },
  methods: {
    transformImageUrl(imageUrl) {
      if (imageUrl.includes('https://brknew.synology.me/web/imageProducts/Garages/')) {
        return imageUrl.replace(
          'https://brknew.synology.me/web/imageProducts/Garages/',
          'https://brkmeeting-room.online/Garages/'
        );
      } else if (imageUrl.includes('https://brknew.synology.me/web/imageProducts/Warehouse/')) {
        return imageUrl.replace(
          'https://brknew.synology.me/web/imageProducts/Warehouse/',
          'https://brkmeeting-room.online/Warehouse/'
        );
      }
      return imageUrl;
    },
    async filterVehicles() {
      try {
        let response;
        if (this.isExternalVehicleSearch) {
          response = await axios.get('https://brk-group.org/api/vehicle_registration_ptt');
        } else {
          response = await axios.get('https://brk-group.org/api/vehicles');
        }
        this.vehicles = response.data;
        this.currentVehiclePage = 1; // Reset to first page after filtering
      } catch (error) {
        // console.error('Error fetching vehicles:', error);
      }
    },
    async saveExternalVehicle() {
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.post('https://brk-group.org/api/vehicle_registration_ptt', {
          vehicleReg: this.newVehicleReg
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const vehicleData = response.data;
        this.form.VehicleID = vehicleData.vehicleId;
        this.selectedVehicle = this.newVehicleReg;
        this.form.vehicleVersion = '';
        this.form.vehicleCode = '';
        this.form.vehicleModel = '';
        this.form.vehicleChassisNumber = '';
        this.form.vehicleMileage = '';
        this.selectedBrand = '';
        this.showVehicleModal = false;
        Swal.fire({
          icon: 'success',
          title: 'บันทึกสำเร็จ',
          text: 'ทะเบียนรถใหม่ถูกบันทึกเรียบร้อยแล้ว',
        });
        await this.filterVehicles();
      } catch (error) {
        // console.error('Error saving external vehicle:', error);
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด',
          text: 'ไม่สามารถบันทึกทะเบียนรถใหม่ได้',
        });
      }
    },
    async fetchDepartments() {
      try {
        const response = await axios.get('https://brk-group.org/api/departments');
        this.departments = response.data;
      } catch (error) {
        // console.error('Error fetching departments:', error);
      }
    },
    selectDepartment(department) {
      this.selectedDepartmentName = department.group_name;
      this.selectedDepartmentId = department.group_id; // เก็บ ID ของหน่วยงานที่เลือก
      this.showDepartmentModal = false;
    },
    isProductSelected(product) {
    return this.form.items.some(item => item.productId === product.product_id);
  },
  async fetchUser() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }
      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const userData = response.data;
        this.user = {
          login_id: userData.login_id,
          fullName: `${userData.prefixname}${userData.fname} ${userData.lname}`,
          group_id: userData.group_id // ดึง group_id ของผู้ใช้
        };
        // console.log('Fetched user:', this.user);
        await this.fetchWarehouses(); // เรียก fetchWarehouses หลังจากได้ข้อมูลผู้ใช้แล้ว
      } catch (error) {
        // console.error('Error fetching user:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' });
        } else {
          alert('เกิดข้อผิดพลาดในการดึงข้อมูลผู้ใช้');
        }
      }
    },
    validateQuantity(item) {
    if (item.quantity < 0) {
      item.quantity = 0;
    }
  },
  async selectVehicle(vehicle) {
      this.form.VehicleID = vehicle.VehicleID;
      this.selectedVehicle = vehicle.LicensePlate;
      this.showVehicleModal = false;
      this.form.isExternalVehicle = this.isExternalVehicleSearch;

      try {
        let response;
        if (this.isExternalVehicleSearch) {
          response = await axios.get(`https://brk-group.org/api/vehicle_registration_ptt/${vehicle.VehicleID}`);
        } else {
          response = await axios.get(`https://brk-group.org/api/vehicles/${vehicle.VehicleID}`);
        }

        const vehicleData = response.data;
        this.selectedDepartmentName = vehicleData.group_name;
        this.form.vehicleCode = vehicleData.VehicleNumber;
        this.form.vehicleModel = vehicleData.VehicleModel;
        this.form.vehicleVersion = vehicleData.VehicleVersion;
        this.form.vehicleChassisNumber = vehicleData.VehicleChassisNumber;
        this.form.vehicleMileage = vehicleData.VehicleMileage;
        this.selectedBrand = vehicleData.Brand;
      } catch (error) {
        // console.error('Error fetching vehicle data:', error);
      }
    },
    // async selectVehicle(vehicle) {
    //   this.form.VehicleID = vehicle.VehicleID;
    //   this.selectedVehicle = vehicle.LicensePlate;
    //   this.showVehicleModal = false;
    //   this.form.isExternalVehicle = false;
    //   // this.isExternalVehicle = false;
    //   try {
    //     const response = await axios.get(`https://brk-group.org/api/vehicles/${vehicle.VehicleID}`);
    //     const vehicleData = response.data;
    //     this.selectedDepartmentName = vehicleData.group_name;
    //     this.form.vehicleCode = vehicleData.VehicleNumber;
    //     this.form.vehicleModel = vehicleData.VehicleModel;
    //     this.form.vehicleVersion = vehicleData.VehicleVersion;
    //     this.form.vehicleChassisNumber = vehicleData.VehicleChassisNumber;
    //     this.form.vehicleMileage = vehicleData.VehicleMileage;
    //     this.selectedBrand = vehicleData.Brand;
    //   } catch (error) {
    //     console.error('Error fetching vehicle data:', error);
    //   }
    // },
    selectWarehouse(warehouse) {
      if (this.isExternalSupplierSearch) {
        this.form.garages_id = warehouse.warehouse_id;
        this.form.warehouse_id = null;
      } else {
        this.form.warehouse_id = warehouse.warehouse_id;
        this.form.garages_id = null;
      }
      this.selectedWarehouse = warehouse.name || warehouse.company_name;
      this.showWarehouseModal = false;


       // เพิ่ม console.log สำหรับการคลิกเลือกรายการของอู่
    // console.log('Selected warehouse:', warehouse);
    // console.log('Form garages_id:', this.form.garages_id);
    // console.log('Form warehouse_id:', this.form.warehouse_id);
    },
    prevVehiclePage() {
      if (this.currentVehiclePage > 1) this.currentVehiclePage--;
    },
    nextVehiclePage() {
      if (this.currentVehiclePage < this.totalVehiclePages) this.currentVehiclePage++;
    },
    async fetchVehicles() {
    try {
      const response = await axios.get('https://brk-group.org/api/vehiclespr');
      this.vehicles = response.data;
    } catch (error) {
      // console.error('Error fetching vehicles:', error);
    }
  },
//   async fetchWarehouses() {
//   const token = localStorage.getItem('userToken');
//   if (!token) {
//     this.$router.push({ name: 'Login' });
//     return;
//   }
//   if (!this.user || !this.user.group_id) {
//     console.error('User data or group_id is missing');
//     return;
//   }
//   try {
//     const response = await axios.get('https://brk-group.org/api/warehousespr', {
//       headers: { 'Authorization': `Bearer ${token}` },
//       params: { group_id: this.user.group_id }
//     });
//     this.warehouses = response.data.filter(warehouse => warehouse.name);
//     console.log('Fetched warehouses:', this.warehouses);
//   } catch (error) {
//     console.error('Error fetching warehouses:', error);
//   }
// },
async fetchWarehouses() {
      const token = localStorage.getItem('userToken');
      if (!token) {
        this.$router.push({ name: 'Login' });
        return;
      }
      if (!this.user || !this.user.group_id) {
        // console.error('User data or group_id is missing');
        return;
      }
      try {
        const response = await axios.get('https://brk-group.org/api/warehousespr', {
          headers: { 'Authorization': `Bearer ${token}` },
          params: { group_id: this.user.group_id, isExternalSupplier: this.isExternalSupplierSearch }
        });
        this.warehouses = response.data;
        // console.log('Fetched warehouses:', this.warehouses);
      } catch (error) {
        // console.error('Error fetching warehouses:', error);
      }
    },
  async fetchPRs() {
      try {
        const response = await axios.get('https://brk-group.org/api/purchase_requests');
        this.prs = response.data.map(pr => {
          if (pr.request_date) {
            const date = new Date(pr.request_date);
            if (!isNaN(date)) {
              pr.requestDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
            } else {
              pr.requestDate = 'N/A';
            }
          } else {
            pr.requestDate = 'N/A';
          }
          return pr;
        });
      } catch (error) {
        // console.error('Error fetching PRs:', error);
      }
    },
    showPRDetails(pr_id) {
      this.fetchPRItems(pr_id);
      this.showPRModal = true;
    },
    prevPRPage() {
      if (this.currentPRPage > 1) this.currentPRPage--;
    },
    nextPRPage() {
      if (this.currentPRPage < this.totalPRPages) this.currentPRPage++;
    },
    setPurchaseType(type) {
    this.form.purchaseType = type;
    // if (type === 'stock') {
    //   this.form.carRegNumber = '';
    // }
  },
    async populateUserData() {
      const token = localStorage.getItem('userToken');
      try {
        const response = await axios.get('https://brk-group.org/api/user', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const userData = response.data;
        this.form.creatorId = userData.login_id; // ใช้ login_id เป็น creatorId
        this.form.creatorName = `${userData.prefixname} ${userData.fname} ${userData.lname}`;
        this.form.departmentId = userData.group_id; // ใช้ group_id เป็น departmentId
        this.form.departmentName = userData.group_name; // ใช้สำหรับแสดงชื่อหน่วยงาน
      } catch (error) {
        // console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'Login' });
        }
      }
    },
    async fetchProducts() {
    try {
      const response = await axios.get('https://brk-group.org/api/productselect', {
        params: { search: this.productSearchQuery }
      });
      this.products = response.data;
    } catch (error) {
      // console.error('Error fetching products:', error);
    }
  },
  prevProductPage() {
      if (this.currentProductPage > 1) {
        this.currentProductPage--;
        this.fetchProducts();
      }
    },
    nextProductPage() {
      if (this.currentProductPage < this.totalProductPages) {
        this.currentProductPage++;
        this.fetchProducts();
      }
    },
    async fetchSuppliers() {
    try {
      const response = await axios.get('https://brk-group.org/api/supplierspr', {
        params: { search: this.searchWarehouseQuery }
      });
      this.suppliers = response.data.filter(supplier => supplier.company_name); // Ensure there are no suppliers without a name
    } catch (error) {
      // console.error('Error fetching suppliers:', error);
    }
  },
  async filterWarehouses() {
    if (this.isExternalSupplier) {
      await this.fetchSuppliers();
    } else {
      await this.fetchWarehouses();
    }
  },
    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) this.currentPage++;
    },
    selectSupplier(supplier) {
    this.form.supplierId = supplier.supplier_id; // บันทึก supplier_id
    this.form.supplierName = supplier.company_name;
    this.form.taxId = supplier.tax_id;
    this.form.phoneNumber = supplier.phone;
    this.showSupplierModal = false;
  },
  selectProduct(product) {
    if (this.isProductSelected(product)) {
      Swal.fire({
        icon: 'warning',
        title: 'สินค้าถูกเลือกไปแล้ว',
        text: 'คุณได้เลือกสินค้านี้ไปแล้ว กรุณาเลือกสินค้ารายการอื่น',
        confirmButtonText: 'ตกลง'
      });
    } else {
      this.form.items.push({
        productId: product.product_id,
        productCode: product.product_code,
        productName: product.product_name,
        productType: product.product_type,
        quantity: 0,
        unit: product.unit_name,
        unitPrice: product.price_per_unit,
        totalPrice: 0.00,
        status: 'Available'
      });
      this.showProductModal = false;
    }
  },
  async generatePurchaseRequestNumber() {
      try {
        const response = await axios.get('https://brk-group.org/api/purchase_requests/last');
        const lastNumber = response.data.lastNumber;
        const newNumber = lastNumber ? parseInt(lastNumber.slice(2)) + 1 : 1;
        this.form.purchaseRequestNumber = 'PR' + newNumber.toString().padStart(5, '0');
      } catch (error) {
        // console.error('Error generating purchase request number:', error);
        this.form.purchaseRequestNumber = 'PR00001';
      }
    },
    async resetForm() {
      await this.populateUserData();
  const newPurchaseRequestNumber = await this.generatePurchaseRequestNumber();
  this.form = {
    requestDate: '',
    creatorId: this.form.creatorId, // รักษาค่า creatorId
    creatorName: this.form.creatorName,
    supplierId: '',
    supplierName: '',
    taxId: '',
    phoneNumber: '',
    purchaseRequestNumber: newPurchaseRequestNumber, // กำหนดเลขใบขอซื้อใหม่
    departmentId: this.form.departmentId, // รักษาค่า departmentId
    departmentName: this.form.departmentName, // รักษาค่า departmentName
    details: '',
    // creditTerms: '',
    deliveryDueDate: '',
    totalAmount: 0.00,
    discount: 0.00,
    discountPercent: 0,
    amountAfterDiscount: 0.00,
    vatPercent: 0,
    vatAmount: 0.00,
    grandTotal: 0.00,
    status_pr: 'รอตรวจสอบ',
    purchaseType: '',
    VehicleID: '',
    warehouse_id: '',
    items: [],
    vehicleModel: '',
    vehicleCode: '',
    vehicleVersion: '',
    vehicleChassisNumber: '',
    vehicleMileage: '',
    files: [], // รีเซ็ตไฟล์แนบ
    files2: null // รีเซ็ตไฟล์แนบ
  };
    this.selectedVehicle = '';
    this.selectedBrand = '';
    this.selectedWarehouse = ''; // รีเซ็ตสถานที่รับอะไหล่
    this.populateUserData();
    await this.generatePurchaseRequestNumber();
  },
  handleFileUpload2(event) {
      const files = Array.from(event.target.files);
      const validTypes = [
        'application/pdf', 
        'image/jpeg', 
        'image/png', 
        'application/vnd.ms-excel', 
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      const invalidFiles = files.filter(file => !validTypes.includes(file.type));
      const invalidFilenames = files.filter(file => !this.isEnglishFilename(file.name));
      
      if (invalidFiles.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'ไฟล์ไม่ถูกต้อง',
          text: 'แนบได้เฉพาะไฟล์ pdf, jpg, jpeg, png และ excel เท่านั้น'
        });
        event.target.value = ''; // Reset the input value
        return;
      }
      
      if (invalidFilenames.length > 0) {
        Swal.fire({
          icon: 'error',
          title: 'ชื่อไฟล์ไม่ถูกต้อง',
          text: 'แนบได้เฉพาะไฟล์ที่มีชื่อเป็นภาษาอังกฤษเท่านั้น'
        });
        event.target.value = ''; // Reset the input value
        return;
      }
      
      this.form.files2 = files[0]; // Assign the first valid file to files2
    },
    isEnglishFilename(filename) {
      return /^[a-zA-Z0-9._-]+$/.test(filename);
    },
  // handleFileUpload(event) {
  //   const files = Array.from(event.target.files);
  //   // const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
  //   const validTypes = ['application/pdf', 'image/jpeg', 'image/png', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  //   const invalidFiles = files.filter(file => !validTypes.includes(file.type));
    
  //   if (invalidFiles.length > 0) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'ไฟล์ไม่ถูกต้อง',
  //       text: 'แนบได้เฉพาะไฟล์ pdf, jpg, jpeg, png เท่านั้น'
  //     });
  //     event.target.value = ''; // Reset the input value
  //     return;
  //   }
    
  //   this.form.files = files;
  // },
  handleFileUpload(event) {
  const files = Array.from(event.target.files);
  const validTypes = [
    'application/pdf', 
    'image/jpeg', 
    'image/png', 
    'application/vnd.ms-excel', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];
  const invalidFiles = files.filter(file => !validTypes.includes(file.type));
  const invalidFilenames = files.filter(file => !this.isEnglishFilename(file.name));
  
  if (invalidFiles.length > 0) {
    Swal.fire({
      icon: 'error',
      title: 'ไฟล์ไม่ถูกต้อง',
      text: 'แนบได้เฉพาะไฟล์ pdf, jpg, jpeg, png, excel เท่านั้น'
    });
    event.target.value = ''; // Reset the input value
    return;
  }
  
  if (invalidFilenames.length > 0) {
    Swal.fire({
      icon: 'error',
      title: 'ชื่อไฟล์ไม่ถูกต้อง',
      text: 'แนบได้เฉพาะไฟล์ที่มีชื่อเป็นภาษาอังกฤษเท่านั้น'
    });
    event.target.value = ''; // Reset the input value
    return;
  }
  
  this.form.files = files; // Assign the array of valid files to files
},
    handleItemFileUpload(event, index) {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
      if (!validTypes.includes(file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'ไฟล์ไม่ถูกต้อง',
          text: 'แนบได้เฉพาะไฟล์ pdf, jpg, jpeg, png เท่านั้น'
        });
        event.target.value = ''; // Reset the input value
        return;
      }
      this.form.items[index].file = file;
    }
  },

//   // เริ่มการยืนยันก่อนบันทึก
//   const result = await Swal.fire({
//     title: 'ยืนยันการบันทึก',
//     text: "คุณต้องการบันทึกข้อมูลนี้หรือไม่?",
//     icon: 'warning',
//     showCancelButton: true,
//     confirmButtonColor: '#3085d6',
//     cancelButtonColor: '#d33',
//     confirmButtonText: 'บันทึก',
//     cancelButtonText: 'ยกเลิก'
//   });

//   // ถ้าผู้ใช้ยืนยันการบันทึก
//   if (!result.isConfirmed) {
//     return;
//   }
//       this.loading = true; // เริ่ม loading
//       const token = localStorage.getItem('userToken');


//       if (this.form.items.length === 0) {
//       Swal.fire({
//         icon: 'error',
//         title: 'เกิดข้อผิดพลาด',
//         text: 'กรุณาเพิ่มรายการสินค้าอย่างน้อยหนึ่งรายการ',
        
//       });
//       this.loading = false;
//       return false;
     
//     }
// try {
//   const formData = new FormData();
//   for (const key in this.form) {
//     if (Object.prototype.hasOwnProperty.call(this.form, key) && key !== 'items') {
//       formData.append(key, this.form[key]);
//     }
//   }
 
//   const items = this.form.items.map((item, index) => {
//     if (item.file) {
//       formData.append(`items[${index}][file]`, item.file);
//     }
//     return {
//       productId: item.productId,
//       productCode: item.productCode,
//       productName: item.productName,
//       productType: item.productType,
//       quantity: item.quantity,
//       unit: item.unit,
//       // unitPrice: item.unitPrice,
//       // totalPrice: item.totalPrice,
//       // status: item.status
//     };
//   });

//   formData.append('items', JSON.stringify(items));

//   const response = await axios.post('https://localhost:3000/api/purchase_requests', formData, {
//     headers: {
//       'Authorization': `Bearer ${token}`,
//       'Content-Type': 'multipart/form-data'
//     }
//   });
//   console.log('Form saved successfully:', response.data);
//   this.fetchPRs();
//   Swal.fire({
//     icon: 'success',
//     title: 'บันทึกสำเร็จ',
//     text: 'ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว',
//   });
//   // ส่งข้อความไปยัง WebSocket เมื่อมีการบันทึกฟอร์มสต็อค
//   socket.emit('message', { type: 'new_stock_request', data: response.data });
//   await this.resetForm();
//   await this.generatePurchaseRequestNumber();
// } catch (error) {
//   console.error('Error saving form:', error);
//   if (error.response && error.response.status === 401) {
//     this.$router.push({ name: 'Login' });
//   } else {
//     let errorMessage = "ไม่สามารถบันทึกข้อมูลได้";
//       if (error.response.data && error.response.data.message) {
//         errorMessage = error.response.data.message; 
//       }
//     Swal.fire({
//       icon: 'error',
//       title: 'เกิดข้อผิดพลาด',
//       text: 'ไม่สามารถบันทึกข้อมูลได้' && errorMessage,
//     });
//       }
//     } finally {
//       this.loading = false; // สิ้นสุด loading
//     }
// },
async resetFormRepair() {
  await this.populateUserData();
  const newPurchaseRequestNumber = await this.generatePurchaseRequestNumber();
  this.form = {
    requestDate: '',
    creatorId: this.form.creatorId, // รักษาค่า creatorId
    creatorName: this.form.creatorName,
    supplierId: '',
    supplierName: '',
    taxId: '',
    phoneNumber: '',
    purchaseRequestNumber: newPurchaseRequestNumber, // กำหนดเลขใบขอซื้อใหม่
    departmentId: this.form.departmentId, // รักษาค่า departmentId
    departmentName: this.form.departmentName, // รักษาค่า departmentName
    details: '',
    // creditTerms: '',
    deliveryDueDate: '',
    totalAmount: 0.00,
    discount: 0.00,
    discountPercent: 0,
    amountAfterDiscount: 0.00,
    vatPercent: 0,
    vatAmount: 0.00,
    grandTotal: 0.00,
    status_pr: 'รอตรวจสอบ',
    purchaseType: '',
    VehicleID: '',
    warehouse_id: '',
    items: [],
    vehicleModel: '',
    vehicleCode: '',
    vehicleVersion: '',
    vehicleChassisNumber: '',
    vehicleMileage: '',
    files: [] // รีเซ็ตไฟล์แนบ
  };
    this.selectedVehicle = '';
    this.selectedBrand = '';
    this.selectedDepartmentName = '';
    this.selectedDepartmentId= '';
    this.selectedWarehouse = ''; // รีเซ็ตสถานที่รับอะไหล่
    this.populateUserData();
    await this.generatePurchaseRequestNumber();
  },
  validateForm() {
  const requiredFields = {
    requestDate: 'วันที่ขอใบสั่งซื้อ',
    purchaseRequestNumber: 'เลขที่ใบขอซื้อ',
    details: 'รายละเอียด',
    purchaseType: 'ประเภทการซื้อ',
  };

  for (const field in requiredFields) {
    if (!this.form[field]) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: `กรุณากรอกข้อมูลในฟิลด์ ${requiredFields[field]} ให้ครบถ้วน`,
      });
      return false;
    }
  }

  let errorMessages = [];

  // if (!this.form.warehouse_id || !this.form.garages_id) {
  //   errorMessages.push('กรุณาเลือกสถานที่รับอะไหล่ (คลังอะไหล่ หรือ อู่นอก)');
  // }

  if (this.form.purchaseType === 'repair') {
    if (!this.form.VehicleID) {
      errorMessages.push('กรุณากรอกทะเบียนรถ');
    }
    if (!this.form.vehicleModel) {
      errorMessages.push('กรุณากรอกรุ่นรถ');
    }
    if (!this.form.vehicleCode) {
      errorMessages.push('กรุณากรอกรหัสรถ');
    }
    if (!this.form.vehicleVersion) {
      errorMessages.push('กรุณากรอกเวอร์ชั่นรถ');
    }
    if (!this.form.vehicleChassisNumber) {
      errorMessages.push('กรุณากรอกเลขคัสซีรถ');
    }
    if (!this.form.vehicleMileage) {
      errorMessages.push('กรุณากรอกเลขไมล์รถ');
    }
  }

  if (errorMessages.length > 0) {
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      html: errorMessages.join('<br>'),
    });
    return false;
  }

  if (this.form.items.length === 0) {
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'กรุณาเพิ่มรายการสินค้าอย่างน้อยหนึ่งรายการ',
    });
    return false;
  }

  for (const item of this.form.items) {
    if (!item.productCode || !item.productName || !item.productType || !item.quantity || !item.unit) {
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'กรุณากรอกข้อมูลในรายการสินค้าให้ครบถ้วน',
      });
      return false;
    }
  }

  return true;
},

  async saveForm() {
  if (!this.validateForm()) {
    return;
  }
  const result = await Swal.fire({
    title: 'ยืนยันการบันทึก',
    text: "คุณต้องการบันทึกข้อมูลนี้หรือไม่?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'บันทึก',
    cancelButtonText: 'ยกเลิก'
  });

  if (!result.isConfirmed) {
    return;
  }
  this.loading = true;
  const token = localStorage.getItem('userToken');
  try {
    const formData = new FormData();
    for (const key in this.form) {
      if (Object.prototype.hasOwnProperty.call(this.form, key) && key !== 'items' && key !== 'files') {
        formData.append(key, this.form[key]);
      }
    }

    formData.append('items', JSON.stringify(this.form.items));

    this.form.items.forEach((item, index) => {
      if (item.file) {
        formData.append(`items[${index}][file]`, item.file);
      }
    });

    if (Array.isArray(this.form.files)) {
      this.form.files.forEach((file, index) => {
        formData.append(`repair_files[${index}]`, file);
      });
    }

    if (this.form.files2) {
      formData.append('repair_files2', this.form.files2);
    }

    formData.append('department', this.form.departmentId); // เพิ่ม department
    formData.append('isExternalVehicle', this.form.isExternalVehicle);
    formData.append('departmentRepairId', this.selectedDepartmentId); // เพิ่มบรรทัดนี้

    // แปลงค่าเป็น null ถ้าเป็น string 'null'
    // if (this.form.warehouse_id === 'null') {
    //   formData.set('warehouse_id', null);
    // }
    // if (this.form.garages_id === 'null') {
    //   formData.set('garages_id', null);
    // }
 // แปลงค่าเป็น null ถ้าเป็น string 'null' หรือ 'undefined'
 formData.set('warehouse_id', this.form.warehouse_id === 'null' || this.form.warehouse_id === 'undefined' ? null : this.form.warehouse_id);
    formData.set('garages_id', this.form.garages_id === 'null' || this.form.garages_id === 'undefined' ? null : this.form.garages_id);
    // console.log('Submitting form data:', formData);

    const response = await axios.post('https://brk-group.org/api/purchase_requests', formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    this.fetchPRs();
    Swal.fire({
      icon: 'success',
      title: 'บันทึกสำเร็จ',
      text: 'ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว',
    });
  // ใช้ this.$socket แทน socket เพื่อส่งข้อความผ่าน WebSocket
  this.$socket.emit('message', { type: 'new_purchase_request', data: response.data });
    this.$socket.emit('message', { type: 'new_stock_request', data: response.data });

    // socket.emit('message', { type: 'new_purchase_request', data: response.data });
    // socket.emit('message', { type: 'new_stock_request', data: response.data });
    this.generatePurchaseRequestNumber();
    this.resetFormRepair();
    this.generatePurchaseRequestNumber();
  } catch (error) {
    console.error('Error saving form:', error);
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'ไม่สามารถบันทึกข้อมูลได้ กรุณาเลือกหน่วยงานรถหรือแนบใบแจ้งซ่อม',
    });
  } finally {
    this.loading = false;
  }
},


async saveStockForm() {
  const result = await Swal.fire({
    title: 'ยืนยันการบันทึก',
    text: "คุณต้องการบันทึกข้อมูลนี้หรือไม่?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'บันทึก',
    cancelButtonText: 'ยกเลิก'
  });

  if (!result.isConfirmed) {
    return;
  }
  this.loading = true;
  const token = localStorage.getItem('userToken');

  if (this.form.items.length === 0) {
    Swal.fire({
      icon: 'error',
      title: 'เกิดข้อผิดพลาด',
      text: 'กรุณาเพิ่มรายการสินค้าอย่างน้อยหนึ่งรายการ',
    });
    this.loading = false;
    return false;
  }

  try {
    const formData = new FormData();
    for (const key in this.form) {
      if (Object.prototype.hasOwnProperty.call(this.form, key) && key !== 'items') {
        formData.append(key, this.form[key]);
      }
    }

    const items = this.form.items.map((item, index) => {
      if (item.file) {
        formData.append(`items[${index}][file]`, item.file);
      }
      return {
        productId: item.productId,
        productCode: item.productCode,
        productName: item.productName,
        productType: item.productType,
        quantity: item.quantity,
        unit: item.unit,
      };
    });

    formData.append('items', JSON.stringify(items));
    // formData.append('department', this.form.department || this.selectedDepartmentId);
    formData.append('department', this.form.departmentId); // เพิ่ม department
    formData.append('departmentRepairId', this.selectedDepartmentId); // เพิ่มบรรทัดนี้

   // แปลงค่า 'null' และ 'undefined' เป็น null จริง ๆ
   const warehouseId = Array.isArray(this.form.warehouse_id) ? this.form.warehouse_id[0] : this.form.warehouse_id;
    const garagesId = Array.isArray(this.form.garages_id) ? this.form.garages_id[0] : this.form.garages_id;

    formData.set('warehouse_id', warehouseId === 'null' || warehouseId === 'undefined' ? null : warehouseId);
    formData.set('garages_id', garagesId === 'null' || garagesId === 'undefined' ? null : garagesId);
    
    // formData.append('warehouse_id', warehouseId);
    // formData.append('garages_id', garagesId);

  

    // console.log('Submitting stock form data:', formData);

    const response = await axios.post('https://brk-group.org/api/purchase_requests', formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response && response.data) {
      // console.log('Form saved successfully:', response.data);
 
      this.fetchPRs();

      Swal.fire({
        icon: 'success',
        title: 'บันทึกสำเร็จ',
        text: 'ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว',
      });
      // socket.emit('message', { type: 'new_stock_request', data: response.data });
      this.$socket.emit('message', { type: 'new_stock_request', data: response.data });
      await this.resetForm();
      await this.generatePurchaseRequestNumber();
    } else {
      throw new Error('Invalid response from server');
    }
  } catch (error) {
    // console.error('Error saving form:', error);
    if (error.response && error.response.status === 401) {
      this.$router.push({ name: 'Login' });
    } else {
      let errorMessage = "ไม่สามารถบันทึกข้อมูลได้";
      if (error.response && error.response.data && error.response.data.message) {
        errorMessage = error.response.data.message; 
      }
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: errorMessage,
      });
    }
  } finally {
    this.loading = false;
  }
},
  // async saveForm() {
  // if (!this.validateForm()) {
  //   return;
  // }
  // const result = await Swal.fire({
  //   title: 'ยืนยันการบันทึก',
  //   text: "คุณต้องการบันทึกข้อมูลนี้หรือไม่?",
  //   icon: 'warning',
  //   showCancelButton: true,
  //   confirmButtonColor: '#3085d6',
  //   cancelButtonColor: '#d33',
  //   confirmButtonText: 'บันทึก',
  //   cancelButtonText: 'ยกเลิก'
  // });

  // // ถ้าผู้ใช้ยืนยันการบันทึก
  // if (!result.isConfirmed) {
  //   return;
  // }
  // this.loading = true;
  // const token = localStorage.getItem('userToken');
  // try {
  //   const formData = new FormData();
  //   for (const key in this.form) {
  //     if (Object.prototype.hasOwnProperty.call(this.form, key) && key !== 'items' && key !== 'files') {
  //       formData.append(key, this.form[key]);
  //     }
  //   }

  //   formData.append('items', JSON.stringify(this.form.items));

  //   this.form.items.forEach((item, index) => {
  //     if (item.file) {
  //       formData.append(`items[${index}][file]`, item.file);
  //     }
  //   });

  //   if (Array.isArray(this.form.files)) {
  //     this.form.files.forEach((file, index) => {
  //       formData.append(`repair_files[${index}]`, file);
  //     });
  //   }

  //   if (this.form.files2) {
  //     formData.append('repair_files2', this.form.files2);
  //   }

  //   formData.append('departmentRepairId', this.selectedDepartmentId);
  //   formData.append('isExternalVehicle', this.form.isExternalVehicle);

  //   const response = await axios.post('https://brk-group.org/api/purchase_requests', formData, {
  //     headers: {
  //       'Authorization': `Bearer ${token}`,
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   });

  //   this.fetchPRs();
  //   Swal.fire({
  //     icon: 'success',
  //     title: 'บันทึกสำเร็จ',
  //     text: 'ข้อมูลของคุณถูกบันทึกเรียบร้อยแล้ว',
  //   });

  //   socket.emit('message', { type: 'new_purchase_request', data: response.data });

  //   this.generatePurchaseRequestNumber();
  //   this.resetFormRepair();
  //   this.generatePurchaseRequestNumber();
  // } catch (error) {
  //   console.error('Error saving form:', error);
  //   if (error.response && error.response.status === 401) {
  //     this.$router.push({ name: 'Login' });
  //   } else {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'เกิดข้อผิดพลาด',
  //       text: 'ไม่สามารถบันทึกข้อมูลได้ กรุณาเลือกหน่วยงานรถหรือแนบใบแจ้งซ่อม',
  //     });
  //   }
  // } finally {
  //   this.loading = false;
  //     }
  //   },
    prevWarehousePage() {
    if (this.currentWarehousePage > 1) {
      this.currentWarehousePage--;
    }
  },
  nextWarehousePage() {
    if (this.currentWarehousePage < this.totalWarehousePages) {
      this.currentWarehousePage++;
    }
  },
    addItem() {
    this.showProductModal = true;
  },
    removeItem(index) {
      this.form.items.splice(index, 1);
    },
    exportData() {
      // Implement export logic here
    },
    calculateItemTotal(item) {
    item.totalPrice = (item.quantity * item.unitPrice).toFixed(2);
    this.calculateAmounts();
  },
  calculateAmounts() {
    let totalAmount = 0;
    for (const item of this.form.items) {
      totalAmount += parseFloat(item.totalPrice) || 0;
    }

    const discountPercent = parseFloat(this.form.discountPercent) || 0;
    const vatPercent = parseFloat(this.form.vatPercent) || 0;

    const amountAfterDiscount = totalAmount - (totalAmount * discountPercent / 100);
    const vatAmount = amountAfterDiscount * vatPercent / 100;
    const grandTotal = amountAfterDiscount + vatAmount;

    this.form.totalAmount = totalAmount.toFixed(2);
    this.form.amountAfterDiscount = amountAfterDiscount.toFixed(2);
    this.form.vatAmount = vatAmount.toFixed(2);
    this.form.grandTotal = grandTotal.toFixed(2);
  },
  
  }
}
</script>
<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 640px) {
  .modal-container {
    max-height: 90vh;
    height: 80%;
    overflow-y: auto;
  }
}
.flex-col.sm\:flex-row {
  display: flex;
  flex-direction: column;
}

/* Space between the search inputs */
.sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

/* Custom styles for the modal */
.modal-header {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 1rem;
}

.modal-footer {
  border-top: 1px solid #e5e7eb;
  padding-top: 1rem;
}

.form-input {
  padding: 0.5rem;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.25);
}
</style>