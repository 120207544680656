<template>
    <div class="register-container">
      <h1>Register New Account</h1>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="username">Username</label>
          <input
            id="username"
            v-model="username"
            type="text"
            placeholder="Enter username"
            required
          />
        </div>
  
        <div class="form-group">
          <label for="password">Password</label>
          <input
            id="password"
            v-model="password"
            type="password"
            placeholder="Enter password"
            required
          />
        </div>
  
        <div class="form-group">
          <label for="confirmPassword">Confirm Password</label>
          <input
            id="confirmPassword"
            v-model="confirmPassword"
            type="password"
            placeholder="Confirm password"
            required
          />
        </div>

        <div class="form-group">
        <label for="position">Position</label>
        <select v-model="position_id" id="position" required>
          <option value="" disabled>Select your position</option>
          <option value="1">Purchase</option>
          <option value="2">Insurance</option>
          <option value="3">Maintenance</option>
          <!-- Add more options here -->
        </select>
      </div>

        <button type="submit" class="btn-register">Register</button>
      <p v-if="error" class="error-message">{{ error }}</p>
      </form>
    </div>
  </template>
  
  <script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      confirmPassword: '',
      position_id: '', // Add this line to include the position_id in the data
      error: null,
    };
  },
  methods: {
    async register() {
      if (this.password !== this.confirmPassword) {
        this.error = 'The passwords do not match.';
        return;
      }
      if (!this.position_id) {
        this.error = 'Please select a position.';
        return;
      }

      try {
        // await axios.post('httpห://brk-group.org/register', 
        await axios.post('https://brk-group.org/register',{
          username: this.username,
          password: this.password,
          position_id: this.position_id // Include the position_id in the request
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        alert('Registration successful, please login.');
        this.$router.push('/login');
      } catch (error) {
        if (error.response) {
          this.error = error.response.data;
        } else {
          this.error = 'Registration failed. Please try again later.';
        }
      }
    }
  }
};
</script>
  
  <style scoped>
  .register-container {
    max-width: 300px;
    margin: 50px auto;
    padding: 20px;
    text-align: left;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type='text'],
  input[type='password'] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .btn-register {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #5cb85c;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .btn-register:hover {
    background-color: #4cae4c;
  }
  
  .error-message {
    color: red;
    margin-top: 20px;
  }
  </style>
  